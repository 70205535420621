import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 歌詞を書くJa() {
const title = "歌詞を書く";
const keywords = "歌詞, 音楽生成, 音楽, 作詞, 作詞アシスタント, 作詞アプリ, 作詞プラットフォーム, 作詞ソフトウェア, 作詞ツール, 作詞ツール, 作詞ウェブサイト, 歌詞を書く方法";
const description = "AIを使用して歌詞を書く方法を学ぶ";
const longDescription = "Lyrical Labsは、AIを使用してより速く歌詞と音楽を作成するための作詞プラットフォームです。あなたの個人的な好みと作詞ニーズに合わせた個人的な作詞アシスタントです！";
const h1 = "AIの力を利用して歌詞を書くことを学ぶ";
const h2 = "私たちのAI駆動の歌詞で作詞を革新し、無限のインスピレーションを得る";
const sectionHeader = "究極の作詞コンパニオン";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなたの個人的な作詞アシスタント";
const featureTitle3 = "組み込みスマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "オリジナルのアイデアとメロディの絶え間ない供給で、終わりなき作詞のインスピレーションが待っています。AIはどんなトピックについても書くことができ、どんなジャンルの音楽も生成できます。";
const featureParagraph2 = "私たちのAI作詞パートナーと協力して、完璧な歌を作り上げましょう。歌詞と音楽ができたら、AIにフィードバックを話してあなたの音楽的ビジョンを実現します。";
const featureParagraph3 = "プラットフォームには、インスピレーションを提供し、歌詞を完成させるのに役立つ関連する言葉をすべて見つける手助けをする組み込みスマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選択できるので、どの言語でも歌詞の書き方を学ぶことができます！";
const faq1 = "著作権は私が保持しますか？";
const faq2 = "このプラットフォームの目的は私の曲を完全に書くことですか？";
const faq3 = "このプラットフォームはどのように作詞ブロックを克服するのを助けますか？";
const faq4 = "このプラットフォームはどのようにして作詞プロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100%ロイヤリティフリーなので、このプラットフォームで作成した音楽と歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、私たちのプラットフォームの目的は、無限のインスピレーションの供給を提供し、作詞ブロックを克服するのを助けることです。AIは完全な歌詞と音楽のメロディを生成する能力がありますが、それらを出発点として使用し、あなた自身の個人的なタッチを加えることをお勧めします。AIはあなたをアシストし、インスパイアするためにここにありますが、あなたの創造性を置き換えるものではありません。";
const faqa3 = "作詞ブロックは任意の作詞家にとってフラストレーションと落胆の原因となることがあります。しかし、私たちのプラットフォームを使用すれば、アイデアが尽きる心配はありません。希望するテーマとスタイルを入力するだけで、AIは創造的でオリジナルなアイデアの絶え間ないストリームを提供します。これにより、作詞プロセスを開始し、軌道に戻るのに役立ちます。";
const faqa4a = "当プラットフォームは、あなたの選んだスタイリスティック好みに基づいてアイデアと提案を無限に提供するように設計されており、新しいことを試したり、実験したりする自由を与えます。";
const faqa4b = "さらに、プラットフォームは、高度にユニークな「関連する言葉」辞書を横に置いて、あなたの作詞プロセス全体を通じて支援することができます。それはまるであなた自身の個人的な創造的アシスタントのようなものであり、あなたの個人的な好みと作詞ニーズに合わせています。";
const quote1 = "これは画期的な変化です、私が最初の歌を書くのを本当に助けました";
const quote2 = "素晴らしい製品です、歌詞を書く方法を学びたい誰にでもお勧めします";
const quote3 = "優れたカスタマーサービス、歌詞を書く方法を学ぶのは本当にクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
