import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongtextGenerator() {
const title = "Songtext-Generator";
const keywords = "Songtext-Generator, Liedtext, Melodie Generator, Musikgenerator, Lyrik Software, Songtext App, Musikkomposition, Lyrik-Tool, Songtext-Plattform";
const description = "Erstellen Sie Liedtexte und Melodien mit KI";
const longDescription = "Lyrical Labs ist eine Plattform für das Songwriting, die KI verwendet, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Assistent für das Songwriting, angepasst an Ihre persönlichen Geschmacks- und Schreibbedürfnisse!";
const h1 = "Erfahren Sie, wie man mit der Kraft der KI Songs schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Texte und Melodien.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Songwriting erwartet Sie mit einer nie endenden Quelle origineller Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu erschaffen. Sobald Sie Texte und Musik haben, können Sie Feedback geben, indem Sie mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform verfügt über ein eingebautes intelligentes Wörterbuch, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vollenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Copyright?";
const faq2 = "Ist es das Ziel der Plattform, meine Songs komplett zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% Lizenzfrei, so dass Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen, behalten.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, vollständige Sets von Texten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Writer's Block kann für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder um Ideenmangel sorgen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI bietet Ihnen einen endlosen Strom kreativer und origineller Ideen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist so konzipiert, dass sie Ihnen eine endlose Versorgung mit Ideen und Vorschlägen bietet, basierend auf Ihren gewählten stilistischen Präferenzen, und gibt Ihnen die Freiheit, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte nach Ihrem eigenen Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Songwriting-Prozesses unterstützt. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacks- und Schreibbedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Songwriting zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
