import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function KostenloserBeatMaker() {
const title = "Kostenloser Beat Maker";
const keywords = "kostenloser Beat Maker, Musikgenerator, Liedtextgenerator, Musikproduktion, Beat-Herstellung, kostenlose Beats, AI Beat Maker, Musik-Making-App, Musik-Making-Plattform";
const description = "Erstelle Beats und Songtexte mit einem kostenlosen AI Beat Maker";
const longDescription = "Lyrical Labs ist eine Plattform für Musikproduktion, die AI verwendet, um dir beim Erstellen von Beats und Liedtexten zu helfen. Es ist dein persönlicher Musikassistent, der genau auf deine musikalischen Bedürfnisse zugeschnitten ist!";
const h1 = "Erstelle beeindruckende Beats und Songtexte mit dem kostenlosen AI Beat Maker.";
const h2 = "Revolutioniere deine Musikproduktion mit unserem AI-gesteuerten Beat Maker.";
const sectionHeader = "Dein ultimativer Begleiter in der Musikproduktion";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Musikassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Komponiere in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für Musikproduktion mit einem nie endenden Angebot an originalen Ideen und Melodien. Der AI kann über jedes Thema schreiben und Beats aller Genres erzeugen.";
const featureParagraph2 = "Arbeite mit unserem AI Musikassistenten zusammen, um den perfekten Beat und Songtext zu erstellen. Hast du einmal Beats und Texte, kannst du dem AI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Vervollständigen deiner Songtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir dabei, einen Kreativitätsblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikproduktionsprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle an Inspiration zu bieten und Kreativitätsblockaden zu überwinden. Obwohl der AI in der Lage ist, komplette Sets von Lyrics und musikalischen Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu nutzen und deinen eigenen persönlichen Touch hinzuzufügen. Der AI ist hier, um zu assistieren und zu inspirieren, nicht um deine Kreativität zu ersetzen.";
const faqa3 = "Ein Kreativitätsblock kann für jeden Musikproduzenten eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und der AI bietet dir einen endlosen Strom an kreativen und originellen Ideen, mit denen du arbeiten kannst. Dies kann deinen Musikproduktionsprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Versorgung mit Ideen und Vorschlägen zu bieten, basierend auf deinen gewählten stilistischen Präferenzen, und gibt dir die Freiheit zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht dir die Plattform, die Songtexte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words'-Wörterbuch an deiner Seite, um dich durchgehend im Musikproduktionsprozess zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, genau auf deine individuellen musikalischen Bedürfnisse angepasst!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man einen Song schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Möglichkeit, das Songschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
