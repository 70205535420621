import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingKi() {
const title = "Songwriting-Ki";
const keywords = "Songwriting-KI, Liedtext-Generator, Musikgenerator, Songkomposition, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Songwriting-Website, Songtexte schreiben lernen";
const description = "Lerne, Lieder mit KI zu schreiben";
const longDescription = "Songwriting-KI ist eine Plattform für das Schreiben von Liedern, die KI nutzt, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, angepasst an deinen eigenen Geschmack und Bedürfnisse beim Songwriting!";
const h1 = "Lerne, Lieder mit der Kraft der KI zu schreiben.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet dich mit einem unerschöpflichen Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu schaffen. Sobald du Texte und Musik hast, kannst du durch Gespräche mit der KI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Fertigstellen deiner Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ wählbaren Sprachen kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Songwriting-KI ist 100% lizenzfrei, daher behältst du alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Sätze von Texten und musikalischen Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deinen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil an, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Songwriting-Prozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist so konzipiert, dass sie dir eine endlose Menge an Ideen und Vorschlägen bietet, basierend auf deinen ausgewählten stilistischen Vorlieben, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht dir die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich durch deinen gesamten Songwriting-Prozess zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deinen eigenen Geschmack und Bedürfnisse beim Songwriting!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
