import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 音楽の書き方Ja() {
const title = "音楽の書き方";
const keywords = "音楽生成, 歌詞生成, 歌作り方, 歌詞アシスタント, 歌詞アプリ, 歌詞プラットフォーム, 歌作成ソフトウェア, 歌作成ツール, 歌詞作成サイト, 音楽の書き方";
const description = "AIを使って音楽の書き方を学ぶ";
const longDescription = "Lyrical LabsはAIを活用して、より速く歌詞とメロディを作成するためのプラットフォームです。あなたの個人的な味や音楽作成のニーズに合わせた個人的なアシスタントです！";
const h1 = "AIの力を使って音楽の書き方を学ぶ";
const h2 = "AIによる歌詞生成で作曲活動を革新し、無限のインスピレーションを得よう";
const sectionHeader = "究極の作曲コンパニオン";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなた専用の作曲アシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どんな言語でも作詞可能";
const featureParagraph1 = "無限に広がる作曲インスピレーションがここに。AIはどんなトピックについても歌詞を書き、どんなジャンルの音楽も生成できます";
const featureParagraph2 = "私たちのAI作曲パートナーと協力して完璧な曲を作りましょう。歌詞とメロディができたら、AIにフィードバックを与えて音楽的ビジョンを実現しましょう。";
const featureParagraph3 = "プラットフォームには、インスピレーションを提供し、歌詞作成を助けるさまざまな関連語を見つけるのに役立つ内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選べますので、どんな言語でも歌詞の書き方を学ぶことができます！";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目的は私の曲を完全に作成することですか？";
const faq3 = "プラットフォームはどのように作家の閉塞を乗り越えるのを助けますか？";
const faq4 = "プラットフォームは作曲プロセスをどのように促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、私たちのプラットフォームの目的は、インスピレーションを無限に提供し、作家の閉塞を克服する助けとなることです。AIは完全な歌詞セットと音楽的メロディを生成する能力がありますが、それらを出発点として使用し、あなたの個人的なタッチを加えることを奨励します。AIは創造性を置き換えるものではなく、あなたを助け、インスパイアするためにここにあります。";
const faqa3 = "作家の閉塞は、どの作曲家にとってもフラストレーションを感じさせるものですが、当プラットフォームを使用すれば、アイディアが尽きることはありません。希望のテーマとスタイルを入力するだけで、AIが創造的でオリジナルのアイディアを無限に提供します。これにより作曲プロセスが開始され、軌道に戻るのに役立ちます。";
const faqa4a = "私たちのプラットフォームは、選んだスタイルに基づいてアイディアと提案を無尽蔵に提供するよう設計されており、新しいことを試みる自由を与えます。";
const faqa4b = "さらに、プラットフォームは「関連語辞書」と共に自由に歌詞を書き編集することができます。これはあなた専用の創造的なアシスタントのようなもので、あなたの個人的な趣味や音楽作成のニーズに合わせています！";
const quote1 = "これは画期的。初めての曲作りに本当に役立ちました";
const quote2 = "素晴らしい製品です。歌作りを学びたい人にはおすすめします";
const quote3 = "優れたカスタマーサービス、曲の書き方を学ぶのにとてもクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
