import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 曲を書くJa() {
const title = "曲を書く";
const keywords = "歌詞, 音楽ジェネレーター, 音楽, 曲作り, 曲作りアシスタント, 曲作りアプリ, 曲作りプラットフォーム, 曲作りソフトウェア, 曲作りツール, 曲作りツール, 曲作りウェブサイト, 曲を書く方法";
const description = "AIを使って曲を書く方法を学ぼう";
const longDescription = "Lyrical Labsは、AIを使用してより速く歌詞と音楽を書くことを助ける曲作りプラットフォームです。それはあなたの個々のテイストと曲作りのニーズに合わせた個人の曲作りアシスタントです。";
const h1 = "AIの力を使って曲を書く方法を学ぶ。";
const h2 = "私たちのAI駆動の歌詞で曲作りを革命し、無限のインスピレーションを得よう。";
const sectionHeader = "究極の曲作りの伴侶";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなたの個人的な曲作りアシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どんな言語でも書ける";
const featureParagraph1 = "終わりなき曲作りのインスピレーションが、元々のアイデアとメロディーの絶え間ない供給とともに待っています。AIはどんなトピックについても書くことができ、どんなジャンルの音楽も生成することができます。";
const featureParagraph2 = "完璧な曲を作り出すために、私たちのAI曲作りパートナーと協力してください。歌詞と音楽が一度できたら、AIにフィードバックを与えて、あなたの音楽的ビジョンを生き生きとさせてください。";
const featureParagraph3 = "プラットフォームにはインスピレーションを提供し、あなたの歌詞を完成させるのを助けるために関連するあらゆる種類の単語を見つけるのに役立つ内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選ぶことができるので、どの言語でも歌詞を書く方法を学べます！";
const faq1 = "著作権は私が保持しますか？";
const faq2 = "このプラットフォームの目標は私の曲を完全に書くことですか？";
const faq3 = "このプラットフォームはどのようにして創作のブロックを克服するのを助けますか？";
const faq4 = "このプラットフォームはどのようにして曲作りのプロセスを促進しますか？";
const faqa1 = "はい！Lyrical Labsは100%ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、当プラットフォームの目標は無尽蔵のインスピレーションを提供し、創作のブロックを克服する手助けをすることです。AIは完全な歌詞セットと音楽的メロディを生成する能力を持っていますが、それらを出発点として使用し、あなた自身の個人的なタッチを加えることを奨励します。AIはあなたをアシストし、インスピレーションを与えるためにここにいます。あなたの創造性を置き換えるためではありません。";
const faqa3 = "創作のブロックはどんなsongwriterにとってもイライラする気分を害する経験ですが、当プラットフォームを使えばアイディアが尽きる心配はありません。desired themeとstyleを入力するだけで、AIが創造的でオリジナルなアイディアの無限の流れを提供します。これはあなたの曲作りのプロセスを再開し、軌道に戻すのに役立ちます。";
const faqa4a = "当プラットフォームは、選んだスタイリスティックな好みに基づいてアイディアと提案の終わりなき供給を提供するように設計されており、新しいことを試す自由を与えます。";
const faqa4b = "さらに、プラットフォームを利用して歌詞を自由に書いたり編集したりすることができます。あなたを通じてお手伝いするために、非常に独特な「関連ワード」辞書がそばにあります。それはあなた自身の個人的な創造的アシスタントのようなものです、あなた自身の個人的なテイストと曲作りのニーズに合わせています。";
const quote1 = "これは画期的です、本当に私が初めての曲を書くのを助けました";
const quote2 = "素晴らしい製品です、曲を書く方法を学びたい人にお勧めします";
const quote3 = "優れたカスタマーサービス、曲を書く方法を学ぶのに本当にクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
