import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiジェネレーターJa() {
const title = "Aiジェネレーター";
const keywords = "歌詞, 音楽ジェネレーター, 音楽, 作詞, 作詞アシスタント, 作詞アプリ, 作詞プラットフォーム, 作詞ソフトウェア, 作詞ツール, 作詞ツール, 作詞ウェブサイト, 曲の作り方";
const description = "AIを使って曲の作り方を学ぶ";
const longDescription = "Lyrical Labsは、AIを使用してより速く歌詞と音楽を作成する作詞プラットフォームです。これはあなた自身の個人的な好みと作詞ニーズに合わせた個人的な作詞アシスタントです！";
const h1 = "AIの力を使って曲の作り方を学ぶ。";
const h2 = "AIによる歌詞生成で、あなたの作詞を革新し、無限のインスピレーションを得よう。";
const sectionHeader = "究極の作詞コンパニオン";
const featureTitle1 = "無限の創造力";
const featureTitle2 = "あなたの個人的な作詞アシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "絶え間ない作詞のインスピレーションが、オリジナルのアイデアやメロディーとともに待っています。AIは任意のトピックについて書くことができ、どのジャンルの音楽でも生成できます。";
const featureParagraph2 = "AI作詞パートナーと協力して完璧な曲を作成しましょう。歌詞と音楽ができたら、AIにフィードバックを与えることで音楽的ビジョンを具体化できます。";
const featureParagraph3 = "このプラットフォームには、インスピレーションを提供し、作詞を完了するのに役立つさまざまな関連語を見つけるのに役立つ内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選択できるので、任意の言語で歌詞の書き方を学ぶことができます。";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目的は私の曲を完全に書くことですか？";
const faq3 = "このプラットフォームはどのようにして創作の壁を克服するのですか？";
const faq4 = "このプラットフォームはどのようにして作詞プロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞の全ての権利を保持できます。";
const faqa2 = "いいえ、当プラットフォームの目的は、無限のインスピレーションを提供し、創作の壁を克服する手助けをすることです。AIは完全な歌詞や音楽メロディを生成する能力がありますが、それらを出発点として使用し、自分自身の個人的なタッチを加えることをお勧めします。AIは補助として、インスピレーションとしてここにありますが、あなたの創造性を置き換えるものではありません。";
const faqa3 = "創作の壁は、どの作詞家にとってもフラストレーションとなりがちです。しかし、当プラットフォームを使用すれば、アイデアが尽きることはありません。必要なテーマやスタイルを入力するだけで、AIが創造的でオリジナルなアイデアの絶え間ない流れを提供します。これにより、作詞プロセスを再開し、正しい軌道に戻るのに役立ちます。";
const faqa4a = "当プラットフォームは、選択したスタイルの好みに基づいて、アイデアと提案を無限に提供するように設計されています。これにより、新しいことを試す自由が得られます。";
const faqa4b = "さらに、プラットフォームでは、作詞プロセス全体を通じてサポートする「関連語辞書」の隣で、自分の意志で自由に歌詞を書くことができます。それはあなた自身の個人的な好みと作詞ニーズに合ったあなた自身の個人的な創造的アシスタントのようなものです！";
const quote1 = "これは革新的です、初めての曲作りを助けてくれました";
const quote2 = "素晴らしい製品です。曲の作り方を学びたい人にお勧めします";
const quote3 = "優れた顧客サービス、曲の作り方を学ぶ新しい方法が本当にクールです";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
