import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiBeatGeneratorGerman() {
const title = "Ai-Beat-Generator";
const keywords = "AI-Beat-Generator, Musik-Generator, Lyrik-Generator, Beat-Erstellung, Musikproduktion, AI-Beat-Tool, Musik-Software, KI-Musikinstrument";
const description = "Erfahren Sie, wie Sie mit unserem AI-Beat-Generator Musik und Texte erzeugen können";
const longDescription = "Lyrical Labs ist eine Plattform zur Songerstellung, die KI nutzt, um Ihnen schneller beim Schreiben von Texten und beim Erstellen von Beats zu helfen. Es ist Ihr persönlicher Musikassistent, angepasst an Ihre eigenen Musikgeschmack und -bedürfnisse!";
const h1 = "Erstellen Sie Musik und Texte mit der Kraft des AI-Beat-Generators.";
const h2 = "Revolutionieren Sie Ihre Musikproduktion mit endloser Inspiration durch unsere KI-betriebenen Beats.";
const sectionHeader = "Der ultimative Begleiter für Musikproduktion";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Musikassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Erstellen Sie Beats in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration und eine nie versiegende Quelle origineller Ideen und Melodien erwarten Sie. Die KI kann über jedes Thema schreiben und Beats jeder Musikrichtung erzeugen.";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-basierten Musikpartner zusammen, um den perfekten Beat zu kreieren. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Ihr musikalisches Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform verfügt über ein integriertes intelligentes Wörterbuch, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, können Sie lernen, Songtexte und Beats in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Musikstücke komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikproduktionsprozess?";
const faqa1 = "Ja! Lyrical Labs ist zu 100% gebührenfrei, Sie behalten somit alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und den Schreibblock zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Musikschaffenden eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI wird Ihnen einen endlosen Strom kreativer und origineller Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Musikproduktionsprozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Quelle von Ideen und Vorschlägen auf der Grundlage Ihrer gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie während Ihres gesamten Musikproduktionsprozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacks- und Produktionsbedürfnisse!";
const quote1 = "Das ist ein echter Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Songs schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Songwriting zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
