import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function BeatSchöpfer() {
const title = "Beat-Schöpfer";
const keywords = "Beats erstellen, Beat-Schöpfer, Musikgenerator, Liedtextgenerator, Songwriting-Tool, Musiksoftware, Musikplattform, Lyrikerstellung, Songwriting-Website, Beat-Making";
const description = "Lernen Sie, wie man mit KI Beats und Lieder schreibt";
const longDescription = "Lyrical Labs ist eine Musikplattform, die KI nutzt, um Ihnen schneller beim Schreiben von Liedtexten und Melodien zu helfen. Es ist Ihr persönlicher Beat-Schöpfer, abgestimmt auf Ihre persönlichen Geschmäcke und Bedürfnisse beim Songwriting!";
const h1 = "Lernen Sie, Beats und Lieder mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Texte und Melodien.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Beat-Schöpfer";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jedes Genres erstellen.";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Beat-Schöpfer zusammen, um das perfekte Lied zu kreieren. Sobald Sie Liedtexte und Musik haben, können Sie durch das Sprechen mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem eingebauten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, also behalten Sie alle Rechte an der Musik und den Liedtexten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu nutzen und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Das kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, so dass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words'-Wörterbuch an Ihrer Seite, um Sie während Ihres Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmäcke und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Songwriting zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
