import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function BeatMakerOnlineGerman() {
const title = "Beat Maker Online";
const keywords = "Beat Maker, Musikgenerator, Lyrikgenerator, Musikproduktion, Musikproduktionssoftware, Musikproduktionstool, Online Beat Maker, Beat Maker App, Musik erstellen, Beats erstellen";
const description = "Erschaffe beeindruckende Beats mit unserem AI-basierten Beat Maker online";
const longDescription = "Lyrical Labs ist eine Plattform zur Musikproduktion, die KI verwendet, um Ihnen beim schnellen Erstellen von Beats und Lyrik zu helfen. Es ist Ihr persönlicher Musikproduktionsassistent, der auf Ihre individuellen musikalischen Bedürfnisse und Vorlieben zugeschnitten ist!";
const h1 = "Entdecken Sie die Kraft der KI mit unserem Online Beat Maker.";
const h2 = "Revolutionieren Sie Ihre Musikproduktion mit unserem AI-gesteuerten Lyrik- und Beat Maker.";
const sectionHeader = "Ihr ultimativer Partner für Musikproduktion";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Musikproduktionsassistent";
const featureTitle3 = "Intelligenter integrierter Wörterbuch";
const featureTitle4 = "Erstellen Sie Musik in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für die Musikproduktion erwartet Sie mit einer unerschöpflichen Quelle an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Beats jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-basierten Musikpartner zusammen, um den perfekten Beat zu kreieren. Sobald Sie Beats und Lyrik haben, können Sie durch die Interaktion mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Beats zu helfen.";
const featureParagraph4 = "Mit über 100 Sprachen zur Auswahl können Sie lernen, Songtexte und Beats in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Musik vollständig für mich zu schreiben?";
const faq3 = "Wie hilft mir die Plattform, den Writer's Block zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Musikproduktionsprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, so dass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, vollständige Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihren eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Writer's Block kann für jeden Musikschaffenden eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI wird Ihnen einen endlosen Strom kreativer und origineller Ideen liefern, mit denen Sie arbeiten können. Dies kann helfen, Ihren Musikproduktionsprozess anzukurbeln und Sie wieder auf Kurs zu bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Quelle an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte und Beats nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter' Wörterbuch an Ihrer Seite, um Sie während des gesamten Musikproduktionsprozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, zugeschnitten auf Ihre eigenen persönlichen Geschmacks- und Musikbedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man einen Song schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art zu lernen, wie man einen Song schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
