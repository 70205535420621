import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function OnlineBeatMakerGerman() {
const title = "Online-Beat-Maker";
const keywords = "Online-Beat-Maker, Beat-Herstellung, Musikgenerator, Musik, Liedermacher, Liedermacher-Assistent, Liedermacher-App, Liedermacher-Plattform, Liedermacher-Software, Liedermacher-Werkzeug, Liedermacher-Werkzeuge, Liedermacher-Website, wie man ein Lied schreibt";
const description = "Erfahren Sie, wie Sie mit KI Musik und Liedtexte erstellen können";
const longDescription = "Lyrical Labs ist eine Liedermacher-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Liedermacher-Assistent, angepasst an Ihre eigenen Geschmacks- und Liedermacher-Bedürfnisse!";
const h1 = "Erfahren Sie, wie man mit der Kraft der KI Lieder und Beats erstellt.";
const h2 = "Revolutionieren Sie Ihre Liedermacherei und erhalten Sie unendliche Inspiration mit unserem KI-gestützten Musikgenerator.";
const sectionHeader = "Der ultimative Begleiter für Liedermacher";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Liedermacher-Assistent";
const featureTitle3 = "Intelligente integrierte Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Liederschreiben erwartet Sie mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jeder Art generieren";
const featureParagraph2 = "Kollaborieren Sie mit unserem KI-Liedermacher-Partner, um das perfekte Lied zu schaffen. Sobald Sie Texte und Musik haben, können Sie durch das Sprechen mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform kommt mit einem intelligenten integrierten Wörterbuch, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Liederschreibeprozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, also behalten Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Versorgung mit Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI komplette Textsätze und musikalische Melodien generieren kann, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und entmutigende Erfahrung für jeden Liedermacher sein. Doch mit unserer Plattform müssen Sie sich nie Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Liederschreibeprozess in Schwung bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, das Ihnen während des gesamten Liederschreibeprozesses hilft. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacks- und Liedermacher-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundendienst, wirklich coole Möglichkeit, zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
