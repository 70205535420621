import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function LiederSchreiben() {
const title = "Lieder Schreiben";
const keywords = "Lieder schreiben, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man Lieder schreibt";
const description = "Lernen Sie, Lieder mit KI zu schreiben";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmäcker und Bedürfnisse beim Songwriting!";
const h1 = "Lernen Sie, Lieder mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und holen Sie sich unendliche Inspiration mit unserem KI-gesteuerten Textgenerator.";
const sectionHeader = "Der ultimative Begleiter für Songwriting";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu gestalten. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben und Ihre musikalische Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, daher behalten Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, vollständige Textsets und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Writer's Block kann eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen mehr zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Gang bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, und gibt Ihnen die Freiheit zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Songwriting-Prozesses unterstützt. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre persönlichen Geschmäcker und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
