import React from 'react'
import Footer from "../../components/Footer";
import styles from "../Homescreen/Homescreen.module.css";
import { Helmet } from 'react-helmet';
import SocialProof from './SocialProof';
import { act } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';

function LandingPageTemplate({ 
  title, 
  keywords, 
  description, 
  longDescription, 
  h1, 
  h2, 
  sectionHeader, 
  featureTitle1, 
  featureTitle2, 
  featureTitle3, 
  featureTitle4, 
  featureParagraph1, 
  featureParagraph2, 
  featureParagraph3, 
  featureParagraph4,
  faq1,
  faq2,
  faq3,
  faq4,
  faqa1,
  faqa2,
  faqa3,
  faqa4a,
  faqa4b,
  quote1,
  quote2,
  quote3
 }) {

  const navigate = useNavigate();
  const url = 'https://frozen-springs-61240.herokuapp.com'
  const captureExperiment = () => {
    fetch(`${url}/experiment-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experimentName: 'landing_page',
        experimentDescription: "Various different elements on landing page",
        variants: {"h1": h1, "h2": h2, "button": buttonVariant, "buttonColour": buttonColourVariant},
        action: "click",
      })
    })
    .catch((error) => {
    });
  }
  const getVariant = () => {
    const variants = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  
  const [variant, setVariant] = useLocalStorage('landingPageButton', getVariant());
  const [variant2, setVariant2] = useLocalStorage('landingPageButtonColour', getVariant());


  let buttonVariant;

  if (variant === 'A') {
    buttonVariant = "Try it, it's Free!";
  } else if (variant === 'B') {
    buttonVariant = "Get Started for Free!";
  } else if (variant === 'C') {
    buttonVariant = "Start Your Free Trial";
  } else if (variant === 'D') {
    buttonVariant = "Start Writing Songs Now!";
  } else if (variant === 'E') {
    buttonVariant = "Create Music Now";
  } else if (variant === 'F') {
    buttonVariant = "Try It Free";
  } else if (variant === 'G') {
    buttonVariant = "Start Writing Songs!";
  } else if (variant === 'H') {
    buttonVariant = "Start Composing";
  } else if (variant === 'I') {
    buttonVariant = "Get Started Now!";
  } else if (variant === 'J') {
    buttonVariant = "Compose with AI";
  } else if (variant === 'K') {
    buttonVariant = "Unlock your creativity";
  } else {
    buttonVariant = "Try it, it's Free";
  }

  let buttonColourVariant;
  let buttonHoverColourVariant;
  let buttonRingColourVariant;

  if (variant2 === 'A') {
    buttonColourVariant = "bg-indigo-500";
    buttonHoverColourVariant = "hover:bg-indigo-700";
    buttonRingColourVariant = "focus:ring-indigo-500";
  } else if (variant2 === 'B') {
    buttonColourVariant = "bg-green-500";
    buttonHoverColourVariant = "hover:bg-green-700";
    buttonRingColourVariant = "focus:ring-green-500";
  } else if (variant2 === 'C') {
    buttonColourVariant = "bg-red-500";
    buttonHoverColourVariant = "hover:bg-red-700";
    buttonRingColourVariant = "focus:ring-red-500";
  } else if (variant2 === 'D') {
    buttonColourVariant = "bg-yellow-500";
    buttonHoverColourVariant = "hover:bg-yellow-700";
    buttonRingColourVariant = "focus:ring-yellow-500";
  } else if (variant2 === 'E') {
    buttonColourVariant = "bg-blue-500";
    buttonHoverColourVariant = "hover:bg-blue-700";
    buttonRingColourVariant = "focus:ring-blue-500";
  } else if (variant2 === 'F') {
    buttonColourVariant = "bg-purple-500";
    buttonHoverColourVariant = "hover:bg-purple-700";
    buttonRingColourVariant = "focus:ring-purple-500";
  } else if (variant2 === 'G') {
    buttonColourVariant = "bg-pink-500";
    buttonHoverColourVariant = "hover:bg-pink-700";
    buttonRingColourVariant = "focus:ring-pink-500";
  } else if (variant2 === 'H') {
    buttonColourVariant = "bg-orange-500";
    buttonHoverColourVariant = "hover:bg-orange-700";
    buttonRingColourVariant = "focus:ring-orange-500";
  } else if (variant2 === 'I') {
    buttonColourVariant = "bg-rose-500";
    buttonHoverColourVariant = "hover:bg-rose-700";
    buttonRingColourVariant = "focus:ring-rose-500";
  } else if (variant2 === 'J') {
    buttonColourVariant = "bg-lime-500";
    buttonHoverColourVariant = "hover:bg-lime-700";
    buttonRingColourVariant = "focus:ring-lime-500";
  } else if (variant2 === 'K') {
    buttonColourVariant = "bg-cyan-500";
    buttonHoverColourVariant = "hover:bg-cyan-700";
    buttonRingColourVariant = "focus:ring-cyan-500";
  } else {
    buttonColourVariant = "bg-indigo-500";
    buttonHoverColourVariant = "hover:bg-indigo-700";
    buttonRingColourVariant = "focus:ring-indigo-500";
  }

  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={`${keywords}`} />
      <meta property="og:description" content={longDescription} />
    </Helmet>
    <div type="body" name="LandingPage" className="min-w-full bg-[#101010]">
      <div className="min-h-screen pt-12 bg-[#101010]">
        <div className="content-between min-h-screen">
        <div className="row-auto content-between flex-wrap" id = "contents">
          <section className="flex flex-col bg-[#101010] justify-between mt-12 pb-12 lg:px-24 lg:flex-row">
            <div className="block rounded-md lg:mt-16 px-16 lg:px-0">
              <h1 className="text-left text-4xl font-bold tracking-tight text-white">
                {h1}
              </h1>
              <h2 className="pt-5 text-left text-xl font-medium tracking-tight text-white">
                {h2}
              </h2>
              <button
                type="try"
                onClick={(e) => { captureExperiment()
                  navigate('/app')
                }}
                className={`
                group
                my-6 
                relative 
                flex 
                w-full 
                justify-center 
                rounded-md 
                border 
                border-transparent 
                ${buttonColourVariant}
                py-3 
                px-4 
                text-sm 
                font-bold 
                text-white 
                ${buttonHoverColourVariant}
                focus:outline-none 
                focus:ring-2 
                ${buttonRingColourVariant}
                focus:ring-offset-2
                `}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                </span>
                {buttonVariant}
              </button>
              
            </div>
            <div style={{  paddingBottom: '40%', position: 'relative', width: '100%'}} className="px-12 lg:pl-16 w-0.8">
              <video style={{ position: 'absolute', width: '80%'}} src="LLDemo.mp4" autoPlay="autoplay" muted="muted" loop="loop"/>
            </div>
          </section>

          <section id="testimonials" className = "bg-white/5 pb-24 flex flex-col mt-12 py-12 border border-white/20 border-x-0 border-t-1 border-b-0">
          <div className="mx-8">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white sm:text-4xl">Testimonials</p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-18 lg:max-w-4xl">
                    <div className="relative">
                      <dt className="text-base font-semibold leading-7 text-white">
                        <SocialProof quote1={quote1} quote2={quote2} quote3={quote3}/>
                      </dt>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section id="features" className = "flex flex-col py-12 border border-white/20 border-x-0 border-t-1 border-b-0">
            <div className="mx-8">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                  <p className="mt-2 text-3xl text-center font-bold tracking-tight text-white sm:text-4xl">{sectionHeader}</p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                  <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    <div className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-white">
                        <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                          </svg>
                        </div>
                        {featureTitle1}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-white/40">{featureParagraph1}</dd>
                    </div>

                    <div className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-white">
                        <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                        </div>
                        {featureTitle2}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-white/40">{featureParagraph2}</dd>
                    </div>

                    <div className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-white">
                        <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>
                        </div>
                        {featureTitle3}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-white/40">{featureParagraph3}</dd>
                    </div>

                    <div className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-white">
                        <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                          </svg>
                        </div>
                        {featureTitle4}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-white/40">{featureParagraph4}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <a
              type="try"
              href="/app" 
              className="group my-6 flex mt-16 mx-16 lg:mx-96 justify-center rounded-md border border-transparent bg-indigo-500 py-3 text-sm font-bold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              </span>
              Try It Now
            </a>           
          </section>

          <section id = "faq" className="bg-white/5 border border-white/20 border-x-0 border-y-1">
            <div className="py-8 px-4 mx-8 lg:mx-auto sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight text-center font-extrabold text-white dark:text-white">Frequently asked questions</h2>
                <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-1">
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-white dark:text-white">
                                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                {faq1}
                            </h3>
                            <p className="text-gray-500 text-sm dark:text-gray-400">{faqa1}</p>
                        </div>
                        <div className="mb-10">                        
                            <h3 className="flex items-center mb-4 text-lg font-medium text-white dark:text-white">
                                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                {faq2}
                            </h3>
                            <p className="text-gray-500 text-sm dark:text-gray-400">{faqa2}</p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-white dark:text-white">
                                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                {faq3}
                            </h3>
                            <p className="text-gray-500 text-sm dark:text-gray-400">{faqa3}</p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-white dark:text-white">
                                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                                {faq4}
                            </h3>
                            <p className="text-gray-500 text-sm dark:text-gray-400">{faqa4a}</p>
                            <br/>
                            <p className="text-gray-500 text-sm dark:text-gray-400">{faqa4b}</p>
                        </div>
                        <div>
                          <a href="/landings">Landing Pages</a>
                        </div>
                        <div className="mb-5">
                          <a href="/partners">Partners</a>
                        </div>
                        <a href="https://www.producthunt.com/posts/lyrical-labs?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lyrical&#0045;labs" target="_blank">
                          <img className="mb-5" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=440489&theme=neutral" alt="Lyrical&#0032;Labs - Generate&#0032;AI&#0032;music&#0032;melodies&#0032;and&#0032;lyrics&#0032;&#0045;&#0032;DALL&#0045;E&#0032;for&#0032;music | Product Hunt" style={{width: '250px', height: '54px', display: 'flex'}} width="250" height="54" />
                        </a>
                    </div>
        
                </div>
            </div>

          </section>
        </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
    )
}

export default LandingPageTemplate;