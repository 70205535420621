import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SingerSongwriterGerman() {
const title = "Singer-Songwriter";
const keywords = "Singer-Songwriter, Musikgenerator, Liedtextgenerator, Musik schreiben, Singer-Songwriter-App, Singer-Songwriter-Plattform, Singer-Songwriter-Software, Singer-Songwriter-Tool, Singer-Songwriter-Werkzeuge, Singer-Songwriter-Website, wie man ein Lied schreibt";
const description = "Lerne, wie man als Singer-Songwriter mit KI Lieder schreibt";
const longDescription = "Lyrical Labs ist eine Plattform für Singer-Songwriter, die KI nutzt, um dir schneller beim Schreiben von Texten und Musik zu helfen. Es ist dein persönlicher Singer-Songwriter-Assistent, angepasst an deine eigenen persönlichen Geschmacksrichtungen und Bedürfnisse beim Liederschreiben!";
const h1 = "Lerne, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Liederschreiben und erhalte unendliche Inspiration mit unserer KI-gestützten Lyrics.";
const sectionHeader = "Der ultimative Singer-Songwriter-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Singer-Songwriter-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Liederschreiben erwartet dich mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre generieren.";
const featureParagraph2 = "Kollaboriere mit unserem KI-Singer-Songwriter-Partner, um das perfekte Lied zu schaffen. Sobald du Texte und Musik hast, kannst du durch Gespräche mit der KI Rückmeldung geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Vollenden deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer’s Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Liederschreibprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, somit behältst du alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist, dir eine endlose Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, komplette Sets von Lyrics und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu verwenden und deinen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Writer's Block kann eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Liederschreibprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Versorgung mit Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Vorlieben zu bieten, was dir die Freiheit gibt zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich erlaubt dir die Plattform, die Lyrics nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich während deines Liederschreibprozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmacksrichtungen und Liederschreibbedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
