import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WoFangeIchAnWennIchEinenSongSchreibe() {
const title = "Wo Fange Ich An, Wenn Ich Einen Song Schreibe?";
const keywords = "Liedtexte, Musikgenerator, Musik, Liedschreiben, Liedschreibungsassistent, Liedschreibungs-App, Liedschreibungsplattform, Liedschreibungssoftware, Liedschreibungswerkzeug, Liedschreibungswerkzeuge, Liedschreibungswebsite, Wie schreibe ich ein Lied?";
const description = "Lerne, wie man mit KI ein Lied schreibt";
const longDescription = "Lyrical Labs ist eine Liedschreibungsplattform, die KI nutzt, um Ihnen beim schnelleren Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Liedschreibungsassistent, angepasst an Ihre persönlichen Geschmäcke und Liedschreibungsbedürfnisse!";
const h1 = "Lerne, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Liedschreiben und hole unendliche Inspiration aus unserem KI-gesteuerten Lyrics-Generator.";
const sectionHeader = "Der ultimative Begleiter für das Liedschreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Liedschreibungsassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Liedschreibungsinspiration erwartet Sie mit einem unerschöpflichen Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Kollaboriere mit unserem KI-Liedschreibpartner, um das perfekte Lied zu kreieren. Sobald du Texte und Musik hast, kannst du durch Gespräche mit der KI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Liedschreibungsprozess zu beschleunigen?";
const faqa1 = "Ja! Lyrical Labs ist 100% gebührenfrei, sodass Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen, behalten.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unerschöpfliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie dazu, diese als Ausgangspunkt zu nutzen und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Liedschreiber eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, Ideen auszugehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen unendlichen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Liedschreibungsprozess in Gang setzen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist so konzipiert, dass sie Ihnen eine endlose Menge an Ideen und Vorschlägen bietet, basierend auf Ihren gewählten stilistischen Präferenzen, und Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht es Ihnen die Plattform, die Texte nach Ihrem eigenen Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words' Wörterbuch an Ihrer Seite, um Sie während des gesamten Liedschreibungsprozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, abgestimmt auf Ihre eigenen persönlichen Geschmäcke und Liedschreibungsbedürfnisse!";
const quote1 = "Das ist ein Game-Changer, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
