import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManEdmMusikMacht() {
const title = "Wie Man Edm-Musik Macht";
const keywords = "EDM-Musik, Musikgenerator, Liedtexte erstellen, Musikproduktion, Musikproduktionsassistent, Musikproduktions-App, Musikproduktionsplattform, Musikproduktionssoftware, Musikproduktionstool, Musikproduktionstools, Musikproduktions-Website, wie man EDM-Musik macht";
const description = "Lerne, wie man EDM-Musik mit Hilfe von KI macht";
const longDescription = "Lyrical Labs ist eine Musikproduktionsplattform, die KI nutzt, um dir schneller beim Schreiben von Liedtexten und beim Erstellen von EDM-Musik zu helfen. Es ist dein persönlicher Musikproduktionsassistent, angepasst an deine eigenen musikalischen Vorlieben und Bedürfnisse!";
const h1 = "Lerne, wie man EDM-Musik mit der Kraft der KI macht.";
const h2 = "Revolutioniere deine Musikproduktion und erhalte unendliche Inspiration mit unserem KI-gesteuerten Lyrics-Generator.";
const sectionHeader = "Der ultimative Musikproduktionsbegleiter";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Dein persönlicher Musikproduktionsassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Musik in jeder Sprache schreiben";
const featureParagraph1 = "Unendliche Inspiration für das Songwriting erwartet dich mit einem nie versiegenden Reservoir an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Musikproduktionspartner zusammen, um den perfekten EDM-Track zu kreieren. Sobald du Texte und Musik hast, kannst du Feedback durch Gespräche mit der KI geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Vervollständigen deiner Lyrics zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikproduktionsprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, du behältst alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Menge an Inspiration zu bieten und dir zu helfen, Schreibblockaden zu überwinden. Obwohl die KI fähig ist, komplette Textsets und musikalische Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und entmutigende Erfahrung für jeden Musikproduzenten sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Musikproduktionsprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Menge an Ideen und Vorschlägen basierend auf deinen stilistischen Präferenzen zu bieten, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich erlaubt dir die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hochspezialisierten 'Verwandte Wörter'-Wörterbuch zu deiner Seite, um dich während deines Musikproduktionsprozesses zu unterstützen. Es ist wie dein eigener persönlicher Kreativassistent, angepasst an deine eigenen musikalischen Vorlieben und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man EDM-Musik macht";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, zu lernen, wie man EDM-Musik macht";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
