import 音楽の作り方JaPage from './pages/LandingPages/音楽の作り方Ja';
import 歌詞ジェネレーター無料JaPage from './pages/LandingPages/歌詞ジェネレーター無料Ja';
import 最高のai音楽ジェネレーターJaPage from './pages/LandingPages/最高のai音楽ジェネレーターJa';
import クロムミュージックラボJaPage from './pages/LandingPages/クロムミュージックラボJa';
import 歌JaPage from './pages/LandingPages/歌Ja';
import 曲を書くJaPage from './pages/LandingPages/曲を書くJa';
import 歌詞を書くJaPage from './pages/LandingPages/歌詞を書くJa';
import 音楽を書くJaPage from './pages/LandingPages/音楽を書くJa';
import 音楽の書き方JaPage from './pages/LandingPages/音楽の書き方Ja';
import AiジェネレーターJaPage from './pages/LandingPages/AiジェネレーターJa';
import アイソングライターJaPage from './pages/LandingPages/アイソングライターJa';
import 歌の歌詞の書き方JaPage from './pages/LandingPages/歌の歌詞の書き方Ja';
import 曲の書き方JaPage from './pages/LandingPages/曲の書き方Ja';
import あい歌詞JaPage from './pages/LandingPages/あい歌詞Ja';
import Ai歌詞ジェネレーターJaPage from './pages/LandingPages/Ai歌詞ジェネレーターJa';
import AiBeatGeneratorGermanPage from './pages/LandingPages/AiBeatGeneratorGerman';
import RapTexterstellerPage from './pages/LandingPages/RapTextersteller';
import GenialerLyrikerPage from './pages/LandingPages/GenialerLyriker';
import TexterstellerAiPage from './pages/LandingPages/TexterstellerAi';
import AiLyrikerPage from './pages/LandingPages/AiLyriker';
import RapLyrikerPage from './pages/LandingPages/RapLyriker';
import LyrikerPage from './pages/LandingPages/Lyriker';
import SongwriterZuMietenPage from './pages/LandingPages/SongwriterZuMieten';
import SongwriterHelferPage from './pages/LandingPages/SongwriterHelfer';
import SongwriterGeneratorGermanPage from './pages/LandingPages/SongwriterGeneratorGerman.js';
import WieSchreibtManEinLiedPage from './pages/LandingPages/WieSchreibtManEinLied';
import WieManEinLiedFürAnfängerSchreibtPage from './pages/LandingPages/WieManEinLiedFürAnfängerSchreibt';
import WieManEinLiebesliedSchreibtPage from './pages/LandingPages/WieManEinLiebesliedSchreibt';
import WieManEinenRapSongSchreibtPage from './pages/LandingPages/WieManEinenRapSongSchreibt';
import WieSchreibeIchSongtexteOhneMusikPage from './pages/LandingPages/WieSchreibeIchSongtexteOhneMusik';
import WieManLiederSchreibtPage from './pages/LandingPages/WieManLiederSchreibt';
import WoFangeIchAnWennIchEinenSongSchreibePage from './pages/LandingPages/WoFangeIchAnWennIchEinenSongSchreibe';
import WieManAnfängtLiedtexteZuSchreibenPage from './pages/LandingPages/WieManAnfängtLiedtexteZuSchreiben';
import WieManAnfängtEinLiedZuSchreibenPage from './pages/LandingPages/WieManAnfängtEinLiedZuSchreiben';
import WieManSongwritingLerntPage from './pages/LandingPages/WieManSongwritingLernt';
import WieManZumSongwritingKommtPage from './pages/LandingPages/WieManZumSongwritingKommt';
import WasIstSongwritingPage from './pages/LandingPages/WasIstSongwriting';
import WieManMitDemSongwritingBeginntPage from './pages/LandingPages/WieManMitDemSongwritingBeginnt';
import WasIstEinSongwriterPage from './pages/LandingPages/WasIstEinSongwriter';
import WieManEinSongwriterWirdPage from './pages/LandingPages/WieManEinSongwriterWird';
import WieManSongwriterWirdPage from './pages/LandingPages/WieManSongwriterWird';
import AmerikanischerSongwriterPage from './pages/LandingPages/AmerikanischerSongwriter';
import SongwritingVorlagePage from './pages/LandingPages/SongwritingVorlage';
import SongwritingIdeenPage from './pages/LandingPages/SongwritingIdeen';
import SongwritingWebsiteGermanPage from './pages/LandingPages/SongwritingWebsiteGerman';
import TippsZumSongwritingPage from './pages/LandingPages/TippsZumSongwriting';
import SongwritingStrukturPage from './pages/LandingPages/SongwritingStruktur';
import SongwritingGeneratorGermanPage from './pages/LandingPages/SongwritingGeneratorGerman';
import SongwritingKiPage from './pages/LandingPages/SongwritingKi';
import AnregungenZumSongwritingPage from './pages/LandingPages/AnregungenZumSongwriting';
import SongwritingTippsPage from './pages/LandingPages/SongwritingTipps';
import WieManTechnomusikMachtPage from './pages/LandingPages/WieManTechnomusikMacht';
import WieManEdmMusikMachtPage from './pages/LandingPages/WieManEdmMusikMacht';
import WieManEinenRapBeatMachtPage from './pages/LandingPages/WieManEinenRapBeatMacht';
import BesteRapBeatsPage from './pages/LandingPages/BesteRapBeats';
import RapBeatsMachenPage from './pages/LandingPages/RapBeatsMachen';
import RapSchlägtKostenlosPage from './pages/LandingPages/RapSchlägtKostenlos';
import KostenloseRapBeatsPage from './pages/LandingPages/KostenloseRapBeats';
import WieManTexteRapptPage from './pages/LandingPages/WieManTexteRappt';
import WieManFreestyleRapptPage from './pages/LandingPages/WieManFreestyleRappt';
import WieManSchnellRapptPage from './pages/LandingPages/WieManSchnellRappt';
import WieManBesserRapptPage from './pages/LandingPages/WieManBesserRappt';
import WieManRapptPage from './pages/LandingPages/WieManRappt';
import RapMusikPage from './pages/LandingPages/RapMusik';
import RapTextePage from './pages/LandingPages/RapTexte';
import BesteKostenloseBeatMakingSoftwarePage from './pages/LandingPages/BesteKostenloseBeatMakingSoftware';
import BesteBeatMakingSoftwarePage from './pages/LandingPages/BesteBeatMakingSoftware';
import KostenloseBeatMakingSoftwarePage from './pages/LandingPages/KostenloseBeatMakingSoftware';
import BeatmachenPage from './pages/LandingPages/Beatmachen';
import BeatSchöpferPage from './pages/LandingPages/BeatSchöpfer';
import ZufallsgeneratorFürBeatsPage from './pages/LandingPages/ZufallsgeneratorFürBeats';
import BeatMakerOnlineKostenlosPage from './pages/LandingPages/BeatMakerOnlineKostenlos';
import KostenloserOnlineBeatMakerPage from './pages/LandingPages/KostenloserOnlineBeatMaker';
import BeatMakerKostenlosPage from './pages/LandingPages/BeatMakerKostenlos';
import KostenloserBeatMakerPage from './pages/LandingPages/KostenloserBeatMaker';
import BeatmakerGermanPage from './pages/LandingPages/BeatmakerGerman';
import RapAutorPage from './pages/LandingPages/RapAutor';
import RapSchöpferPage from './pages/LandingPages/RapSchöpfer';
import KiRapHerstellerPage from './pages/LandingPages/KiRapHersteller';
import RapHerstellerOnlinePage from './pages/LandingPages/RapHerstellerOnline';
import RapMacherPage from './pages/LandingPages/RapMacher';
import TextZuRapGeneratorPage from './pages/LandingPages/TextZuRapGenerator';
import GeneratorFürElektronischeMusikPage from './pages/LandingPages/GeneratorFürElektronischeMusik';
import TechnomusikPage from './pages/LandingPages/Technomusik';
import LiedtexteDesSongwritersPage from './pages/LandingPages/LiedtexteDesSongwriters';
import MusiklaborSongmacherPage from './pages/LandingPages/MusiklaborSongmacher';
import OnlineSongmacherPage from './pages/LandingPages/OnlineSongmacher';
import KiSongmacherPage from './pages/LandingPages/KiSongmacher';
import LiedermacherPage from './pages/LandingPages/Liedermacher';
import TextZuSongGeneratorPage from './pages/LandingPages/TextZuSongGenerator';
import AiCoversongGeneratorGermanPage from './pages/LandingPages/AiCoversongGeneratorGerman';
import AiSongGeneratorKostenlosPage from './pages/LandingPages/AiSongGeneratorKostenlos';
import ZufallsgeneratorFürLiederNachGenrePage from './pages/LandingPages/ZufallsgeneratorFürLiederNachGenre';
import ZufallsgeneratorFürLiederPage from './pages/LandingPages/ZufallsgeneratorFürLieder';
import AiSonggeneratorGermanPage from './pages/LandingPages/AiSonggeneratorGerman';
import PopTextgeneratorPage from './pages/LandingPages/PopTextgenerator';
import ZufallsgeneratorFürMusikPage from './pages/LandingPages/ZufallsgeneratorFürMusik';
import BesteKiMusikPage from './pages/LandingPages/BesteKiMusik';
import AiMusikgeneratorAusTextPage from './pages/LandingPages/AiMusikgeneratorAusText';
import AiMusikgeneratorKostenlosPage from './pages/LandingPages/AiMusikgeneratorKostenlos';
import MusikmacherPage from './pages/LandingPages/Musikmacher';
import UrheberrechtsfreieMusikPage from './pages/LandingPages/UrheberrechtsfreieMusik';
import GemafreieMusikPage from './pages/LandingPages/GemafreieMusik';
import KostenloserMusikDownloadPage from './pages/LandingPages/KostenloserMusikDownload';
import Mp3MusikHerunterladenPage from './pages/LandingPages/Mp3MusikHerunterladen';
import EntspannendeMusikPage from './pages/LandingPages/EntspannendeMusik';
import KostenloseMusikPage from './pages/LandingPages/KostenloseMusik';
import AiTextgeneratorKostenlosPage from './pages/LandingPages/AiTextgeneratorKostenlos';
import FreestyleTextgeneratorPage from './pages/LandingPages/FreestyleTextgenerator';
import AiSongtextGeneratorPage from './pages/LandingPages/AiSongtextGenerator';
import RapTextgeneratorPage from './pages/LandingPages/RapTextgenerator';
import LiedtextgeneratorPage from './pages/LandingPages/Liedtextgenerator';
import MusikUndTextePage from './pages/LandingPages/MusikUndTexte';
import WieManMusikMachtPage from './pages/LandingPages/WieManMusikMacht';
import TextgeneratorKostenlosPage from './pages/LandingPages/TextgeneratorKostenlos';
import SongtextGeneratorPage from './pages/LandingPages/SongtextGenerator';
import BesterKiMusikgeneratorPage from './pages/LandingPages/BesterKiMusikgenerator';
import ChromMusiklaborPage from './pages/LandingPages/ChromMusiklabor';
import LiederSchreibenPage from './pages/LandingPages/LiederSchreiben';
import SongtexteSchreibenPage from './pages/LandingPages/SongtexteSchreiben';
import MusikSchreibenPage from './pages/LandingPages/MusikSchreiben';
import WieManMusikSchreibtPage from './pages/LandingPages/WieManMusikSchreibt';
import KiMusikgeneratorPage from './pages/LandingPages/KiMusikgenerator';
import KiGeneratorPage from './pages/LandingPages/KiGenerator';
import LyrikgeneratorPage from './pages/LandingPages/Lyrikgenerator';
import WieManLiedtexteSchreibtPage from './pages/LandingPages/WieManLiedtexteSchreibt';
import KiSongwriterPage from './pages/LandingPages/KiSongwriter';
import WieManEinenLiedtextSchreibtPage from './pages/LandingPages/WieManEinenLiedtextSchreibt';
import WieManEinLiedSchreibtPage from './pages/LandingPages/WieManEinLiedSchreibt';
import AiTextePage from './pages/LandingPages/AiTexte';
import AiTextgeneratorPage from './pages/LandingPages/AiTextgenerator';
import GénérateurDeTexteEnRapPage from './pages/LandingPages/GénérateurDeTexteEnRap';
import GénérateurDeRapFreestylePage from './pages/LandingPages/GénérateurDeRapFreestyle';
import GénérateurDeRapAiPage from './pages/LandingPages/GénérateurDeRapAi';
import GénérateurDeRapPage from './pages/LandingPages/GénérateurDeRap';
import GénérateurDeMusiqueÉlectroniquePage from './pages/LandingPages/GénérateurDeMusiqueÉlectronique';
import MusiqueTechnoPage from './pages/LandingPages/MusiqueTechno';
import ParolesDuCréateurDeChansonsPage from './pages/LandingPages/ParolesDuCréateurDeChansons';
import CréateurDeChansonsEnLignePage from './pages/LandingPages/CréateurDeChansonsEnLigne';
import CréateurDeChansonsAiPage from './pages/LandingPages/CréateurDeChansonsAi';
import CréateurDeChansonsDeLaboratoireDeMusiqueChroméPage from './pages/LandingPages/CréateurDeChansonsDeLaboratoireDeMusiqueChromé';
import CréateurDeChansonsPage from './pages/LandingPages/CréateurDeChansons';
import GénérateurDeTexteEnChansonPage from './pages/LandingPages/GénérateurDeTexteEnChanson';
import GénérateurDeChansonDeRepriseAiPage from './pages/LandingPages/GénérateurDeChansonDeRepriseAi';
import GénérateurDeChansonsAiGratuitPage from './pages/LandingPages/GénérateurDeChansonsAiGratuit';
import GénérateurDeChansonsAléatoiresParGenrePage from './pages/LandingPages/GénérateurDeChansonsAléatoiresParGenre';
import GénérateurDeChansonsRapPage from './pages/LandingPages/GénérateurDeChansonsRap';
import GénérateurDeChansonsAléatoiresPage from './pages/LandingPages/GénérateurDeChansonsAléatoires';
import GénérateurDeChansonsAiPage from './pages/LandingPages/GénérateurDeChansonsAi';
import GénérateurDeParolesPopPage from './pages/LandingPages/GénérateurDeParolesPop';
import GénérateurDeMusiqueAléatoirePage from './pages/LandingPages/GénérateurDeMusiqueAléatoire';
import MeilleureMusiqueIaPage from './pages/LandingPages/MeilleureMusiqueIa';
import GénérateurDeMusiqueAiÀPartirDeTextePage from './pages/LandingPages/GénérateurDeMusiqueAiÀPartirDeTexte';
import GénérateurDeMusiqueAiGratuitPage from './pages/LandingPages/GénérateurDeMusiqueAiGratuit';
import CréateurDeMusiquePage from './pages/LandingPages/CréateurDeMusique';
import MusiqueLibreDeDroitsPage from './pages/LandingPages/MusiqueLibreDeDroits';
import TéléchargementDeMusiqueGratuitPage from './pages/LandingPages/TéléchargementDeMusiqueGratuit';
import TéléchargementDeMusiqueMp3Page from './pages/LandingPages/TéléchargementDeMusiqueMp3';
import MusiqueRelaxantePage from './pages/LandingPages/MusiqueRelaxante';
import MusiqueGratuitePage from './pages/LandingPages/MusiqueGratuite';
import GénérateurDeParolesAiGratuitPage from './pages/LandingPages/GénérateurDeParolesAiGratuit';
import GénérateurDeParolesFreestylePage from './pages/LandingPages/GénérateurDeParolesFreestyle';
import GénérateurDeParolesDeChansonsAiPage from './pages/LandingPages/GénérateurDeParolesDeChansonsAi';
import MusiqueEtParolesPage from './pages/LandingPages/MusiqueEtParoles';
import CommentFaireDeLaMusiquePage from './pages/LandingPages/CommentFaireDeLaMusique';
import GénérateurDeParolesGratuitPage from './pages/LandingPages/GénérateurDeParolesGratuit';
import GénérateurDeParolesDeChansonsPage from './pages/LandingPages/GénérateurDeParolesDeChansons';
import MeilleurGénérateurDeMusiqueIaPage from './pages/LandingPages/MeilleurGénérateurDeMusiqueIa';
import LaboratoireDeMusiqueChroméPage from './pages/LandingPages/LaboratoireDeMusiqueChromé';
import ÉcrireDesChansonsPage from './pages/LandingPages/ÉcrireDesChansons';
import CommentÉcrireDeLaMusiquePage from './pages/LandingPages/CommentÉcrireDeLaMusique';
import GénérateurDeParolesDeRapPage from './pages/LandingPages/GénérateurDeParolesDeRap';
import GénérateurDeMusiqueAiPage from './pages/LandingPages/GénérateurDeMusiqueAi';
import GénérateurDIaPage from './pages/LandingPages/GénérateurDIa';
import CommentÉcrireDesParolesPage from './pages/LandingPages/CommentÉcrireDesParoles';
import AuteurCompositeurInterprètePage from './pages/LandingPages/AuteurCompositeurInterprète';
import CommentÉcrireLesParolesDUneChansonPage from './pages/LandingPages/CommentÉcrireLesParolesDUneChanson';
import CommentÉcrireUneChansonPage from './pages/LandingPages/CommentÉcrireUneChanson';
import ParolesDAiPage from './pages/LandingPages/ParolesDAi';
import GénérateurDeParolesAiPage from './pages/LandingPages/GénérateurDeParolesAi';
import HowToComposeMusicOnComputerPage from './pages/LandingPages/HowToComposeMusicOnComputer';
import HowToComposeMusicPage from './pages/LandingPages/HowToComposeMusic';
import ComposeAiPage from './pages/LandingPages/ComposeAi';
import SongwritingAppAndroidPage from './pages/LandingPages/SongwritingAppAndroid';
import SongwritingAppIphonePage from './pages/LandingPages/SongwritingAppIphone';
import SongwritingAppOnlineFreePage from './pages/LandingPages/SongwritingAppOnlineFree';
import ChorusSongwritingAppPage from './pages/LandingPages/ChorusSongwritingApp';
import AiSongwritingAppPage from './pages/LandingPages/AiSongwritingApp';
import SongwritingAppFreePage from './pages/LandingPages/SongwritingAppFree';
import BestSongwritingAppPage from './pages/LandingPages/BestSongwritingApp';
import FreeSongwritingAppPage from './pages/LandingPages/FreeSongwritingApp';
import MakeMusicCloudPage from './pages/LandingPages/MakeMusicCloud';
import MakeMusicOnlineFreePage from './pages/LandingPages/MakeMusicOnlineFree';
import RapLyricsMakerPage from './pages/LandingPages/RapLyricsMaker';
import GeniusLyricMakerPage from './pages/LandingPages/GeniusLyricMaker';
import LyricMakerAiPage from './pages/LandingPages/LyricMakerAi';
import AiLyricMakerPage from './pages/LandingPages/AiLyricMaker';
import RapLyricMakerPage from './pages/LandingPages/RapLyricMaker';
import LyricMakerPage from './pages/LandingPages/LyricMaker';
import SongWriterForHirePage from './pages/LandingPages/SongWriterForHire';
import SongWriterHelperPage from './pages/LandingPages/SongWriterHelper';
import SongWriterGeneratorPage from './pages/LandingPages/SongWriterGenerator.js';
import HowDoYouWriteASongPage from './pages/LandingPages/HowDoYouWriteASong';
import HowToWriteASongForBeginnersPage from './pages/LandingPages/HowToWriteASongForBeginners';
import HowToWriteALoveSongPage from './pages/LandingPages/HowToWriteALoveSong';
import HowToWriteARapSongPage from './pages/LandingPages/HowToWriteARapSong';
import HowToWriteSongLyricsWithoutMusicPage from './pages/LandingPages/HowToWriteSongLyricsWithoutMusic';
import HowToWriteSongLyricsPage from './pages/LandingPages/HowToWriteSongLyrics';
import HowToWriteSongsPage from './pages/LandingPages/HowToWriteSongs';
import WhereToStartWhenWritingASongPage from './pages/LandingPages/WhereToStartWhenWritingASong';
import HowToBeginWritingASongPage from './pages/LandingPages/HowToBeginWritingASong';
import HowToStartWritingSongLyricsPage from './pages/LandingPages/HowToStartWritingSongLyrics';
import HowToStartWritingASongPage from './pages/LandingPages/HowToStartWritingASong';
import HowToLearnSongwritingPage from './pages/LandingPages/HowToLearnSongwriting';
import HowToGetIntoSongwritingPage from './pages/LandingPages/HowToGetIntoSongwriting';
import WhatIsSongwritingPage from './pages/LandingPages/WhatIsSongwriting';
import WhatIsASongwriterPage from './pages/LandingPages/WhatIsASongwriter';
import HowToBeASongwriterPage from './pages/LandingPages/HowToBeASongwriter';
import HowToBecomeASongwriterPage from './pages/LandingPages/HowToBecomeASongwriter';
import SongwriterAiPage from './pages/LandingPages/SongwriterAi';
import Songwriter from './pages/LandingPages/Songwriter';
import SongwriterGerman from './pages/LandingPages/SongwriterGerman';
import AmericanSongwriterPage from './pages/LandingPages/AmericanSongwriter';
import SingerSongwriterPage from './pages/LandingPages/SingerSongwriter';
import SingerSongwriterGermanPage from './pages/LandingPages/SingerSongwriterGerman';
import AiSongWriterPage from './pages/LandingPages/AiSongWriter';
import AiSongwriterGermanPage from './pages/LandingPages/AiSongwriterGerman';
import SongWritingAppPage from './pages/LandingPages/SongWritingApp';
import SongWritingTemplatePage from './pages/LandingPages/SongWritingTemplate';
import SongWritingIdeasPage from './pages/LandingPages/SongWritingIdeas';
import SongWritingWebsitePage from './pages/LandingPages/SongWritingWebsite';
import SongWritingStructurePage from './pages/LandingPages/SongWritingStructure';
import SongWritingGeneratorPage from './pages/LandingPages/SongWritingGenerator';
import SongwritingPromptsPage from './pages/LandingPages/SongwritingPrompts';
import SongwritingTipsPage from './pages/LandingPages/SongwritingTips';
import SongwritingPage from './pages/LandingPages/Songwriting';
import SongwritingGermanPage from './pages/LandingPages/SongwritingGerman';
import HowToMakeTechnoMusicPage from './pages/LandingPages/HowToMakeTechnoMusic';
import HowToMakeEdmMusicPage from './pages/LandingPages/HowToMakeEdmMusic';
import SongLyricsPage from './pages/LandingPages/SongLyrics';
import HowToMakeARapBeatPage from './pages/LandingPages/HowToMakeARapBeat';
import FreestyleRapBeatPage from './pages/LandingPages/FreestyleRapBeat';
import FreestyleRapBeatGermanPage from './pages/LandingPages/FreestyleRapBeatGerman';
import FreestyleRapBeatsGermanPage from './pages/LandingPages/FreestyleRapBeatsGerman';
import RapBeatMakerPage from './pages/LandingPages/RapBeatMaker';
import RapBeatMakerGermanPage from './pages/LandingPages/RapBeatMakerGerman';
import BestRapBeatsPage from './pages/LandingPages/BestRapBeats';
import FreestyleRapBeatsPage from './pages/LandingPages/FreestyleRapBeats';
import MakeRapBeatsPage from './pages/LandingPages/MakeRapBeats';
import RapBeatsFreePage from './pages/LandingPages/RapBeatsFree';
import FreeRapBeatsPage from './pages/LandingPages/FreeRapBeats';
import HowToRapLyricsPage from './pages/LandingPages/HowToRapLyrics';
import HowToRapFreestylePage from './pages/LandingPages/HowToRapFreestyle';
import HowToRapFastPage from './pages/LandingPages/HowToRapFast';
import HowToRapBetterPage from './pages/LandingPages/HowToRapBetter';
import RapBeatPage from './pages/LandingPages/RapBeat';
import RapBeatGermanPage from './pages/LandingPages/RapBeatGerman';
import HowToRapPage from './pages/LandingPages/HowToRap';
import RapMusicPage from './pages/LandingPages/RapMusic';
import RapLyricsPage from './pages/LandingPages/RapLyrics';
import BestFreeBeatMakingSoftwarePage from './pages/LandingPages/BestFreeBeatMakingSoftware';
import BestBeatMakingSoftwarePage from './pages/LandingPages/BestBeatMakingSoftware';
import BeatMakingSoftwarePage from './pages/LandingPages/BeatMakingSoftware';
import BeatMakingSoftwareGermanPage from './pages/LandingPages/BeatMakingSoftwareGerman';
import FreeBeatMakingSoftwarePage from './pages/LandingPages/FreeBeatMakingSoftware';
import BeatMakingPage from './pages/LandingPages/BeatMaking';
import BeatCreatorPage from './pages/LandingPages/BeatCreator';
import RandomBeatGeneratorPage from './pages/LandingPages/RandomBeatGenerator';
import AiBeatGeneratorPage from './pages/LandingPages/AiBeatGenerator';
import BeatGeneratorPage from './pages/LandingPages/BeatGenerator';
import BeatMakerOnlineFreePage from './pages/LandingPages/BeatMakerOnlineFree';
import OnlineBeatMakerFreePage from './pages/LandingPages/OnlineBeatMakerFree';
import FreeOnlineBeatMakerPage from './pages/LandingPages/FreeOnlineBeatMaker';
import BeatMakerFreePage from './pages/LandingPages/BeatMakerFree';
import OnlineBeatMakerPage from './pages/LandingPages/OnlineBeatMaker';
import OnlineBeatMakerGermanPage from './pages/LandingPages/OnlineBeatMakerGerman';
import BeatMakerOnlinePage from './pages/LandingPages/BeatMakerOnline';
import BeatMakerOnlineGermanPage from './pages/LandingPages/BeatMakerOnlineGerman';
import FreeBeatMakerPage from './pages/LandingPages/FreeBeatMaker';
import RapWriterPage from './pages/LandingPages/RapWriter';
import RapCreatorPage from './pages/LandingPages/RapCreator';
import AiRapMakerPage from './pages/LandingPages/AiRapMaker';
import RapMakerOnlinePage from './pages/LandingPages/RapMakerOnline';
import RapMakerPage from './pages/LandingPages/RapMaker';
import TextToRapGeneratorPage from './pages/LandingPages/TextToRapGenerator';
import FreestyleRapGeneratorPage from './pages/LandingPages/FreestyleRapGenerator';
import FreestyleRapGeneratorGermanPage from './pages/LandingPages/FreestyleRapGeneratorGerman';
import AiRapGeneratorPage from './pages/LandingPages/AiRapGenerator';
import AiRapGeneratorGermanPage from './pages/LandingPages/AiRapGeneratorGerman';
import ElectronicMusicGeneratorPage from './pages/LandingPages/ElectronicMusicGenerator';
import TechnoMusicPage from './pages/LandingPages/TechnoMusic';
import SongMakerLyricsPage from './pages/LandingPages/SongMakerLyrics';
import MusicLabSongMakerPage from './pages/LandingPages/MusicLabSongMaker';
import OnlineSongMakerPage from './pages/LandingPages/OnlineSongMaker';
import AiSongMakerPage from './pages/LandingPages/AiSongMaker';
import ChromeMusicLabSongMakerPage from './pages/LandingPages/ChromeMusicLabSongMaker';
import ChromeMusicLabSongMakerGermanPage from './pages/LandingPages/ChromeMusicLabSongMakerGerman';
import SongMakerPage from './pages/LandingPages/SongMaker';
import TextToSongGeneratorPage from './pages/LandingPages/TextToSongGenerator';
import AiCoverSongGeneratorPage from './pages/LandingPages/AiCoverSongGenerator';
import AiSongGeneratorFreePage from './pages/LandingPages/AiSongGeneratorFree';
import RandomSongGeneratorByGenrePage from './pages/LandingPages/RandomSongGeneratorByGenre';
import RapSongGeneratorPage from './pages/LandingPages/RapSongGenerator';
import RapSongGeneratorGermanPage from './pages/LandingPages/RapSongGeneratorGerman';
import RandomSongGeneratorPage from './pages/LandingPages/RandomSongGenerator';
import SongGeneratorPage from './pages/LandingPages/SongGenerator';
import SongGeneratorGermanPage from './pages/LandingPages/SongGeneratorGerman';
import AiSongGeneratorPage from './pages/LandingPages/AiSongGenerator';
import PopLyricsGeneratorPage from './pages/LandingPages/PopLyricsGenerator';
import RandomMusicGeneratorPage from './pages/LandingPages/RandomMusicGenerator';
import AiMusicGeneratorFromTextPage from './pages/LandingPages/AiMusicGeneratorFromText';
import AiMusicGeneratorFreePage from './pages/LandingPages/AiMusicGeneratorFree';
import MusicLabPage from './pages/LandingPages/MusicLab';
import MusicMakerPage from './pages/LandingPages/MusicMaker';
import CopyrightFreeMusicPage from './pages/LandingPages/CopyrightFreeMusic';
import RoyaltyFreeMusicPage from './pages/LandingPages/RoyaltyFreeMusic';
import FreeMusicDownloadPage from './pages/LandingPages/FreeMusicDownload';
import Mp3MusicDownloadPage from './pages/LandingPages/Mp3MusicDownload';
import RelaxingMusicPage from './pages/LandingPages/RelaxingMusic';
import FreeMusicPage from './pages/LandingPages/FreeMusic';
import AiLyricsGeneratorFreePage from './pages/LandingPages/AiLyricsGeneratorFree';
import FreestyleLyricsGeneratorPage from './pages/LandingPages/FreestyleLyricsGenerator';
import AiSongLyricsGeneratorPage from './pages/LandingPages/AiSongLyricsGenerator';
import RapLyricsGeneratorPage from './pages/LandingPages/RapLyricsGenerator';
import LyricsGeneratorPage from './pages/LandingPages/LyricsGenerator';
import SongLyricsGeneratorPage from './pages/LandingPages/SongLyricsGenerator';
import MusicAndLyricsPage from './pages/LandingPages/MusicAndLyrics';
import HowToMakeMusicPage from './pages/LandingPages/HowToMakeMusic';
import LyricGeneratorFreePage from './pages/LandingPages/LyricGeneratorFree';
import SongLyricGeneratorPage from './pages/LandingPages/SongLyricGenerator';
import BestAiMusicGeneratorPage from './pages/LandingPages/BestAiMusicGenerator';
import ChromeMusicLabPage from './pages/LandingPages/ChromeMusicLab';
import SongsPage from './pages/LandingPages/Songs';
import WritingSongsPage from './pages/LandingPages/WritingSongs';
import WritingLyricsPage from './pages/LandingPages/WritingLyrics';
import WritingMusicPage from './pages/LandingPages/WritingMusic';
import HowToWriteMusicPage from './pages/LandingPages/HowToWriteMusic';
import AiMusicGeneratorPage from './pages/LandingPages/AiMusicGenerator';
import AiGeneratorPage from './pages/LandingPages/AiGenerator';
import HowToWriteLyricsPage from './pages/LandingPages/HowToWriteLyrics';
import HowToWriteASongLyricsPage from './pages/LandingPages/HowToWriteASongLyrics';
import AiLyricGeneratorPage from './pages/LandingPages/AiLyricGenerator';
import AiLyricsPage from './pages/LandingPages/AiLyrics';
import AiLyricsGeneratorPage from './pages/LandingPages/AiLyricsGenerator';
import RapBeatsPage from './pages/LandingPages/RapBeats';
import RapBeatsGermanPage from './pages/LandingPages/RapBeatsGerman';
import BeatMakerPage from './pages/LandingPages/BeatMaker';
import RapGeneratorPage from './pages/LandingPages/RapGenerator';
import RapGeneratorGermanPage from './pages/LandingPages/RapGeneratorGerman';
import AiLyricGeneratorGermanPage from './pages/LandingPages/AiLyricGeneratorGerman';

import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
import Homescreen from './pages/Homescreen/Homescreen';
import PageNotFound from './components/PageNotFound';
import Signin from './pages/Signin';
import Register from './pages/Register';
import LandingPage from './pages/LandingPage';
import useLocalStorage from './hooks/useLocalStorage';
import { checkUserToken, getIpAddress } from './api';
import Subscribe from './components/Stripe/Subscribe';
import Account from './components/Stripe/Account';
import Cancel from './components/Stripe/Cancel';
import PricingTable from './pages/PricingTable';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeIntermediate from './components/Stripe/StripeIntermediate';
import VerifyEmail from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordEmail from './pages/ForgotPasswordEmail';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Alert from '@mui/material/Alert';
import Landings from './pages/LandingPages/Landings';
import HowToWriteASong from './pages/LandingPages/HowToWriteASong';
import Partners from './pages/LandingPages/Partners';
import LyricGenerator from './pages/LandingPages/LyricGenerator';
import RapLyricGenerator from './pages/LandingPages/RapLyricGenerator';
import RapLyricGeneratorGerman from './pages/LandingPages/RapLyricGeneratorGerman';
import StripePricingTable from './pages/StripePricingTable';
import MusicPlayer from './pages/MusicPlayer/MusicPlayer';
import MainApp from './pages/MainApp/MainApp';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';



function App() {

  const [savedEmail, setSavedEmail] = useLocalStorage('email', null);
  const [user, setUser] = useLocalStorage('user', null);
  const [ipAddress, setIpAddress] = useLocalStorage('ipAddress', null);
  const [data, setData] = useState(0);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const tawkMessengerRef = useRef();
  //const stripePublishableKey = 'pk_test_51MDLQfDsTL5MPVicnM3b4ukcprZmGyAVw8lzyl01a7q4FheWTtypiYFiq7CAZnI3vwhKfosdi4VhHUkrh4aGqOeC00ivuUOQaN';
  const stripePublishableKey = 'pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK';

  const stripePromise = loadStripe(stripePublishableKey);
  useEffect(() => {
    if (!user || user === 'undefined' || !JSON.stringify(user)){
      setUser(null);
    }
    if (user) {
      checkUserToken(user.token).then(result => {
        if (result) {
          setUser(user);
        } else {
          setUser(null);
          localStorage.removeItem('user');
        }
      });
    }
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function generateString(length) {
        let result = ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
    if (!ipAddress) {
      const getIp = async () => {
        let ip;
        ip = await getIpAddress();
        if (!ip || ip === 'undefined' || ip === 'null' || ip === null || ip === undefined) {
          ip = generateString(15);
        }
        setIpAddress(ip);
      }
    try{
      getIp();
    }
    catch (error) {
      let ip = generateString(15);
      setIpAddress(ip);
    }
  }
  }, []);

  const onBeforeLoad = () => {
    try{
      tawkMessengerRef.current.minimize();
    } catch (error) {
    }
  }

  return (
    <div className="App">
      <Router>
      <Navbar savedEmail={savedEmail} setSavedEmail={setSavedEmail} setUser={setUser} user={user}/>
      <Sidebar user={user}/>
      {isAlert && <>{ isMobile ? <Alert style={{paddingTop: 60}} severity={alertSeverity} onClose={() => setIsAlert(false)}>{alertMessage}</Alert> : <Alert style={{paddingTop: 60, marginLeft: 70}} severity={alertSeverity} onClose={() => setIsAlert(false)}>{alertMessage}</Alert>} </>}
        <Routes>
          <Route exact path='/' element={<LandingPage user={user} setUser={setUser} ipAddress={ipAddress}/>}/>
          <Route exact path='/app' element={<Homescreen user={user} setUser={setUser} ipAddress={ipAddress} setIsAlert={setIsAlert} setAlertMessage={setAlertMessage} setAlertSeverity={setAlertSeverity}/>}/>
          <Route exact path='/signin' element={<Signin savedEmail={savedEmail} setSavedEmail={setSavedEmail} user={user} setUser={setUser}/>}/>
          <Route exact path='/register' element={<Register user={user} setUser={setUser}/>}/>
          <Route exact path='/prices' element={<StripePricingTable setSubscriptionData={setSubscriptionData} subscriptionActive={subscriptionActive} setSubscriptionActive={setSubscriptionActive} user={user} setUser={setUser}/>}/>
          <Route exact path='/preloginprices' element={<PricingTable setSubscriptionData={setSubscriptionData} subscriptionActive={subscriptionActive} setSubscriptionActive={setSubscriptionActive} user={user} setUser={setUser}/>}/>
          <Route exact path='/musicplayer' element={<MusicPlayer user={user} setUser={setUser} ipAddress={ipAddress} setIsAlert={setIsAlert} setAlertMessage={setAlertMessage} setAlertSeverity={setAlertSeverity}/>}/>
          <Route exact path='/mainapp' element={<MainApp/>}/>
          <Route exact path='/processing' element={<StripeIntermediate user={user} setUser={setUser} subscriptionData={subscriptionData} setSubscriptionActive={setSubscriptionActive}/>}/>
          <Route exact path='/subscribe' element={
            <Elements stripe={stripePromise} options={{"clientSecret": subscriptionData ? subscriptionData.clientSecret : null}}>
              <Subscribe user={user} setUser={setUser} subscriptionData={subscriptionData} setSubscriptionActive={setSubscriptionActive} subscriptionActive={subscriptionActive}/>
            </Elements>
          }/>
          <Route exact path='/account' element={<Account setUser={setUser} user={user} token={user ? user.token : null}/>}/>
          <Route exact path='/cancel' element={<Cancel token={user ? user.token : null}/>}/>
          <Route exact path='/verify-email' element={<VerifyEmail setUser={setUser} user={user} />}/>
          <Route exact path='/forgot-password' element={<ForgotPassword setUser={setUser} user={user}/>}/>
          <Route exact path='/forgot-password-email' element={<ForgotPasswordEmail setUser={setUser} user={user}/>}/>
          <Route exact path='/landings' element={<Landings/>} />
          <Route exact path='/partners' element={<Partners/>} />
          <Route exact path='privacy' element={<PrivacyPolicy/>}/>
          <Route exact path='/terms' element={<TermsAndConditions/>}/>
          <Route path='/404' element={<PageNotFound setUser={setUser} user={user}/>}/>
          <Route exact path='/how-to-write-a-song' element={<HowToWriteASong />} />
          <Route exact path='/lyric-generator' element={<LyricGenerator />} />
          <Route exact path="/rap-generator" element={<RapGeneratorPage />} />
          <Route exact path="/beat-maker" element={<BeatMakerPage />} />
          <Route exact path="/rap-beats" element={<RapBeatsPage />} />
          <Route exact path="/ai-lyrics-generator" element={<AiLyricsGeneratorPage />} />
          <Route exact path="/ai-lyrics" element={<AiLyricsPage />} />
          <Route exact path="/ai-lyric-generator" element={<AiLyricGeneratorPage />} />
          <Route exact path="/how-to-write-a-song-lyrics" element={<HowToWriteASongLyricsPage />} />
          <Route exact path="/how-to-write-lyrics" element={<HowToWriteLyricsPage />} />
          <Route exact path="/ai-generator" element={<AiGeneratorPage />} />
          <Route exact path="/ai-music-generator" element={<AiMusicGeneratorPage />} />
          <Route exact path="/how-to-write-music" element={<HowToWriteMusicPage />} />
          <Route exact path="/writing-music" element={<WritingMusicPage />} />
          <Route exact path="/writing-lyrics" element={<WritingLyricsPage />} />
          <Route exact path="/writing-songs" element={<WritingSongsPage />} />
          <Route exact path="/songs" element={<SongsPage />} />
          <Route exact path="/chrome-music-lab" element={<ChromeMusicLabPage />} />
          <Route exact path="/best-ai-music-generator" element={<BestAiMusicGeneratorPage />} />
          <Route exact path="/song-lyric-generator" element={<SongLyricGeneratorPage />} />
          <Route exact path="/lyric-generator-free" element={<LyricGeneratorFreePage />} />
          <Route exact path="/how-to-make-music" element={<HowToMakeMusicPage />} />
          <Route exact path="/music-and-lyrics" element={<MusicAndLyricsPage />} />
          <Route exact path="/song-lyrics-generator" element={<SongLyricsGeneratorPage />} />
          <Route exact path="/lyrics-generator" element={<LyricsGeneratorPage />} />
          <Route exact path="/rap-lyrics-generator" element={<RapLyricsGeneratorPage />} />
          <Route exact path="/ai-song-lyrics-generator" element={<AiSongLyricsGeneratorPage />} />
          <Route exact path="/freestyle-lyrics-generator" element={<FreestyleLyricsGeneratorPage />} />
          <Route exact path="/ai-lyrics-generator-free" element={<AiLyricsGeneratorFreePage />} />
          <Route exact path="/free-music" element={<FreeMusicPage />} />
          <Route exact path="/relaxing-music" element={<RelaxingMusicPage />} />
          <Route exact path="/mp3-music-download" element={<Mp3MusicDownloadPage />} />
          <Route exact path="/free-music-download" element={<FreeMusicDownloadPage />} />
          <Route exact path="/royalty-free-music" element={<RoyaltyFreeMusicPage />} />
          <Route exact path="/copyright-free-music" element={<CopyrightFreeMusicPage />} />
          <Route exact path="/music-maker" element={<MusicMakerPage />} />
          <Route exact path="/music-lab" element={<MusicLabPage />} />
          <Route exact path="/ai-music-generator-free" element={<AiMusicGeneratorFreePage />} />
          <Route exact path="/ai-music-generator-from-text" element={<AiMusicGeneratorFromTextPage />} />
          <Route exact path="/random-music-generator" element={<RandomMusicGeneratorPage />} />
          <Route exact path="/pop-lyrics-generator" element={<PopLyricsGeneratorPage />} />
          <Route exact path="/ai-song-generator" element={<AiSongGeneratorPage />} />
          <Route exact path="/song-generator" element={<SongGeneratorPage />} />
          <Route exact path="/random-song-generator" element={<RandomSongGeneratorPage />} />
          <Route exact path="/rap-song-generator" element={<RapSongGeneratorPage />} />
          <Route exact path="/random-song-generator-by-genre" element={<RandomSongGeneratorByGenrePage />} />
          <Route exact path="/ai-song-generator-free" element={<AiSongGeneratorFreePage />} />
          <Route exact path="/ai-cover-song-generator" element={<AiCoverSongGeneratorPage />} />
          <Route exact path="/text-to-song-generator" element={<TextToSongGeneratorPage />} />
          <Route exact path="/song-maker" element={<SongMakerPage />} />
          <Route exact path="/chrome-music-lab-song-maker" element={<ChromeMusicLabSongMakerPage />} />
          <Route exact path="/ai-song-maker" element={<AiSongMakerPage />} />
          <Route exact path="/online-song-maker" element={<OnlineSongMakerPage />} />
          <Route exact path="/music-lab-song-maker" element={<MusicLabSongMakerPage />} />
          <Route exact path="/song-maker-lyrics" element={<SongMakerLyricsPage />} />
          <Route exact path="/techno-music" element={<TechnoMusicPage />} />
          <Route exact path="/electronic-music-generator" element={<ElectronicMusicGeneratorPage />} />
          <Route exact path="/ai-rap-generator" element={<AiRapGeneratorPage />} />
          <Route exact path="/freestyle-rap-generator" element={<FreestyleRapGeneratorPage />} />
          <Route exact path="/text-to-rap-generator" element={<TextToRapGeneratorPage />} />
          <Route exact path="/rap-maker" element={<RapMakerPage />} />
          <Route exact path="/rap-maker-online" element={<RapMakerOnlinePage />} />
          <Route exact path="/ai-rap-maker" element={<AiRapMakerPage />} />
          <Route exact path="/rap-creator" element={<RapCreatorPage />} />
          <Route exact path="/rap-writer" element={<RapWriterPage />} />
          <Route exact path="/free-beat-maker" element={<FreeBeatMakerPage />} />
          <Route exact path="/beat-maker-online" element={<BeatMakerOnlinePage />} />
          <Route exact path="/online-beat-maker" element={<OnlineBeatMakerPage />} />
          <Route exact path="/beat-maker-free" element={<BeatMakerFreePage />} />
          <Route exact path="/free-online-beat-maker" element={<FreeOnlineBeatMakerPage />} />
          <Route exact path="/online-beat-maker-free" element={<OnlineBeatMakerFreePage />} />
          <Route exact path="/beat-maker-online-free" element={<BeatMakerOnlineFreePage />} />
          <Route exact path="/beat-generator" element={<BeatGeneratorPage />} />
          <Route exact path="/ai-beat-generator" element={<AiBeatGeneratorPage />} />
          <Route exact path="/random-beat-generator" element={<RandomBeatGeneratorPage />} />
          <Route exact path="/beat-creator" element={<BeatCreatorPage />} />
          <Route exact path="/beat-making" element={<BeatMakingPage />} />
          <Route exact path="/free-beat-making-software" element={<FreeBeatMakingSoftwarePage />} />
          <Route exact path="/beat-making-software" element={<BeatMakingSoftwarePage />} />
          <Route exact path="/best-beat-making-software" element={<BestBeatMakingSoftwarePage />} />
          <Route exact path="/best-free-beat-making-software" element={<BestFreeBeatMakingSoftwarePage />} />
          <Route exact path="/rap-lyrics" element={<RapLyricsPage />} />
          <Route exact path="/rap-music" element={<RapMusicPage />} />
          <Route exact path="/how-to-rap" element={<HowToRapPage />} />
          <Route exact path="/rap-beat" element={<RapBeatPage />} />
          <Route exact path="/how-to-rap-better" element={<HowToRapBetterPage />} />
          <Route exact path="/how-to-rap-fast" element={<HowToRapFastPage />} />
          <Route exact path="/how-to-rap-freestyle" element={<HowToRapFreestylePage />} />
          <Route exact path="/how-to-rap-lyrics" element={<HowToRapLyricsPage />} />
          <Route exact path="/free-rap-beats" element={<FreeRapBeatsPage />} />
          <Route exact path="/rap-beats-free" element={<RapBeatsFreePage />} />
          <Route exact path="/make-rap-beats" element={<MakeRapBeatsPage />} />
          <Route exact path="/freestyle-rap-beats" element={<FreestyleRapBeatsPage />} />
          <Route exact path="/best-rap-beats" element={<BestRapBeatsPage />} />
          <Route exact path="/rap-beat-maker" element={<RapBeatMakerPage />} />
          <Route exact path="/freestyle-rap-beat" element={<FreestyleRapBeatPage />} />
          <Route exact path="/how-to-make-a-rap-beat" element={<HowToMakeARapBeatPage />} />
          <Route exact path="/song-lyrics" element={<SongLyricsPage />} />
          <Route exact path="/how-to-make-edm-music" element={<HowToMakeEdmMusicPage />} />
          <Route exact path="/how-to-make-techno-music" element={<HowToMakeTechnoMusicPage />} />
          <Route exact path="/songwriting" element={<SongwritingPage />} />
          <Route exact path="/songwriting-de" element={<SongwritingGermanPage />} />
          <Route exact path="/songwriting-tips" element={<SongwritingTipsPage />} />
          <Route exact path="/songwriting-prompts" element={<SongwritingPromptsPage />} />
          <Route exact path="/song-writing-generator" element={<SongWritingGeneratorPage />} />
          <Route exact path="/song-writing-structure" element={<SongWritingStructurePage />} />
          <Route exact path="/song-writing-website" element={<SongWritingWebsitePage />} />
          <Route exact path="/song-writing-ideas" element={<SongWritingIdeasPage />} />
          <Route exact path="/song-writing-template" element={<SongWritingTemplatePage />} />
          <Route exact path="/song-writing-app" element={<SongWritingAppPage />} />
          <Route exact path="/ai-songwriter" element={<AiSongWriterPage />} />
          <Route exact path="/singer-songwriter" element={<SingerSongwriterPage />} />
          <Route exact path="/american-songwriter" element={<AmericanSongwriterPage />} />
          <Route exact path="/songwriter-ai" element={<SongwriterAiPage />} />
          <Route exact path="/how-to-become-a-songwriter" element={<HowToBecomeASongwriterPage />} />
          <Route exact path="/how-to-be-a-songwriter" element={<HowToBeASongwriterPage />} />
          <Route exact path="/what-is-a-songwriter" element={<WhatIsASongwriterPage />} />
          <Route exact path="/what-is-songwriting" element={<WhatIsSongwritingPage />} />
          <Route exact path="/how-to-get-into-songwriting" element={<HowToGetIntoSongwritingPage />} />
          <Route exact path="/how-to-learn-songwriting" element={<HowToLearnSongwritingPage />} />
          <Route exact path="/how-to-start-writing-a-song" element={<HowToStartWritingASongPage />} />
          <Route exact path="/how-to-start-writing-song-lyrics" element={<HowToStartWritingSongLyricsPage />} />
          <Route exact path="/how-to-begin-writing-a-song" element={<HowToBeginWritingASongPage />} />
          <Route exact path="/where-to-start-when-writing-a-song" element={<WhereToStartWhenWritingASongPage />} />
          <Route exact path="/how-to-write-songs" element={<HowToWriteSongsPage />} />
          <Route exact path="/how-to-write-song-lyrics" element={<HowToWriteSongLyricsPage />} />
          <Route exact path="/how-to-write-song-lyrics-without-music" element={<HowToWriteSongLyricsWithoutMusicPage />} />
          <Route exact path="/how-to-write-a-rap-song" element={<HowToWriteARapSongPage />} />
          <Route exact path="/how-to-write-a-love-song" element={<HowToWriteALoveSongPage />} />
          <Route exact path="/how-to-write-a-song-for-beginners" element={<HowToWriteASongForBeginnersPage />} />
          <Route exact path="/how-do-you-write-a-song" element={<HowDoYouWriteASongPage />} />
          <Route exact path="/song-writer-generator" element={<SongWriterGeneratorPage />} />
          <Route exact path="/song-writer-helper" element={<SongWriterHelperPage />} />
          <Route exact path="/song-writer-for-hire" element={<SongWriterForHirePage />} />
          <Route exact path="/song-writer" element={<Songwriter />} />
          <Route exact path="/song-writer-de" element={<SongwriterGerman />} />
          <Route exact path="/lyric-maker" element={<LyricMakerPage />} />
          <Route exact path="/rap-lyric-maker" element={<RapLyricMakerPage />} />
          <Route exact path="/ai-lyric-maker" element={<AiLyricMakerPage />} />
          <Route exact path="/lyric-maker-ai" element={<LyricMakerAiPage />} />
          <Route exact path="/genius-lyric-maker" element={<GeniusLyricMakerPage />} />
          <Route exact path="/rap-lyrics-maker" element={<RapLyricsMakerPage />} />
          <Route exact path="/make-music-online-free" element={<MakeMusicOnlineFreePage />} />
          <Route exact path="/make-music-cloud" element={<MakeMusicCloudPage />} />
          <Route exact path="/free-songwriting-app" element={<FreeSongwritingAppPage />} />
          <Route exact path="/best-songwriting-app" element={<BestSongwritingAppPage />} />
          <Route exact path="/songwriting-app-free" element={<SongwritingAppFreePage />} />
          <Route exact path="/ai-songwriting-app" element={<AiSongwritingAppPage />} />
          <Route exact path="/chorus-songwriting-app" element={<ChorusSongwritingAppPage />} />
          <Route exact path="/songwriting-app-online-free" element={<SongwritingAppOnlineFreePage />} />
          <Route exact path="/songwriting-app-iphone" element={<SongwritingAppIphonePage />} />
          <Route exact path="/songwriting-app-android" element={<SongwritingAppAndroidPage />} />
          <Route exact path="/compose-ai" element={<ComposeAiPage />} />
          <Route exact path="/how-to-compose-music" element={<HowToComposeMusicPage />} />
          <Route exact path="/how-to-compose-music-on-computer" element={<HowToComposeMusicOnComputerPage />} />
          <Route exact path="/générateur-de-paroles-ai" element={<GénérateurDeParolesAiPage />} />
          <Route exact path="/paroles-d-ai" element={<ParolesDAiPage />} />
          <Route exact path="/comment-écrire-une-chanson" element={<CommentÉcrireUneChansonPage />} />
          <Route exact path="/comment-écrire-les-paroles-d-une-chanson" element={<CommentÉcrireLesParolesDUneChansonPage />} />
          <Route exact path="/auteur-compositeur-interprète" element={<AuteurCompositeurInterprètePage />} />
          <Route exact path="/comment-écrire-des-paroles" element={<CommentÉcrireDesParolesPage />} />
          <Route exact path="/générateur-d-ia" element={<GénérateurDIaPage />} />
          <Route exact path="/générateur-de-musique-ai" element={<GénérateurDeMusiqueAiPage />} />
          <Route exact path="/générateur-de-paroles-de-rap" element={<GénérateurDeParolesDeRapPage />} />
          <Route exact path="/comment-écrire-de-la-musique" element={<CommentÉcrireDeLaMusiquePage />} />
          <Route exact path="/écrire-des-chansons" element={<ÉcrireDesChansonsPage />} />
          <Route exact path="/laboratoire-de-musique-chromé" element={<LaboratoireDeMusiqueChroméPage />} />
          <Route exact path="/meilleur-générateur-de-musique-ia" element={<MeilleurGénérateurDeMusiqueIaPage />} />
          <Route exact path="/générateur-de-paroles-de-chansons" element={<GénérateurDeParolesDeChansonsPage />} />
          <Route exact path="/générateur-de-paroles-gratuit" element={<GénérateurDeParolesGratuitPage />} />
          <Route exact path="/comment-faire-de-la-musique" element={<CommentFaireDeLaMusiquePage />} />
          <Route exact path="/musique-et-paroles" element={<MusiqueEtParolesPage />} />
          <Route exact path="/générateur-de-paroles-de-chansons-ai" element={<GénérateurDeParolesDeChansonsAiPage />} />
          <Route exact path="/générateur-de-paroles-freestyle" element={<GénérateurDeParolesFreestylePage />} />
          <Route exact path="/générateur-de-paroles-ai-gratuit" element={<GénérateurDeParolesAiGratuitPage />} />
          <Route exact path="/musique-gratuite" element={<MusiqueGratuitePage />} />
          <Route exact path="/musique-relaxante" element={<MusiqueRelaxantePage />} />
          <Route exact path="/téléchargement-de-musique-mp3" element={<TéléchargementDeMusiqueMp3Page />} />
          <Route exact path="/téléchargement-de-musique-gratuit" element={<TéléchargementDeMusiqueGratuitPage />} />
          <Route exact path="/musique-libre-de-droits" element={<MusiqueLibreDeDroitsPage />} />
          <Route exact path="/créateur-de-musique" element={<CréateurDeMusiquePage />} />
          <Route exact path="/générateur-de-musique-ai-gratuit" element={<GénérateurDeMusiqueAiGratuitPage />} />
          <Route exact path="/générateur-de-musique-ai-à-partir-de-texte" element={<GénérateurDeMusiqueAiÀPartirDeTextePage />} />
          <Route exact path="/meilleure-musique-ia" element={<MeilleureMusiqueIaPage />} />
          <Route exact path="/générateur-de-musique-aléatoire" element={<GénérateurDeMusiqueAléatoirePage />} />
          <Route exact path="/générateur-de-paroles-pop" element={<GénérateurDeParolesPopPage />} />
          <Route exact path="/générateur-de-chansons-ai" element={<GénérateurDeChansonsAiPage />} />
          <Route exact path="/générateur-de-chansons-aléatoires" element={<GénérateurDeChansonsAléatoiresPage />} />
          <Route exact path="/générateur-de-chansons-rap" element={<GénérateurDeChansonsRapPage />} />
          <Route exact path="/générateur-de-chansons-aléatoires-par-genre" element={<GénérateurDeChansonsAléatoiresParGenrePage />} />
          <Route exact path="/générateur-de-chansons-ai-gratuit" element={<GénérateurDeChansonsAiGratuitPage />} />
          <Route exact path="/générateur-de-chanson-de-reprise-ai" element={<GénérateurDeChansonDeRepriseAiPage />} />
          <Route exact path="/générateur-de-texte-en-chanson" element={<GénérateurDeTexteEnChansonPage />} />
          <Route exact path="/créateur-de-chansons" element={<CréateurDeChansonsPage />} />
          <Route exact path="/créateur-de-chansons-de-laboratoire-de-musique-chromé" element={<CréateurDeChansonsDeLaboratoireDeMusiqueChroméPage />} />
          <Route exact path="/créateur-de-chansons-ai" element={<CréateurDeChansonsAiPage />} />
          <Route exact path="/créateur-de-chansons-en-ligne" element={<CréateurDeChansonsEnLignePage />} />
          <Route exact path="/paroles-du-créateur-de-chansons" element={<ParolesDuCréateurDeChansonsPage />} />
          <Route exact path="/musique-techno" element={<MusiqueTechnoPage />} />
          <Route exact path="/générateur-de-musique-électronique" element={<GénérateurDeMusiqueÉlectroniquePage />} />
          <Route exact path="/générateur-de-rap" element={<GénérateurDeRapPage />} />
          <Route exact path="/générateur-de-rap-ai" element={<GénérateurDeRapAiPage />} />
          <Route exact path="/générateur-de-rap-freestyle" element={<GénérateurDeRapFreestylePage />} />
          <Route exact path="/générateur-de-texte-en-rap" element={<GénérateurDeTexteEnRapPage />} />
          <Route exact path="/ai-textgenerator" element={<AiTextgeneratorPage />} />
          <Route exact path="/ai-texte" element={<AiTextePage />} />
          <Route exact path="/wie-man-ein-lied-schreibt" element={<WieManEinLiedSchreibtPage />} />
          <Route exact path="/wie-man-einen-liedtext-schreibt" element={<WieManEinenLiedtextSchreibtPage />} />
          <Route exact path="/ki-songwriter" element={<KiSongwriterPage />} />
          <Route exact path="/wie-man-liedtexte-schreibt" element={<WieManLiedtexteSchreibtPage />} />
          <Route exact path="/lyrikgenerator" element={<LyrikgeneratorPage />} />
          <Route exact path="/ki-generator" element={<KiGeneratorPage />} />
          <Route exact path="/ki-musikgenerator" element={<KiMusikgeneratorPage />} />
          <Route exact path="/wie-man-musik-schreibt" element={<WieManMusikSchreibtPage />} />
          <Route exact path="/musik-schreiben" element={<MusikSchreibenPage />} />
          <Route exact path="/songtexte-schreiben" element={<SongtexteSchreibenPage />} />
          <Route exact path="/lieder-schreiben" element={<LiederSchreibenPage />} />
          <Route exact path="/chrom-musiklabor" element={<ChromMusiklaborPage />} />
          <Route exact path="/bester-ki-musikgenerator" element={<BesterKiMusikgeneratorPage />} />
          <Route exact path="/songtext-generator" element={<SongtextGeneratorPage />} />
          <Route exact path="/textgenerator-kostenlos" element={<TextgeneratorKostenlosPage />} />
          <Route exact path="/wie-man-musik-macht" element={<WieManMusikMachtPage />} />
          <Route exact path="/musik-und-texte" element={<MusikUndTextePage />} />
          <Route exact path="/liedtextgenerator" element={<LiedtextgeneratorPage />} />
          <Route exact path="/rap-textgenerator" element={<RapTextgeneratorPage />} />
          <Route exact path="/ai-songtext-generator" element={<AiSongtextGeneratorPage />} />
          <Route exact path="/freestyle-textgenerator" element={<FreestyleTextgeneratorPage />} />
          <Route exact path="/ai-textgenerator-kostenlos" element={<AiTextgeneratorKostenlosPage />} />
          <Route exact path="/kostenlose-musik" element={<KostenloseMusikPage />} />
          <Route exact path="/entspannende-musik" element={<EntspannendeMusikPage />} />
          <Route exact path="/mp3-musik-herunterladen" element={<Mp3MusikHerunterladenPage />} />
          <Route exact path="/kostenloser-musik-download" element={<KostenloserMusikDownloadPage />} />
          <Route exact path="/gemafreie-musik" element={<GemafreieMusikPage />} />
          <Route exact path="/urheberrechtsfreie-musik" element={<UrheberrechtsfreieMusikPage />} />
          <Route exact path="/musikmacher" element={<MusikmacherPage />} />
          <Route exact path="/ai-musikgenerator-kostenlos" element={<AiMusikgeneratorKostenlosPage />} />
          <Route exact path="/ai-musikgenerator-aus-text" element={<AiMusikgeneratorAusTextPage />} />
          <Route exact path="/beste-ki-musik" element={<BesteKiMusikPage />} />
          <Route exact path="/zufallsgenerator-für-musik" element={<ZufallsgeneratorFürMusikPage />} />
          <Route exact path="/pop-textgenerator" element={<PopTextgeneratorPage />} />
          <Route exact path="/ai-songgenerator-de" element={<AiSonggeneratorGermanPage />} />
          <Route exact path="/zufallsgenerator-für-lieder" element={<ZufallsgeneratorFürLiederPage />} />
          <Route exact path="/zufallsgenerator-für-lieder-nach-genre" element={<ZufallsgeneratorFürLiederNachGenrePage />} />
          <Route exact path="/ai-song-generator-kostenlos" element={<AiSongGeneratorKostenlosPage />} />
          <Route exact path="/ai-cover-song-generator" element={<AiCoverSongGeneratorPage />} />
          <Route exact path="/text-zu-song-generator" element={<TextZuSongGeneratorPage />} />
          <Route exact path="/liedermacher" element={<LiedermacherPage />} />
          <Route exact path="/ki-songmacher" element={<KiSongmacherPage />} />
          <Route exact path="/online-songmacher" element={<OnlineSongmacherPage />} />
          <Route exact path="/musiklabor-songmacher" element={<MusiklaborSongmacherPage />} />
          <Route exact path="/liedtexte-des-songwriters" element={<LiedtexteDesSongwritersPage />} />
          <Route exact path="/technomusik" element={<TechnomusikPage />} />
          <Route exact path="/generator-für-elektronische-musik" element={<GeneratorFürElektronischeMusikPage />} />
          <Route exact path="/text-zu-rap-generator" element={<TextZuRapGeneratorPage />} />
          <Route exact path="/rap-macher" element={<RapMacherPage />} />
          <Route exact path="/rap-hersteller-online" element={<RapHerstellerOnlinePage />} />
          <Route exact path="/ki-rap-hersteller" element={<KiRapHerstellerPage />} />
          <Route exact path="/rap-schöpfer" element={<RapSchöpferPage />} />
          <Route exact path="/rap-autor" element={<RapAutorPage />} />
          <Route exact path="/beatmaker-de" element={<BeatmakerGermanPage />} />
          <Route exact path="/kostenloser-beat-maker" element={<KostenloserBeatMakerPage />} />
          <Route exact path="/beat-maker-kostenlos" element={<BeatMakerKostenlosPage />} />
          <Route exact path="/kostenloser-online-beat-maker" element={<KostenloserOnlineBeatMakerPage />} />
          <Route exact path="/beat-maker-online-kostenlos" element={<BeatMakerOnlineKostenlosPage />} />
          <Route exact path="/zufallsgenerator-für-beats" element={<ZufallsgeneratorFürBeatsPage />} />
          <Route exact path="/beat-schöpfer" element={<BeatSchöpferPage />} />
          <Route exact path="/beatmachen" element={<BeatmachenPage />} />
          <Route exact path="/kostenlose-beat-making-software" element={<KostenloseBeatMakingSoftwarePage />} />
          <Route exact path="/beste-beat-making-software" element={<BesteBeatMakingSoftwarePage />} />
          <Route exact path="/beste-kostenlose-beat-making-software" element={<BesteKostenloseBeatMakingSoftwarePage />} />
          <Route exact path="/rap-texte" element={<RapTextePage />} />
          <Route exact path="/rap-musik" element={<RapMusikPage />} />
          <Route exact path="/wie-man-rappt" element={<WieManRapptPage />} />
          <Route exact path="/wie-man-besser-rappt" element={<WieManBesserRapptPage />} />
          <Route exact path="/wie-man-schnell-rappt" element={<WieManSchnellRapptPage />} />
          <Route exact path="/wie-man-freestyle-rappt" element={<WieManFreestyleRapptPage />} />
          <Route exact path="/wie-man-texte-rappt" element={<WieManTexteRapptPage />} />
          <Route exact path="/kostenlose-rap-beats" element={<KostenloseRapBeatsPage />} />
          <Route exact path="/rap-schlägt-kostenlos" element={<RapSchlägtKostenlosPage />} />
          <Route exact path="/rap-beats-machen" element={<RapBeatsMachenPage />} />
          <Route exact path="/beste-rap-beats" element={<BesteRapBeatsPage />} />
          <Route exact path="/wie-man-einen-rap-beat-macht" element={<WieManEinenRapBeatMachtPage />} />
          <Route exact path="/wie-man-edm-musik-macht" element={<WieManEdmMusikMachtPage />} />
          <Route exact path="/wie-man-technomusik-macht" element={<WieManTechnomusikMachtPage />} />
          <Route exact path="/songwriting-tipps" element={<SongwritingTippsPage />} />
          <Route exact path="/anregungen-zum-songwriting" element={<AnregungenZumSongwritingPage />} />
          <Route exact path="/songwriting-ki" element={<SongwritingKiPage />} />
          <Route exact path="/songwriting-generator-de" element={<SongwritingGeneratorGermanPage />} />
          <Route exact path="/songwriting-struktur" element={<SongwritingStrukturPage />} />
          <Route exact path="/tipps-zum-songwriting" element={<TippsZumSongwritingPage />} />
          <Route exact path="/songwriting-website-de" element={<SongwritingWebsiteGermanPage />} />
          <Route exact path="/songwriting-ideen" element={<SongwritingIdeenPage />} />
          <Route exact path="/songwriting-vorlage" element={<SongwritingVorlagePage />} />
          <Route exact path="/amerikanischer-songwriter" element={<AmerikanischerSongwriterPage />} />
          <Route exact path="/wie-man-songwriter-wird" element={<WieManSongwriterWirdPage />} />
          <Route exact path="/wie-man-ein-songwriter-wird" element={<WieManEinSongwriterWirdPage />} />
          <Route exact path="/was-ist-ein-songwriter?" element={<WasIstEinSongwriterPage />} />
          <Route exact path="/wie-man-mit-dem-songwriting-beginnt" element={<WieManMitDemSongwritingBeginntPage />} />
          <Route exact path="/was-ist-songwriting?" element={<WasIstSongwritingPage />} />
          <Route exact path="/wie-man-zum-songwriting-kommt" element={<WieManZumSongwritingKommtPage />} />
          <Route exact path="/wie-man-songwriting-lernt" element={<WieManSongwritingLerntPage />} />
          <Route exact path="/wie-man-anfängt,-ein-lied-zu-schreiben" element={<WieManAnfängtEinLiedZuSchreibenPage />} />
          <Route exact path="/wie-man-anfängt,-liedtexte-zu-schreiben" element={<WieManAnfängtLiedtexteZuSchreibenPage />} />
          <Route exact path="/wo-fange-ich-an,-wenn-ich-einen-song-schreibe?" element={<WoFangeIchAnWennIchEinenSongSchreibePage />} />
          <Route exact path="/wie-man-lieder-schreibt" element={<WieManLiederSchreibtPage />} />
          <Route exact path="/wie-schreibe-ich-songtexte-ohne-musik?" element={<WieSchreibeIchSongtexteOhneMusikPage />} />
          <Route exact path="/wie-man-einen-rap-song-schreibt" element={<WieManEinenRapSongSchreibtPage />} />
          <Route exact path="/wie-man-ein-liebeslied-schreibt" element={<WieManEinLiebesliedSchreibtPage />} />
          <Route exact path="/wie-man-ein-lied-für-anfänger-schreibt" element={<WieManEinLiedFürAnfängerSchreibtPage />} />
          <Route exact path="/wie-schreibt-man-ein-lied?" element={<WieSchreibtManEinLiedPage />} />
          <Route exact path="/song-writer-generator-de" element={<SongwriterGeneratorGermanPage />} />
          <Route exact path="/songwriter-helfer" element={<SongwriterHelferPage />} />
          <Route exact path="/songwriter-zu-mieten" element={<SongwriterZuMietenPage />} />
          <Route exact path="/lyriker" element={<LyrikerPage />} />
          <Route exact path="/rap-lyriker" element={<RapLyrikerPage />} />
          <Route exact path="/ai-lyriker" element={<AiLyrikerPage />} />
          <Route exact path="/textersteller-ai" element={<TexterstellerAiPage />} />
          <Route exact path="/genialer-lyriker" element={<GenialerLyrikerPage />} />
          <Route exact path="/rap-textersteller" element={<RapTexterstellerPage />} />
          <Route exact path='/ai-beat-generator-de' element={<AiBeatGeneratorGermanPage/>} />
          <Route exact path="/ai-coversong-generator-de" element={<AiCoversongGeneratorGermanPage />} />
          <Route exact path="/ai-lyric-generator-de" element={<AiLyricGeneratorGermanPage />} />
          <Route exact path="/ai-rap-generator-de" element={<AiRapGeneratorGermanPage />} />
          <Route exact path="/ai-songwriter-de" element={<AiSongwriterGermanPage />} />
          <Route exact path="/beat-maker-online-de" element={<BeatMakerOnlineGermanPage />} />
          <Route exact path="/beat-making-software-de" element={<BeatMakingSoftwareGermanPage />} />
          <Route exact path="/chrome-music-lab-song-maker-de" element={<ChromeMusicLabSongMakerGermanPage />} />
          <Route exact path="/freestyle-rap-beat-de" element={<FreestyleRapBeatGermanPage />} />
          <Route exact path="/freestyle-rap-beats-de" element={<FreestyleRapBeatsGermanPage />} />
          <Route exact path="/freestyle-rap-generator-de" element={<FreestyleRapGeneratorGermanPage />} />
          <Route exact path="/online-beat-maker-de" element={<OnlineBeatMakerGermanPage />} />
          <Route exact path="/rap-beat-de" element={<RapBeatGermanPage />} />
          <Route exact path="/rap-beat-maker-de" element={<RapBeatMakerGermanPage />} />
          <Route exact path="/rap-beats-de" element={<RapBeatsGermanPage />} />
          <Route exact path="/rap-generator-de" element={<RapGeneratorGermanPage />} />
          <Route exact path='/rap-lyric-generator-de' element={<RapLyricGeneratorGerman/>} />
          <Route exact path="/rap-song-generator-de" element={<RapSongGeneratorGermanPage />} />
          <Route exact path="/singer-songwriter-de" element={<SingerSongwriterGermanPage />} />
          <Route exact path="/song-generator-de" element={<SongGeneratorGermanPage />} />
          <Route exact path="/ai歌詞ジェネレーター-ja" element={<Ai歌詞ジェネレーターJaPage />} />
          <Route exact path="/あい歌詞-ja" element={<あい歌詞JaPage />} />
          <Route exact path="/曲の書き方-ja" element={<曲の書き方JaPage />} />
          <Route exact path="/歌の歌詞の書き方-ja" element={<歌の歌詞の書き方JaPage />} />
          <Route exact path="/アイソングライター-ja" element={<アイソングライターJaPage />} />
          <Route exact path="/aiジェネレーター-ja" element={<AiジェネレーターJaPage />} />
          <Route exact path="/音楽の書き方-ja" element={<音楽の書き方JaPage />} />
          <Route exact path="/音楽を書く-ja" element={<音楽を書くJaPage />} />
          <Route exact path="/歌詞を書く-ja" element={<歌詞を書くJaPage />} />
          <Route exact path="/曲を書く-ja" element={<曲を書くJaPage />} />
          <Route exact path="/歌-ja" element={<歌JaPage />} />
          <Route exact path="/クロムミュージックラボ-ja" element={<クロムミュージックラボJaPage />} />
          <Route exact path="/最高のai音楽ジェネレーター-ja" element={<最高のai音楽ジェネレーターJaPage />} />
          <Route exact path="/歌詞ジェネレーター無料-ja" element={<歌詞ジェネレーター無料JaPage />} />
          <Route exact path="/音楽の作り方-ja" element={<音楽の作り方JaPage />} />
          <Route exact path='/rap-lyric-generator' element={<RapLyricGenerator/>} />

          <Route path='*' element={<Navigate replace to='/404'/>}/>
        </Routes>
      </Router>
      <TawkMessengerReact 
          propertyId="63e832a5c2f1ac1e2032c4c4"
          widgetId="1gp1gbmar"
          onBeforeLoad={onBeforeLoad}
          ref={tawkMessengerRef}      />
    </div>
  );
}

export default App;
