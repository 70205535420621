import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function OnlineSongmacher() {
const title = "Online-Songmacher";
const keywords = "Songtexte erstellen, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, Online-Songmacher";
const description = "Lerne, Songs mit KI zu schreiben";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um dir schneller beim Schreiben von Songtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, angepasst an deine persönlichen Geschmäcker und Songwriting-Bedürfnisse!";
const h1 = "Lerne, Songs mit der Kraft der KI zu schreiben.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendlich viel Inspiration durch unsere KI-betriebenen Texte.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Unendliche Songwriting-Inspiration erwartet dich mit einem nie versiegenden Vorrat an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jeglichen Genres generieren.";
const featureParagraph2 = "Kollaboriere mit unserem KI-Songwriting-Partner, um den perfekten Song zu erschaffen. Sobald du Texte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zu verwirklichen.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten smarten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Beenden deiner Songtexte zu helfen.";
const featureParagraph4 = "Mit mehr als 100+ Sprachen zur Auswahl kannst du lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle der Inspiration zu bieten und einen Schreibblock zu überwinden. Während die KI in der Lage ist, komplette Sets von Songtexten und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu verwenden und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI bietet dir einen unendlichen Strom kreativer und origineller Ideen zur Arbeit.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Menge an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter' Wörterbuch an deiner Seite, um dich während des gesamten Songwriting-Prozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine persönlichen Geschmäcker und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man einen Song schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Schreiben von Songs zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
