import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingTipps() {
const title = "Songwriting-Tipps";
const keywords = "Songwriting-Tipps, Liedtexte erstellen, Musikgenerator, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Lied schreibt";
const description = "Erfahren Sie, wie man mit KI Lieder schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, zugeschnitten auf Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Liedtexte.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Eingebautes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu gestalten. Sobald Sie Liedtexte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem eingebauten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Worten zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ verfügbaren Sprachen können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, komplette Sets von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter ein frustrierendes und demoralisierendes Erlebnis sein. Aber mit unserer Plattform müssen Sie sich nie Sorgen machen, keine Ideen mehr zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Gang bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Menge an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Ihnen während Ihres gesamten Songwriting-Prozesses zu helfen. Es ist wie Ihr eigener persönlicher kreativer Assistent, zugeschnitten auf Ihre eigenen persönlichen Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Hervorragender Kundenservice, wirklich coole Möglichkeit, zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
