import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwriterGerman() {
const title = "Songwriter";
const keywords = "Songwriter, Lyrikgenerator, Musikgenerator, Liedschreiben, Musiksoftwar, Lieder erstellen, Songtexte schreiben, Musikproduktion, Musikkreation, Songwriter-Plattform";
const description = "Lerne, wie man mit KI Songtexte und Musik schreibt";
const longDescription = "Lyrical Labs ist eine Songwriter-Plattform, die KI verwendet, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, abgestimmt auf deine persönlichen Geschmäcker und Bedürfnisse beim Songwriting!";
const h1 = "Lerne, ein Songwriter mit der Kraft der KI zu sein.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration mit unserer KI-betriebenen Lyrik.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Unendliche Songwriting-Inspiration erwartet dich mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art erzeugen.";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald du Texte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Beenden deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, wie man Songtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Zielt die Plattform darauf ab, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, also behältst du alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Versorgung mit Inspiration zu bieten und den Schreibblock zu überwinden. Die KI ist zwar in der Lage, komplette Texte und musikalische Melodien zu generieren, wir ermutigen dich jedoch, sie als Ausgangspunkt zu verwenden und deine persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und entmutigende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen darüber machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil an, und die KI wird dir einen endlosen Strom kreativer und origineller Ideen bieten, mit denen du arbeiten kannst. Das kann deinen Songwriting-Prozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Versorgung an Ideen und Vorschlägen basierend auf deinen stilistischen Vorlieben zu bieten. Dadurch hast du die Freiheit zu experimentieren und etwas Neues zu versuchen.";
const faqa4b = "Zusätzlich ermöglicht es dir die Plattform, die Lyrics nach eigenem Willen zu schreiben und zu editieren. Mit einem einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, das dir während des gesamten Songwriting-Prozesses hilft. Es ist wie dein persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmäcke und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben.";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt.";
const quote3 = "Ausgezeichneter Kundendienst, wirklich coole Möglichkeit, zu erfahren, wie man ein Lied schreibt.";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
