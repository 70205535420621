import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManAnfängtLiedtexteZuSchreiben() {
const title = "Wie Man Anfängt, Liedtexte Zu Schreiben";
const keywords = "Liedtexte schreiben, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Songwriting-Website, wie man anfängt, Liedtexte zu schreiben";
const description = "Lerne, wie man mit KI Liedtexte und Musikmelodien schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, angepasst an deinen eigenen Geschmack und deine Songwriting-Bedürfnisse!";
const h1 = "Lerne, wie man Liedtexte mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet dich mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald du Texte und Musik hast, kannst du dem KI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und dir helfen, deine Liedtexte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist zu 100% lizenzfrei, du behältst also alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, empfehlen wir dir, diese als Ausgangspunkt zu verwenden und deine persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform musst du dir keine Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom kreativer und origineller Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Songwriting-Prozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgerichtet, dir eine endlose Versorgung mit Ideen und Vorschlägen basierend auf deinen stilistischen Vorlieben zu bieten, und gibt dir die Freiheit zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich erlaubt es die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einmaligen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich durch den gesamten Songwriting-Prozess zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmacks- und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man Liedtexte schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Liedtexten zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
