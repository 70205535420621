import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManSongwritingLernt() {
const title = "Wie Man Songwriting Lernt";
const keywords = "Liedtexte, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man Songwriting lernt";
const description = "Erfahren Sie, wie man mit KI Songwriting betreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmäcke und Bedürfnisse beim Songwriting!";
const h1 = "Lernen Sie, wie man Songwriting mit der Kraft der KI betreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Liedtexte.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes cleveres Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einer nie endenden Quelle von originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Gattung generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu erschaffen. Sobald Sie Liedtexte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten cleveren Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und Ihnen helfen, Ihre Liedtexte zu vervollständigen.";
const featureParagraph4 = "Mit über 100 verfügbaren Sprachen können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer’s Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist zu 100% lizenzfrei, sodass Sie alle Rechte an der von Ihnen auf dieser Plattform erstellten Musik und den Liedtexten behalten.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und den Writer’s Block zu überwinden. Während die KI in der Lage ist, komplette Sets von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Writer’s Block kann eine frustrierende und demotivierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich keine Sorgen mehr machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Menge an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie während des gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener kreativer persönlicher Assistent, auf Ihre persönlichen Geschmäcke und Bedürfnisse beim Songwriting abgestimmt!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man Songwriting betreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, Songwriting zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
