import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 音楽を書くJa() {
const title = "音楽を書く";
const keywords = "音楽生成, 歌詞生成, 音楽, 曲作り, 曲作りアシスタント, 曲作りアプリ, 曲作りプラットフォーム, 曲作りソフトウェア, 曲作りツール, 曲作りツール, 曲作りウェブサイト, 音楽を書く方法";
const description = "AIを使って音楽を書く方法を学びましょう";
const longDescription = "Lyrical Labsは、AIを活用してより速く歌詞と音楽を作成するための曲作りプラットフォームです。個々の趣味や曲作りのニーズに合ったパーソナルな曲作りアシスタントです！";
const h1 = "AIの力を利用して音楽を書く方法を学びましょう";
const h2 = "私たちのAIパワードの歌詞で曲作りを革命化し、無限のインスピレーションを手に入れましょう";
const sectionHeader = "究極の曲作りコンパニオン";
const featureTitle1 = "限界なき創造力";
const featureTitle2 = "あなた専用の曲作りアシスタント";
const featureTitle3 = "組み込みスマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "終わりなき創作インスピレーションが、オリジナルのアイデアとメロディーを絶え間なく提供します。AIはどんなトピックについても曲を書き、どんなジャンルの音楽も生成できます";
const featureParagraph2 = "AI曲作りパートナーと協力して完璧な曲を作りましょう。歌詞と音楽ができたら、AIにフィードバックを話して音楽的ビジョンを実現させます。";
const featureParagraph3 = "プラットフォームには、関連する様々な言葉を見つけるのに役立つ組み込みスマート辞書が装備されており、歌詞完成の助けとインスピレーションを提供します。";
const featureParagraph4 = "100以上の言語から選べるため、どの言語でも歌詞を書く方法を学べます！";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目的は私のために完全に曲を書くことですか？";
const faq3 = "プラットフォームはどのようにして私のライターズブロックを解消するのですか？";
const faq4 = "プラットフォームはどのようにして私の曲作りプロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーで、このプラットフォームで作成した音楽と歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、私たちのプラットフォームの目的は、無尽蔵のインスピレーションの供給とライターズブロックの克服を支援することです。AIは完全な歌詞と音楽のメロディーを生成する能力がありますが、それらを出発点として利用し、あなた自身の個人的なタッチを加えることをお勧めします。AIはあなたを支援し、インスパイアするためにここにいますが、あなたの創造性を置き換えるものではありません。";
const faqa3 = "ライターズブロックは、どんな曲作りの人にとってもフラストレーションが溜まり、意気消沈する経験です。しかし、当プラットフォームを使用すれば、アイデアに困ることはもうありません。希望のテーマとスタイルを入力するだけで、AIが創造的でオリジナルのアイデアの無限の流れを提供します。これにより曲作りプロセスを活性化し、スムーズに進めることができます。";
const faqa4a = "当プラットフォームは、選択したスタイルの設定に基づいてアイデアや提案の無尽蔵の供給を提供します。これにより、実験し新しいことを試す自由が得られます。";
const faqa4b = "さらに、このプラットフォームでは、'関連する言葉'の辞書が手元にあるので、あなた自身の意志で歌詞を書き、編集することができます。これは、あなたの個別の味覚や曲作りのニーズに合った独自の創造的アシスタントのようなものです。";
const quote1 = "これはゲームチェンジャーです。私が初めて曲を書くのを本当に助けてくれました";
const quote2 = "素晴らしい製品です。歌を書く方法を学びたい人にお勧めします";
const quote3 = "優れたカスタマーサービスで、曲を書く方法を学ぶのがとても楽しいです";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
