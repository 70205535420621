import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManMusikMacht() {
const title = "Wie Man Musik Macht";
const keywords = "Musik machen, Lyrikgenerator, Musikgenerator, Musikkomposition, Musikkompositionsassistent, Musikkompositions-App, Musikkompositionsplattform, Musikkompositionssoftware, Musikkompositionstool, Musikkompositionswerkzeuge, Musikkompositionswebsite, wie man Musik macht";
const description = "Lernen Sie, wie man Musik und Lieder mit Hilfe von KI macht";
const longDescription = "Lyrical Labs ist eine Musikkompositionsplattform, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Musikkompositionsassistent, angepasst an Ihre eigenen Geschmäcke und Bedürfnisse beim Musikmachen!";
const h1 = "Lernen Sie, wie man Musik macht, mit der Kraft der KI.";
const h2 = "Revolutionieren Sie Ihre Musikkomposition und holen Sie sich unendliche Inspiration mit unserem KI-gestützten Lyrik- und Musikgenerator.";
const sectionHeader = "Der ultimative Begleiter für Musikkomposition";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Musikkompositionsassistent";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Komponieren in jeder Sprache";
const featureParagraph1 = "Unendliche Inspiration für Musikkomposition erwartet Sie mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeiten Sie zusammen mit unserem KI Musikkompositionspartner, um das perfekte Lied zu erschaffen. Sobald Sie Liedtexte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist ausgestattet mit einem integrierten smarten Wörterbuch, das Ihnen hilft, alle Arten von verwandten Worten zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Liedtexte zu helfen.";
const featureParagraph4 = "Mit mehr als 100+ Sprachen zur Auswahl, können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikkompositionsprozess";
const faqa1 = "Ja! Lyrical Labs ist 100% gebührenfrei, Sie behalten also alle Rechte an der Musik und den Liedtexten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI fähig ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Musikschaffenden eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen unendlichen Strom an kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Musikkompositionsprozess ankurbeln und Sie wieder in die richtige Spur bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Menge an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich erlaubt es Ihnen die Plattform, die Liedtexte nach eigenem Willen zu schreiben und zu bearbeiten, wobei Ihnen ein hochgradig einzigartiges 'Verwandte Wörter'-Wörterbuch zur Seite steht, um Sie durch Ihren Musikkompositionsprozess zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmäcke und Bedürfnisse beim Musikmachen!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Musik macht";
const quote3 = "Hervorragender Kundenservice, wirklich coole Art, zu lernen, wie man Musik macht";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
