import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Musikmacher() {
const title = "Musikmacher";
const keywords = "Liedtexte, Musikgenerator, Musik, Liederschreiben, Liederschreibungsassistent, Liederschreibungsapp, Liederschreibungsplattform, Liederschreibungssoftware, Liederschreibungswerkzeug, Liederschreibungswerkzeuge, Liederschreibungswebsite, Musikmacher";
const description = "Lernen Sie, mit Hilfe von KI Lieder zu schreiben";
const longDescription = "Musikmacher ist eine Liederschreibungsplattform, die KI verwendet, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Liederschreibungsassistent, der auf Ihre persönlichen Geschmäcke und Bedürfnisse beim Liederschreiben abgestimmt ist!";
const h1 = "Lernen Sie, Lieder mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Liederschreiben und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Der ultimative Begleiter beim Liederschreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Liederschreibungsassistent";
const featureTitle3 = "Eingebautes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration zum Liederschreiben erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann zu jedem Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Liederschreibungspartner zusammen, um das perfekte Lied zu kreieren. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem eingebauten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und Ihnen helfen, Ihre Texte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Liederschreibungsprozess zu beschleunigen?";
const faqa1 = "Ja! Musikmacher ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihren eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Liederschreiber eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen unendlichen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann helfen, Ihren Liederschreibungsprozess in Gang zu bringen und Sie wieder auf Kurs zu bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen einen unendlichen Vorrat an Ideen und Vorschlägen zu bieten, die auf Ihren gewählten stilistischen Präferenzen basieren, und Ihnen die Freiheit geben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Wörter' Wörterbuch an Ihrer Seite, um Sie während des gesamten Liederschreibungsprozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, der auf Ihre eigenen persönlichen Geschmäcke und Liederschreibungsbedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
