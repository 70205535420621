import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManRappt() {
const title = "Wie Man Rappt";
const keywords = "Rap texten, Musikgenerator, Rapmusik, Rap schreiben, Rap-Hilfsprogramm, Rap-App, Rap-Plattform, Rap-Software, Rap-Tool, Rap-Tools, Rap-Website, wie man rappt";
const description = "Lerne mit KI, wie man Rap-Musik und -Texte schreibt";
const longDescription = "Lyrical Labs ist eine Rap-Plattform, die KI verwendet, um dir schneller beim Texten und Komponieren von Rap-Musik zu helfen. Es ist dein persönliches Rap-Hilfsprogramm, angepasst an deinen persönlichen Geschmack und deine Bedürfnisse beim Rap-Schreiben!";
const h1 = "Lerne, wie man rappt mit der Kraft der KI.";
const h2 = "Revolutioniere dein Rap-Schreiben und finde unendliche Inspiration mit unserer KI-gesteuerten Lyrik.";
const sectionHeader = "Der ultimative Begleiter fürs Rap-Schreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönliches Rap-Hilfsprogramm";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Schreiben von Rap-Songs wartet auf dich, mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeite mit unserem KI-Rap-Partner zusammen, um den perfekten Song zu erstellen. Sobald du Texte und Musik hast, kannst du Feedback durch Gespräche mit der KI geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform verfügt über ein integriertes smartes Wörterbuch, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Vervollständigen deiner Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, wie man Rap-Texte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Rap-Schreibprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, also behältst du alle Rechte an der Musik und den Texten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI komplette Sets aus Texten und musikalischen Melodien generieren kann, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Rapper eine frustrierende und demotivierende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI liefert dir einen endlosen Strom kreativer und origineller Ideen, mit denen du arbeiten kannst. Dies kann helfen, deinen Rap-Schreibprozess in Gang zu bringen und dich wieder auf Kurs zu bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Quelle an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Außerdem ermöglicht es dir die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter' Wörterbuch an deiner Seite, um dich durch den gesamten Rap-Schreibprozess zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmacks- und Schreibbedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Rap zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man rappt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Rap-Schreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
