import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function BeatmakerGerman() {
const title = "Beatmaker";
const keywords = "Beatmaker, Musikgenerator, Liedtext-Generator, Musik machen, Musikproduktion, Musik software, Musik-Tool, Musikplattform, Musik schreiben, wie man Musik macht";
const description = "Lerne, wie man mit AI Beatmaker Musik und Liedtexte erstellt";
const longDescription = "Lyrical Labs ist eine Beatmaker-Plattform, die KI verwendet, um dir schneller beim Schreiben von Musik und Liedtexten zu helfen. Es ist dein persönlicher Musikassistent, der auf deine persönlichen Geschmäcker und Musikbedürfnisse zugeschnitten ist!";
const h1 = "Lerne, mit der Kraft der KI Musik zu machen.";
const h2 = "Revolutioniere dein Musikschaffen und erhalte unendliche Inspiration mit unserem KI-gesteuerten Beatmaker.";
const sectionHeader = "Der ultimative Musikbegleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Musikassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Musik in jeder Sprache schreiben";
const featureParagraph1 = "Endlose Inspiration für das Schreiben von Musik und Texten wartet mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-gesteuerten Beatmaker zusammen, um das perfekte Lied zu erschaffen. Sobald du Musik und Liedtexte hast, kannst du durch Gespräche mit der KI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu geben und dir beim Fertigstellen deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Musik für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen kreativen Stillstand zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Musikschaffensprozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, also behältst du alle Rechte an der Musik und den Liedtexten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle der Inspiration zu bieten und kreative Blockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Liedtexte und musikalische Melodien zu erzeugen, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deine persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine Kreativität zu ersetzen.";
const faqa3 = "Ein kreativer Stillstand kann für jeden Musikschaffenden eine frustrierende und demotivierende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Musikschaffensprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Menge an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, sodass du die Freiheit hast, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es dir die Plattform, die Liedtexte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochmodernen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich während des gesamten Musikschaffensprozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, der auf deine eigenen persönlichen Geschmäcker und Bedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Musik macht";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, Musik zu lernen zu machen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
