import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiTexte() {
const title = "Ai-Texte";
const keywords = "Textgenerator KI, KI-Lyrik, Musikgenerator, Textschreiben, Songwriting-Assistent, Textschreib-App, Textschreib-Plattform, Textschreib-Software, Textschreib-Tool, Textschreib-Tools, Textschreib-Website, ai-Texte";
const description = "Lernen Sie, wie man mit KI Texte für Songs schreibt";
const longDescription = "Lyrical Labs ist eine Textschreib-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmacks- und Schreibbedürfnisse!";
const h1 = "Lernen Sie, Texte mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Textschreib-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-basierten Songwriting-Partner zusammen, um den perfekten Song zu erstellen. Sobald Sie Texte und Musik haben, können Sie durch das Gespräch mit der KI Feedback geben und Ihre musikalische Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen mehr zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI liefert Ihnen einen endlosen Strom kreativer und origineller Ideen, mit denen Sie arbeiten können. Das kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Quelle von Ideen und Vorschlägen basierend auf Ihren stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es Ihnen die Plattform, die Texte nach Ihrem eigenen Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words' Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Songwriting-Prozesses unterstützt. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre persönlichen Geschmacks- und Schreibbedürfnisse!";
const quote1 = "Das ist ein Gamechanger. Es hat mir wirklich geholfen, meinen ersten Song zu schreiben.";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man Texte schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, Textschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
