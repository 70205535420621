import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusikUndTexte() {
const title = "Musik Und Texte";
const keywords = "Musikgenerator, Lyrikgenerator, Musik und Texte, Songwriting-Hilfe, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Songwriting-Website, wie man Lieder schreibt";
const description = "Lernen Sie, wie man mit KI Musik und Texte schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Musik und Texten zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmacks- und Songwriting-Anforderungen!";
const h1 = "Lernen Sie, Musik und Texte mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserer KI-gesteuerten Lyrik.";
const sectionHeader = "Der ultimative Begleiter für Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Eingebautes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem unerschöpflichen Vorrat an originellen Ideen und Melodien. Die KI kann zu jedem Thema schreiben und Musik jeder Art erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu erstellen. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Ihr musikalisches Vorhaben zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem eingebauten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, wie man Liedtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft mir die Plattform, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% gebührenfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Sets von Texten und musikalischen Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu nutzen und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und entmutigende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom an kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen einen unendlichen Vorrat an Ideen und Vorschlägen zu bieten, die auf Ihre gewählten stilistischen Vorlieben basieren, und Ihnen die Freiheit geben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "";
const quote1 = "Das ist ein Durchbruch, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
