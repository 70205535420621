import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function ChromMusiklabor() {
const title = "Chrom-Musiklabor";
const keywords = "Liedtexte, Musik Generator, Musik, Liederschreiben, Liederschreib-Assistent, Liederschreib-App, Liederschreib-Plattform, Liederschreib-Software, Liederschreib-Tool, Liederschreib-Tools, Liederschreib-Website, wie man ein Lied schreibt";
const description = "Lerne, wie man ein Lied mit KI schreibt";
const longDescription = "Chrom-Musiklabor ist eine Liederschreib-Plattform, die KI nutzt, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Liederschreib-Assistent, angepasst an deinen persönlichen Geschmack und deine Liederschreib-Bedürfnisse!";
const h1 = "Lerne, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Liederschreiben und erhalte unendliche Inspiration mit unserem KI-gestützten Liedtext-Generator.";
const sectionHeader = "Der ultimative Begleiter für Liederschreiber";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Dein persönlicher Liederschreib-Assistent";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Liederschreib-Inspiration erwartet dich mit einem unerschöpflichen Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeite mit unserem KI-Liederschreibpartner zusammen, um das perfekte Lied zu erschaffen. Sobald du Liedtexte und Musik hast, kannst du Rückmeldungen geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten smarten Wörterbuch ausgestattet, das dir hilft, alle möglichen verwandten Worte zu finden, um Inspiration zu bieten und dir beim Beenden deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen, aus denen du wählen kannst, kannst du lernen, wie man Liedtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform dabei, meinen Liederschreibprozess zu beschleunigen?";
const faqa1 = "Ja! Chrom-Musiklabor ist 100% urheberrechtsfrei, sodass du alle Rechte an der Musik und den Liedtexten, die du auf dieser Plattform erstellst, behältst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Sets von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu nutzen und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und entmutigende Erfahrung für jeden Liederschreiber sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Liederschreibprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir einen unendlichen Vorrat an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, sodass du die Freiheit hast, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht dir die Plattform, die Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words'-Wörterbuch an deiner Seite, das dich während deines gesamten Liederschreibprozesses unterstützt. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deinen eigenen Geschmack und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Liederschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
