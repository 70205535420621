import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function クロムミュージックラボJa() {
const title = "クロムミュージックラボ";
const keywords = "歌詞生成, 音楽生成, 音楽, 曲作り, 曲作りアシスタント, 曲作りアプリ, 曲作りプラットフォーム, 曲作りソフトウェア, 曲作りツール, 曲作りツールズ, 曲作りウェブサイト, 曲の作り方";
const description = "AIを使って曲の作り方を学びましょう";
const longDescription = "クロムミュージックラボは、AIを利用して歌詞と音楽を早く作成するための曲作りプラットフォームです。あなたの個人的な曲作りアシスタントとして、あなたの個人的な趣味や曲作りニーズに合わせています！";
const h1 = "AIの力を使って曲の作り方を学ぼう。";
const h2 = "AIによる歌詞生成で、曲作りを革命しましょう。";
const sectionHeader = "究極の曲作りコンパニオン";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなた専用の曲作りアシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どの言語でも曲作り";
const featureParagraph1 = "終わりなき曲作りのインスピレーションが、オリジナルのアイディアやメロディの絶え間ない供給とともに待っています。AIはあらゆるトピックについて書くことができ、どんなジャンルの音楽も生成することができます。";
const featureParagraph2 = "AI曲作りパートナーと協力して、完璧な曲を作りましょう。歌詞と音楽ができたら、AIにフィードバックを与えて、あなたの音楽的ビジョンを実現させましょう。";
const featureParagraph3 = "プラットフォームには、インスピレーションを提供し、歌詞を完成させるのに役立つ関連語を見つけるための内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選択できるため、どの言語でも歌詞の書き方を学ぶことができます！";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目的は、私の曲を完全に書いてくれることですか？";
const faq3 = "プラットフォームはどのようにして作詞のブロックを解消するのですか？";
const faq4 = "プラットフォームはどのようにして曲作りのプロセスを促進するのですか？";
const faqa1 = "はい！クロムミュージックラボは100％ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞のすべての権利を保持することができます。";
const faqa2 = "いいえ、当プラットフォームの目的は、絶え間ないインスピレーションを提供し、作詞のブロックを克服する手助けをすることです。AIは完全なセットの歌詞や音楽メロディを生成する能力がありますが、それらを出発点として使用し、自分の個人的なタッチを加えることを推奨します。AIはあなたの創造性を置き換えるものではなく、アシストしてインスピレーションを与えるものです。";
const faqa3 = "作詞のブロックは、どの作曲家にとってもイライラするものであり、意気消沈する経験ですが、当プラットフォームを使えば、アイディアが尽きる心配はありません。希望するテーマとスタイルを入力するだけで、AIが創造的でオリジナルのアイディアを絶え間なく提供します。これにより、曲作りのプロセスを促進し、再び軌道に乗せることができます。";
const faqa4a = "当プラットフォームは、選んだスタイリスティックな好みに基づいてアイディアや提案を無限に提供し、新しいことを試メントや実験の自由を与えるように設計されています。";
const faqa4b = "さらに、プラットフォームでは、高度に独特な「関連語辞書」を活用しながら、自分の意志で歌詞を書いたり編集したりすることができます。まるで自分だけの個人的な創造的アシスタントのようで、自分自身の趣味や曲作りニーズに合わせています！";
const quote1 = "これは画期的なものです、私の最初の曲作りに本当に役立ちました。";
const quote2 = "素晴らしい製品です、曲の作り方を学びたい全ての人にお勧めします。";
const quote3 = "優れたカスタマーサービスです、曲の作り方を学ぶのにとてもクールな方法です。";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
