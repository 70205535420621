import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Beatmachen() {
const title = "Beatmachen";
const keywords = "Beatmachen, Lyrics Generator, Musikgenerator, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Songwriting-Website, wie man einen Song schreibt";
const description = "Lernen Sie, wie man mit KI einen Song und Beats erstellt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen beim schnelleren Schreiben von Lyrics und Beats zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, perfekt angepasst an Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, wie man einen Song mit der Kraft der KI macht.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserer KI-gestützten Lyrics und Beats.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Eingebauter intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Beats jeder Genre erzeugen";
const featureParagraph2 = "Kollaborieren Sie mit unserem KI-Songwritingpartner, um den perfekten Song zu kreieren. Sobald Sie Lyrics und Beats haben, können Sie Feedback geben, indem Sie mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Lyrics zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% Lizenzfrei, sodass Sie alle Rechte an der Musik und den Lyrics behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Obwohl die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Writer's Block kann für jeden Songwriter eine frustrierende und demotivierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI versorgt Sie mit einem endlosen Strom kreativer und origineller Ideen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen einen endlosen Vorrat an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht es Ihnen die Plattform, die Lyrics nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter' Wörterbuch an Ihrer Seite, um Sie während Ihres gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, perfekt angepasst an Ihre eigenen persönlichen Geschmacksrichtungen und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man einen Song schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, zu lernen, wie man einen Song schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
