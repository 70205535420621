import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingWebsiteGerman() {
const title = "Songwriting-Website";
const keywords = "Songwriting-Website, Liedtexte, Musikgenerator, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Lied schreibt";
const description = "Erfahren Sie, wie Sie mit KI Lieder schreiben können";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, der auf Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse zugeschnitten ist!";
const h1 = "Erfahren Sie, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserem KI-gesteuerten Liedtext-Generator.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration wartet mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art generieren";
const featureParagraph2 = "Kollaborieren Sie mit unserem KI-Songwriting-Partner, um das perfekte Lied zu gestalten. Sobald Sie Liedtexte und Musik haben, können Sie Feedback geben, indem Sie mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, allerlei verwandte Wörter zu finden, die Inspiration bieten und Ihnen helfen, Ihre Liedtexte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, wie man Songtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu beschleunigen?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und den Schreibblock zu überwinden. Während die KI in der Lage ist, komplette Sätze von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen unendlichen Strom kreativer und origineller Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Versorgung mit Ideen und Vorschlägen basierend auf Ihren stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Liedtexte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandten Wörter'-Lexikon an Ihrer Seite, um Ihnen durchgehend im Songwriting-Prozess zu assistieren. Es ist wie Ihr eigener persönlicher kreativer Assistent, der auf Ihre eigenen persönlichen Geschmacksrichtungen und Bedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein echter Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Möglichkeit, zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
