import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function アイソングライターJa() {
const title = "アイソングライター";
const keywords = "歌詞生成, 音楽生成, 音楽, 作詞アシスタント, 作曲アプリ, 作曲プラットフォーム, 作曲ソフトウェア, 作曲ツール, 作曲ツール, 作曲ウェブサイト, アイソングライター";
const description = "AIを使用して歌の歌詞とメロディを書く方法を学びましょう";
const longDescription = "Lyrical LabsはAIを使用して歌の歌詞と音楽をより速く書くための作曲プラットフォームです。あなたの個人の趣味や作曲のニーズに合わせた個人の作曲アシスタントです！";
const h1 = "AIの力を使って歌を書く方法を学びましょう。";
const h2 = "私たちのAI駆動の歌詞で作曲を革命化し、無限のインスピレーションを得ましょう。";
const sectionHeader = "究極の作曲コンパニオン";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなたの個人的な作曲アシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "オリジナルのアイデアやメロディが尽きることのない無限の作詞インスピレーションが待っています。AIはどのようなトピックにも対応し、任意のジャンルの音楽を生成することができます。";
const featureParagraph2 = "私たちのAI作曲パートナーと協力して、完璧な歌を作り上げましょう。歌詞と音楽が得られたら、AIと対話してフィードバックを提供し、音楽的ビジョンを実現させましょう。";
const featureParagraph3 = "このプラットフォームには、インスピレーションを提供し、歌詞を完成させるのに役立つ関連する言葉を見つけるのに役立つ内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100を超える言語から選べるため、どの言語でも歌詞を書く方法を学ぶことができます。";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目標は私のために完全に曲を書くことですか？";
const faq3 = "このプラットフォームはどのようにして作詞の行き詰まりを克服するのですか？";
const faq4 = "このプラットフォームは作曲プロセスをどのように促進しますか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、当プラットフォームの目的は、無限のインスピレーションを提供し、作詞の行き詰まりを解消することです。AIは完全な歌詞セットと音楽的メロディを生成する能力を持っていますが、それらを出発点として使用し、あなた自身の個人的なタッチを加えることを奨励します。AIはあなたを支援し、触発するためにここにありますが、あなたの創造性を置き換えるものではありません。";
const faqa3 = "作詞の行き詰まりは、どの作詞家にとってもイライラする経験です。しかし、当プラットフォームを使用すれば、アイディアに行き詰まることは一切ありません。希望のテーマとスタイルを入力し、AIが創造的でオリジナルのアイディアの無限の流れを提供します。これにより、作詞プロセスが再起動し、軌道に乗ることができます。";
const faqa4a = "当プラットフォームは、選択したスタイルの好みに基づいてアイディアと提案の無限の供給を提供するように設計されており、新しいことを試み、実験する自由を与えます。";
const faqa4b = "さらに、プラットフォームでは、随時歌詞の作成や編集が可能であり、書き進めるプロセス全体をサポートするために、ユニークな「関連語辞書」を横に置いて利用できます。あなた自身のパーソナルクリエイティブアシスタントのようなもので、あなた自身の個人的な趣味や作曲ニーズに合わせています！";
const quote1 = "これは画期的で、私が初めての歌を書くのに大変役立ちました";
const quote2 = "素晴らしい製品です。誰でも歌の書き方を学ぶためにお勧めします";
const quote3 = "優れたカスタマーサービスで、歌の書き方を学ぶのにとてもクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
