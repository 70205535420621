import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiLyricGeneratorGerman() {
const title = "Ai-Lyric-Generator";
const keywords = "AI-Lyrik-Generator, Musikgenerator, Songtexte erstellen, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Lied schreibt";
const description = "Lernen Sie mit einem AI-Lyrik-Generator, wie man ein Lied schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre eigenen persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, wie man ein Lied mit Hilfe von KI schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unseren KI-gesteuerten Lyrik-Generator.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes kluges Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Stilrichtung generieren";
const featureParagraph2 = "Kooperieren Sie mit unserem KI-Songwriting-Partner, um das perfekte Lied zu kreieren. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten klugen Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, wie man Songtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Copyright?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, also behalten Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle an Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, komplette Sets von Texten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom an kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Gang bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist so konzipiert, dass sie Ihnen eine endlose Quelle an Ideen und Vorschlägen bietet, basierend auf Ihren gewählten stilistischen Vorlieben, und gibt Ihnen die Freiheit, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Außerdem ermöglicht Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie während des gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Hervorragender Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
