import React, { useState } from 'react';
import './Modal.css';
import useLocalStorage from '../../hooks/useLocalStorage';


const Modal = ({ onClose, user }) => {
  const [emails, setEmails] = useState(['']);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonStatus, setButtonStatus] = useState(true);
  const url = "https://frozen-springs-61240.herokuapp.com"
  const getVariant = () => {
    const variants = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  
  const [variant, setVariant] = useLocalStorage('modalButton', getVariant());
  const [variant2, setVariant2] = useLocalStorage('modalButtonColour', getVariant());

  let buttonVariant;

  if (variant === 'A') {
    buttonVariant = "Buy Credits";
  } else if (variant === 'B') {
    buttonVariant = "Get Credits";
  } else if (variant === 'C') {
    buttonVariant = "Upgrade Now";
  } else if (variant === 'D') {
    buttonVariant = "Unlock Full Access";
  } else if (variant === 'E') {
    buttonVariant = "Purchase Credits";
  } else if (variant === 'F') {
    buttonVariant = "Get More Credits";
  } else if (variant === 'G') {
    buttonVariant = "Unlock More Songs";
  } else if (variant === 'H') {
    buttonVariant = "Continue your journey";
  } else if (variant === 'I') {
    buttonVariant = "Continue creating";
  } else if (variant === 'J') {
    buttonVariant = "Keep going";
  } else if (variant === 'K') {
    buttonVariant = "Don't let the music stop";
  } else {
    buttonVariant = "Buy Credits";
  }

  let buttonColourVariant;
  let buttonHoverColourVariant;
  let buttonRingColourVariant;

  if (variant2 === 'A') {
    buttonColourVariant = "bg-indigo-500";
    buttonHoverColourVariant = "hover:bg-indigo-700";
    buttonRingColourVariant = "focus:ring-indigo-500";
  } else if (variant2 === 'B') {
    buttonColourVariant = "bg-green-500";
    buttonHoverColourVariant = "hover:bg-green-700";
    buttonRingColourVariant = "focus:ring-green-500";
  } else if (variant2 === 'C') {
    buttonColourVariant = "bg-red-500";
    buttonHoverColourVariant = "hover:bg-red-700";
    buttonRingColourVariant = "focus:ring-red-500";
  } else if (variant2 === 'D') {
    buttonColourVariant = "bg-yellow-500";
    buttonHoverColourVariant = "hover:bg-yellow-700";
    buttonRingColourVariant = "focus:ring-yellow-500";
  } else if (variant2 === 'E') {
    buttonColourVariant = "bg-blue-500";
    buttonHoverColourVariant = "hover:bg-blue-700";
    buttonRingColourVariant = "focus:ring-blue-500";
  } else if (variant2 === 'F') {
    buttonColourVariant = "bg-purple-500";
    buttonHoverColourVariant = "hover:bg-purple-700";
    buttonRingColourVariant = "focus:ring-purple-500";
  } else if (variant2 === 'G') {
    buttonColourVariant = "bg-pink-500";
    buttonHoverColourVariant = "hover:bg-pink-700";
    buttonRingColourVariant = "focus:ring-pink-500";
  } else if (variant2 === 'H') {
    buttonColourVariant = "bg-orange-500";
    buttonHoverColourVariant = "hover:bg-orange-700";
    buttonRingColourVariant = "focus:ring-orange-500";
  } else if (variant2 === 'I') {
    buttonColourVariant = "bg-rose-500";
    buttonHoverColourVariant = "hover:bg-rose-700";
    buttonRingColourVariant = "focus:ring-rose-500";
  } else if (variant2 === 'J') {
    buttonColourVariant = "bg-lime-500";
    buttonHoverColourVariant = "hover:bg-lime-700";
    buttonRingColourVariant = "focus:ring-lime-500";
  } else if (variant2 === 'K') {
    buttonColourVariant = "bg-cyan-500";
    buttonHoverColourVariant = "hover:bg-cyan-700";
    buttonRingColourVariant = "focus:ring-cyan-500";
  } else {
    buttonColourVariant = "bg-indigo-500";
    buttonHoverColourVariant = "hover:bg-indigo-700";
    buttonRingColourVariant = "focus:ring-indigo-500";
  }

  const captureExperiment = () => {
    fetch(`${url}/experiment-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experimentName: 'modal',
        experimentDescription: "The modal when users reach the credit limit",
        variants: {"button": buttonVariant, "buttonColour": buttonColourVariant},
        action: "click",
      })
    })
    .catch((error) => {
    });
  }

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log('Failed to copy!');
    }
  }

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmails = emails.filter((email) => email !== '');
    if (validEmails.length === 0) {
      setErrorMessage('Please enter at least one email address');
      return;
    }
    // Call "/invite-users" endpoint with the array of emails in the request body
    // If the request is successful, call onClose() to close the modal
    // If the request fails, set the error message to the error message returned from the server
    const response = await fetch(`${url}/invite-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.token,
      },
      body: JSON.stringify({
         emails: validEmails,
         invitedByName: user.name,
         invitedById: user._id,
         }),
    });
    if (response.ok) {
      onClose();
    } else {
      setErrorMessage("An error has occurred");
    }
    
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <h2>You've reached the credit limit for a free user...</h2>
        <button className="close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="button-container">
          <button className={`
          ${buttonColourVariant}
          ${buttonHoverColourVariant}
          ${buttonRingColourVariant}
          text-white 
          px-2 
          py-5 
          mb-10 
          rounded 
          border-none 
          mt-5 
          text-lg 
          font-bold 
          cursor-pointer  
          `}
          onClick={() => {
            captureExperiment();
            window.location.href='/prices'
            }}>
            {buttonVariant}
          </button>
        </div>
        <hr className='border-white/5 mt-6'/>
        <div className="invite-container">
          <h2>Invite friends to get more credits instead!</h2>
          <div className="padding-bottom">
            <h4 className="text-sm">
              You will get more credits immediately, 
              and you will get <strong className='text-indigo-400'>5 credits</strong> for each friend who signs up with your link!
            </h4>
            <br></br>

            <p>Invite by link:</p>
            <div className="grid grid-cols-8 space-x-0">
              <input type="text" className="col-start-1 col-span-6 text-sm" value={`https://lyricallabs.io/register?invite=${user._id}`} readOnly onClick={() => copyToClipBoard(`https://lyricallabs.io/register?invite=${user._id}`)} />
              <button className="col-start-7 col-span-2 text-sm bg-indigo-700 rounded-md rounded-l-none" value="Click to copy" onClick={() => copyToClipBoard(`https://lyricallabs.io/register?invite=${user._id}`)}>Click to copy</button>
            </div>
          </div>
          <p>Invite by email:</p>
          <form onSubmit={handleSubmit}>
            {emails.map((email, index) => (
              <div key={index}>
                <div className="grid grid-cols-8 space-x-2">
                <input
                  type="email"
                  value={email}
                  onChange={(event) => {
                    const newEmails = [...emails];
                    newEmails[index] = event.target.value;
                    setEmails(newEmails);
                  }}
                  placeholder="Enter email address"
                  className="col-start-1 col-span-6 text-sm"
                  required
                />
                {index > 0 && (
                  <button type="button" onClick={() => handleRemoveEmail(index)} className="remove-button col-start-7 col-span-2 text-sm bg-indigo-700 rounded-md rounded-l-none">
                    Remove
                  </button>
                )}
                </div>
                
              </div>
            ))}
            <button type="button" onClick={handleAddEmail} className="add-button">
              Add another email
            </button>
            <div className="invite-button-container">
              <button disabled={!buttonStatus} type="submit" className="submit-button">
                Submit
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
