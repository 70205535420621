import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongGeneratorGerman() {
const title = "Songgenerator";
const keywords = "Songgenerator, Musikgenerator, Liedtext Generator, Lied erstellen, Songwriting-Software, Songwriting-Hilfe, Songwriting-Plattform, Songwriting-Werkzeug, Musik erstellen, wie man Lieder schreibt";
const description = "Erfahren Sie, wie Sie mit KI Lieder schreiben können";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Erlernen Sie das Schreiben von Songs mit der Kraft der KI.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserem KI-betriebenen Songgenerator.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann zu jedem Thema schreiben und Musik jeder Gattung generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songgenerator zusammen, um das perfekte Lied zu schaffen. Sobald Sie Texte und Musik haben, können Sie durch das Sprechen mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, können Sie lernen, wie man Songtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, so dass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI fähig ist, vollständige Sätze von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir Sie, sie als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen um Ideenmangel machen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI versorgt Sie mit einem endlosen Strom kreativer und origineller Ideen. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Quelle von Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie durch Ihren gesamten Songwriting-Prozess zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacksrichtungen und Bedürfnisse!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Songs zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
