import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WasIstSongwriting() {
const title = "Was Ist Songwriting?";
const keywords = "Songwriting, Liedtexte, Musikgenerator, Musik schreiben, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, Was ist Songwriting?";
const description = "Erfahren Sie, wie Songwriting mit KI funktioniert";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, der auf Ihre persönlichen Geschmäcker und Bedürfnisse beim Songwriting zugeschnitten ist!";
const h1 = "Lernen Sie, wie man mit der Kraft der KI Songwriting betreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Texte und Melodien.";
const sectionHeader = "Der ultimative Begleiter für Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Kollaborieren Sie mit unserem KI-Songwriting-Partner, um das perfekte Lied zu erschaffen. Sobald Sie Texte und Musik haben, können Sie Feedback durch Gespräche mit der KI geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle möglichen verwandten Wörter zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen, aus denen Sie wählen können, können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu beschleunigen?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Versorgung mit Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI liefert Ihnen einen unendlichen Strom von kreativen und originellen Ideen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Songwriting-Prozesses unterstützt. Es ist wie Ihr eigener persönlicher Kreativ-Assistent, der auf Ihre eigenen persönlichen Geschmäcker und Bedürfnisse im Songwriting zugeschnitten ist!";
const quote1 = "Das ist ein Spielwechsler, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Songs schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Songwriting zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
