import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function RapAutor() {
const title = "Rap-Autor";
const keywords = "Rap, Rap-Autor, Musikgenerator, Raptexte, Textgenerator, Rap-Software, Rap-Tool, Musikproduktion, Rap erstellen, Texte schreiben, wie man Rap schreibt";
const description = "Lerne, wie man Rap-Texte und Musik mit KI erstellt";
const longDescription = "Rap-Autor ist eine Plattform für das Schreiben von Rap-Texten und Musik, die KI nutzt, um dir schneller beim Schreiben von Lyrics und Melodien zu helfen. Es ist dein persönlicher Assistent für das Schreiben von Rap, angepasst an deinen eigenen Geschmack und deine Bedürfnisse!";
const h1 = "Lerne Rap zu schreiben mit der Kraft der KI.";
const h2 = "Revolutioniere deine Rap-Kreationen und erhalte unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Dein ultimativer Partner für das Rap-Schreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Rap-Schreibassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Rap-Schreiben erwartet dich mit einem niemals endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jedes Genres generieren.";
const featureParagraph2 = "Arbeite mit unserem KI-Rap-Partner zusammen, um den perfekten Rap zu erschaffen. Sobald du Texte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Vervollständigen deiner Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, wie man Rap-Texte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Raps komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Rap-Schreibprozess?";
const faqa1 = "Ja! Rap-Autor ist 100% lizenzfrei, sodass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können ein frustrierendes und entmutigendes Erlebnis für jeden Texter sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI bietet dir einen endlosen Strom kreativer und origineller Ideen, mit denen du arbeiten kannst. Dies kann deinen Rap-Schreibprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Quelle von Ideen und Anregungen zu bieten, basierend auf deinen gewählten stilistischen Vorlieben, und gibt dir die Freiheit zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht dir die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, das dich während deines gesamten Rap-Schreibprozesses unterstützt. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen Bedürfnisse und Geschmacksrichtungen!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Rap zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Rap schreibt";
const quote3 = "Hervorragender Kundenservice, wirklich coole Art, Rap-Schreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
