import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManLiederSchreibt() {
const title = "Wie Man Lieder Schreibt";
const keywords = "Liedtexte erstellen, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man Lieder schreibt";
const description = "Erfahre, wie man Lieder mit KI schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, angepasst an deinen eigenen Geschmack und deine Songwriting-Bedürfnisse!";
const h1 = "Lerne, wie man Lieder mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration mit unseren KI-gesteuerten Texten.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration wartet mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald du Texte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Fertigstellen deiner Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, wie man Songtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist zu 100% urheberrechtsfrei, so dass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, komplette Sets von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu verwenden und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Eine Schreibblockade kann eine frustrierende und entmutigende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Das kann deinen Songwriting-Prozess in Gang bringen und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Versorgung mit Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zudem ermöglicht dir die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich während deines gesamten Songwriting-Prozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deinen eigenen Geschmack und deine Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, zu lernen, wie man Lieder schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
