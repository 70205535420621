import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwriterHelfer() {
const title = "Songwriter-Helfer";
const keywords = "Songwriter-Helfer, Liedtext Generator, Musikgenerator, Songwriting, Songwriting Assistent, Songwriting App, Songwriting Plattform, Songwriting Software, Songwriting Tool, Songwriting Tools, Songwriting Webseite, Lieder schreiben lernen";
const description = "Lernen Sie, Lieder mit KI zu schreiben";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriter-Helfer, angepasst an Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, Lieder mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Lyrics.";
const sectionHeader = "Der ultimative Songwriter-Helfer";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriter-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration wartet mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriter-Partner zusammen, um das perfekte Lied zu erschaffen. Sobald Sie Texte und Musik haben, können Sie Feedback geben, indem Sie mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu liefern und Ihnen beim Fertigstellen Ihrer Lyrics zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihren eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Writer’s Block kann eine frustrierende und demotivierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Schwung bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht die Plattform, dass Sie die Texte nach eigenem Willen schreiben und bearbeiten können, mit einem hochgradig einzigartigen 'Verwandten Wörter'-Wörterbuch an Ihrer Seite, um Sie während Ihres gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
