import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Liedermacher() {
const title = "Liedermacher";
const keywords = "Liedermacher, Musikgenerator, Lyrikgenerator, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Lied schreibt";
const description = "Lernen Sie, wie man mit KI Lieder schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, der auf Ihre persönlichen Geschmäcke und Bedürfnisse zugeschnitten ist!";
const h1 = "Lernen Sie, ein Lied mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Texte.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Songwriting erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu schreiben. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und Ihnen helfen, Ihre Texte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, daher behalten Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und den Schreibblock zu überwinden. Obwohl die KI komplette Sets von Texten und musikalischen Melodien generieren kann, empfehlen wir Ihnen, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und entmutigende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Gang bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Quelle von Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, und gibt Ihnen die Freiheit, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandten Wörter'-Wörterbuch an Ihrer Seite, um Sie während Ihres gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, der auf Ihre eigenen persönlichen Geschmäcke und Bedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
