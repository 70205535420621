import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 音楽の作り方Ja() {
const title = "音楽の作り方";
const keywords = "音楽生成, 歌詞生成, 音楽の作り方, AI音楽アシスタント, 音楽作成アプリ, 音楽作成プラットフォーム, 音楽作成ソフトウェア, 音楽作成ツール, 音楽作成ツール, 音楽作成ウェブサイト";
const description = "AIを使って音楽と歌詞を作る方法を学びましょう";
const longDescription = "Lyrical Labsは、AIを活用してより速く歌詞と音楽を作成するためのプラットフォームです。それはあなたの個人的な音楽作成アシスタントであり、あなたの個人的な音楽作成ニーズや好みに合わせて設計されています。";
const h1 = "AIのパワーを使って音楽の作り方を学びましょう。";
const h2 = "AI駆動の歌詞であなたの音楽作成を革命化し、無限のインスピレーションを得ましょう。";
const sectionHeader = "究極の音楽作成のパートナー";
const featureTitle1 = "創造性に制限なし";
const featureTitle2 = "あなたの個人的な音楽作成アシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どの言語でも作曲";
const featureParagraph1 = "元々のアイデアやメロディの絶え間ない供給で終わりなき音楽作成インスピレーションが待っています。AIはどんなトピックについても歌を書き、どのジャンルの音楽も生成できます。";
const featureParagraph2 = "完璧な歌を作り上げるためにAI音楽パートナーと協力してください。歌詞と音楽ができたら、AIにフィードバックを与えて音楽ビジョンを実現しましょう。";
const featureParagraph3 = "このプラットフォームには関連するあらゆる種類の言葉を見つけるのに役立つ内蔵スマート辞書が装備されています。これによりインスピレーションを得て、歌詞を完成させるのに役立ちます。";
const featureParagraph4 = "100以上の言語から選択できるため、どの言語でも歌詞の書き方を学ぶことができます。";
const faq1 = "著作権は保持されますか？";
const faq2 = "このプラットフォームの目的は私のために完全に曲を作ることですか？";
const faq3 = "このプラットフォームはどのようにして私のライターズブロックを乗り越えるのを助けますか？";
const faq4 = "このプラットフォームはどのようにして私の音楽作成プロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーで、このプラットフォームで作成した音楽や歌詞の全ての権利を保持します。";
const faqa2 = "いいえ、私たちのプラットフォームの目的は無限のインスピレーションを提供し、ライターズブロックを克服する手助けをすることです。AIは完全な歌詞と音楽のメロディを生成する能力がありますが、それらを出発点として使用し、あなた自身の個人的なタッチを加えることをお勧めします。AIはあなたを補助しインスピレーションを与えるためのものです。あなたの創造性を置き換えるものではありません。";
const faqa3 = "ライターズブロックは、どの音楽作成者にとってもいら立ちや落胆を引き起こす経験です。でも、当プラットフォームを使えばアイデアが尽きることは決してありません。望むテーマとスタイルを入力するだけで良く、AIはクリエイティブでオリジナルなアイデアを絶え間なく提供します。これはあなたの音楽作成プロセスを再開し、軌道に戻す手助けとなるでしょう。";
const faqa4a = "当プラットフォームは、あなたが選択したスタイリスティックな好みに基づいて無限のアイデアや提案を提供するように設計されており、新しいことを試みる自由をあなたに与えます。";
const faqa4b = "さらに、このプラットフォームでは、あなたが自由に歌詞を書いたり編集したりすることができます。その際、「関連単語」辞書があなたの音楽作成プロセス全体を通じてサポートとインスピレーションを提供します。それはあなた自身の個人的なクリエイティブアシスタントのようなものです。";
const quote1 = "これはゲームチェンジャーです、僕の最初の曲を書くのに本当に役立ちました";
const quote2 = "素晴らしい製品です、曲を書く方法を学びたい誰にでもお勧めします";
const quote3 = "優れたカスタマーサービスです、曲を作る方法を学ぶのに本当にクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
