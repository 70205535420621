import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Technomusik() {
const title = "Technomusik";
const keywords = "Technomusik, Lyrikgenerator, Musikgenerator, Musikproduktion, AI Musik, Musikkomposition, Musikplattform, Musiksoftware, Musik-Tool, Musik-Website, Technomusik erstellen";
const description = "Erstelle mit KI Hilfe Technomusik";
const longDescription = "Lyrical Labs ist eine Musikplattform, die KI nutzt, um dir zu helfen, Technomusik und Lyrik schneller zu erzeugen. Es ist dein persönlicher Musikassistent, angepasst an deinen eigenen Geschmack und Musikbedürfnisse!";
const h1 = "Erstelle Technomusik mit der Kraft der AI.";
const h2 = "Revolutioniere deine Musikproduktion und erhalte unendliche Inspiration durch unsere AI-gesteuerten Lyrics.";
const sectionHeader = "Der ultimative Musikbegleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Musikassistent";
const featureTitle3 = "Integriertes Smart-Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für Technomusik wartet auf dich, mit einer nie endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem AI-Musikpartner zusammen, um das perfekte Technolied zu kreieren. Sobald du Lyrics und Musik hast, kannst du Feedback geben, indem du mit der AI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten Smart-Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und dir helfen, deine Lyrics zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, wie man Liedtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Copyright?";
const faq2 = "Ist das Ziel der Plattform, meine Songs vollständig zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikproduktionsprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% gebührenfrei, sodass du alle Rechte an der Musik und den Lyrics behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine endlose Versorgung mit Inspiration zu bieten und den Writer's Block zu überwinden. Während die KI in der Lage ist, komplette Sets von Lyrics und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu nutzen und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Writer's Block kann für jeden Musikschaffenden eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen um das Ausgehen von Ideen machen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Musikproduktionsprozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine endlose Versorgung mit Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, und gibt dir die Freiheit, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht dir die Plattform, die Lyrics nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words' Wörterbuch an deiner Seite, um dich während deines Musikproduktionsprozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmacks- und Musikbedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Technolied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man Technomusik schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, zu lernen, wie man Technomusik schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
