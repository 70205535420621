import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function あい歌詞Ja() {
const title = "あい歌詞";
const keywords = "歌詞生成, 音楽生成, 歌作り, 歌詞アプリ, 歌詞プラットフォーム, 歌詞ソフトウェア, 歌詞作成ツール, 歌詞作成ツール, 歌詞作成ウェブサイト, あい歌詞";
const description = "AIを使って歌詞とメロディを作成する方法を学びましょう";
const longDescription = "Lyrical Labsは、AIを使用して歌詞と音楽をより速く書くためのプラットフォームです。それはあなた自身の個人的な好みと作曲のニーズに合わせて、あなたの個人的な作曲アシスタントです。";
const h1 = "AIの力を使って歌を書く方法を学びましょう。";
const h2 = "私たちのAI駆動の歌詞であなたの作曲を革新し、無限のインスピレーションを得ましょう。";
const sectionHeader = "究極の作曲のパートナー";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなたの個人的な作曲アシスタント";
const featureTitle3 = "組み込みスマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "終わりなきオリジナルのアイデアとメロディが提供されるので、歌作りのインスピレーションは尽きることがありません。AIはどんなトピックについても書くことができ、どんなジャンルの音楽も生成できます。";
const featureParagraph2 = "私たちのAI作曲パートナーと協力して、完璧な歌を作り出しましょう。歌詞と音楽ができたら、AIにフィードバックを提供することで、あなたの音楽ビジョンを実現させることができます。";
const featureParagraph3 = "このプラットフォームは、インスピレーションを提供し、歌詞を完成させるのを助けるために、さまざまな関連語を見つけるのを助ける組み込みのスマート辞書を備えています。";
const featureParagraph4 = "100以上の言語から選択できるため、どの言語でも歌詞を書く方法を学ぶことができます。";
const faq1 = "著作権は私が保持しますか？";
const faq2 = "このプラットフォームの目的は、私の歌を完全に書くことですか？";
const faq3 = "このプラットフォームはどのように作詞ブロックを克服するのに役立ちますか？";
const faq4 = "このプラットフォームはどのようにして作詞プロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100％ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞の全ての権利を保持します。";
const faqa2 = "いいえ、このプラットフォームの目的は、無限のインスピレーションを提供し、作詞ブロックを克服する手助けをすることです。AIは完全な歌詞セットと音楽的メロディを生成する能力がありますが、それらを出発点として使用し、個人的なタッチを加えることを奨励します。AIはあなたの創造性を置き換えるためではなく、支援し、インスピレーションを与えるためにここにあります。";
const faqa3 = "作詞ブロックは、どの作曲者にとってもイライラするし、落胆する経験です。しかし、当プラットフォームを使用すれば、アイデアが尽きる心配はありません。希望のテーマとスタイルを入力するだけで、AIが創造的でオリジナルのアイデアを無限に提供し、あなたの作曲プロセスを再開させるのに役立ちます。";
const faqa4a = "当プラットフォームは、選択したスタイリスティックな好みに基づいてアイデアや提案を無限に提供するように設計されており、新しいことを試し、実験する自由を与えます。";
const faqa4b = "さらに、プラットフォームでは独自の「関連語辞書」を使用して歌詞を自由に書いたり編集したりすることができます。それはあなた自身の個人的な創造的アシスタントのようなもので、あなたの個人的な好みと作曲のニーズに合うように調整されています。";
const quote1 = "これは画期的です、初めての歌を書くのに本当に役立ちました";
const quote2 = "素晴らしい製品です、歌を書く方法を学びたい人にお勧めします";
const quote3 = "優れたカスタマーサービス、歌を書く方法を学ぶのに本当にクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
