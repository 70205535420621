import React from 'react';

const articlesData = [
    {id: 1, title: 'How to write a song', link: '/how-to-write-a-song'},
    {id: 2, title: 'Lyric Generator', link: '/lyric-generator'},
    {id: 3, title: 'Rap Lyric Generator', link: '/rap-lyric-generator'},
    {id: 4, title: 'Rap Generator', link: '/rap-generator'},
    {id: 5, title: 'Beat Maker', link: '/beat-maker'},
    {id: 6, title: 'Rap Beats', link: '/rap-beats'},
    {id: 7, title: 'Ai Lyrics Generator', link: '/ai-lyrics-generator'},
    {id: 8, title: 'Ai Lyrics', link: '/ai-lyrics'},
    {id: 9, title: 'Ai Lyric Generator', link: '/ai-lyric-generator'},
    {id: 10, title: 'How To Write A Song Lyrics', link: '/how-to-write-a-song-lyrics'},
    {id: 12, title: 'How To Write Lyrics', link: '/how-to-write-lyrics'},
    {id: 13, title: 'Ai Generator', link: '/ai-generator'},
    {id: 14, title: 'Ai Music Generator', link: '/ai-music-generator'},
    {id: 15, title: 'How To Write Music', link: '/how-to-write-music'},
    {id: 16, title: 'Writing Music', link: '/writing-music'},
    {id: 17, title: 'Writing Lyrics', link: '/writing-lyrics'},
    {id: 18, title: 'Writing Songs', link: '/writing-songs'},
    {id: 19, title: 'Songs', link: '/songs'},
    {id: 20, title: 'Chrome Music Lab', link: '/chrome-music-lab'},
    {id: 21, title: 'Best Ai Music Generator', link: '/best-ai-music-generator'},
    {id: 22, title: 'Song Lyric Generator', link: '/song-lyric-generator'},
    {id: 23, title: 'Lyric Generator Free', link: '/lyric-generator-free'},
    {id: 24, title: 'How To Make Music', link: '/how-to-make-music'},
    {id: 25, title: 'Music And Lyrics', link: '/music-and-lyrics'},
    {id: 26, title: 'Song Lyrics Generator', link: '/song-lyrics-generator'},
    {id: 27, title: 'Lyrics Generator', link: '/lyrics-generator'},
    {id: 28, title: 'Rap Lyrics Generator', link: '/rap-lyrics-generator'},
    {id: 29, title: 'Ai Song Lyrics Generator', link: '/ai-song-lyrics-generator'},
    {id: 30, title: 'Freestyle Lyrics Generator', link: '/freestyle-lyrics-generator'},
    {id: 31, title: 'Ai Lyrics Generator Free', link: '/ai-lyrics-generator-free'},
    {id: 32, title: 'Free Music', link: '/free-music'},
    {id: 33, title: 'Relaxing Music', link: '/relaxing-music'},
    {id: 34, title: 'Mp3 Music Download', link: '/mp3-music-download'},
    {id: 35, title: 'Free Music Download', link: '/free-music-download'},
    {id: 36, title: 'Royalty Free Music', link: '/royalty-free-music'},
    {id: 37, title: 'Copyright Free Music', link: '/copyright-free-music'},
    {id: 38, title: 'Music Maker', link: '/music-maker'},
    {id: 39, title: 'Music Lab', link: '/music-lab'},
    {id: 40, title: 'Ai Music Generator Free', link: '/ai-music-generator-free'},
    {id: 41, title: 'Ai Music Generator From Text', link: '/ai-music-generator-from-text'},
    {id: 42, title: 'Random Music Generator', link: '/random-music-generator'},
    {id: 43, title: 'Pop Lyrics Generator', link: '/pop-lyrics-generator'},
    {id: 44, title: 'Ai Song Generator', link: '/ai-song-generator'},
    {id: 45, title: 'Song Generator', link: '/song-generator'},
    {id: 46, title: 'Random Song Generator', link: '/random-song-generator'},
    {id: 47, title: 'Rap Song Generator', link: '/rap-song-generator'},
    {id: 48, title: 'Random Song Generator By Genre', link: '/random-song-generator-by-genre'},
    {id: 49, title: 'Ai Song Generator Free', link: '/ai-song-generator-free'},
    {id: 50, title: 'Ai Cover Song Generator', link: '/ai-cover-song-generator'},
    {id: 51, title: 'Text To Song Generator', link: '/text-to-song-generator'},
    {id: 52, title: 'Song Maker', link: '/song-maker'},
    {id: 53, title: 'Chrome Music Lab Song Maker', link: '/chrome-music-lab-song-maker'},
    {id: 54, title: 'Ai Song Maker', link: '/ai-song-maker'},
    {id: 55, title: 'Online Song Maker', link: '/online-song-maker'},
    {id: 56, title: 'Music Lab Song Maker', link: '/music-lab-song-maker'},
    {id: 57, title: 'Song Maker Lyrics', link: '/song-maker-lyrics'},
    {id: 58, title: 'Techno Music', link: '/techno-music'},
    {id: 59, title: 'Electronic Music Generator', link: '/electronic-music-generator'},
    {id: 60, title: 'Ai Rap Generator', link: '/ai-rap-generator'},
    {id: 61, title: 'Freestyle Rap Generator', link: '/freestyle-rap-generator'},
    {id: 62, title: 'Text To Rap Generator', link: '/text-to-rap-generator'},
    {id: 63, title: 'Rap Maker', link: '/rap-maker'},
    {id: 64, title: 'Rap Maker Online', link: '/rap-maker-online'},
    {id: 65, title: 'Ai Rap Maker', link: '/ai-rap-maker'},
    {id: 66, title: 'Rap Creator', link: '/rap-creator'},
    {id: 67, title: 'Rap Writer', link: '/rap-writer'},
    {id: 68, title: 'Free Beat Maker', link: '/free-beat-maker'},
    {id: 69, title: 'Beat Maker Online', link: '/beat-maker-online'},
    {id: 70, title: 'Online Beat Maker', link: '/online-beat-maker'},
    {id: 71, title: 'Beat Maker Free', link: '/beat-maker-free'},
    {id: 72, title: 'Free Online Beat Maker', link: '/free-online-beat-maker'},
    {id: 73, title: 'Online Beat Maker Free', link: '/online-beat-maker-free'},
    {id: 74, title: 'Beat Maker Online Free', link: '/beat-maker-online-free'},
    {id: 75, title: 'Beat Generator', link: '/beat-generator'},
    {id: 76, title: 'Ai Beat Generator', link: '/ai-beat-generator'},
    {id: 77, title: 'Random Beat Generator', link: '/random-beat-generator'},
    {id: 78, title: 'Beat Creator', link: '/beat-creator'},
    {id: 79, title: 'Beat Making', link: '/beat-making'},
    {id: 80, title: 'Free Beat Making Software', link: '/free-beat-making-software'},
    {id: 81, title: 'Beat Making Software', link: '/beat-making-software'},
    {id: 82, title: 'Best Beat Making Software', link: '/best-beat-making-software'},
    {id: 83, title: 'Best Free Beat Making Software', link: '/best-free-beat-making-software'},
    {id: 84, title: 'Rap Lyrics', link: '/rap-lyrics'},
    {id: 85, title: 'Rap Music', link: '/rap-music'},
    {id: 86, title: 'How To Rap', link: '/how-to-rap'},
    {id: 87, title: 'Rap Beat', link: '/rap-beat'},
    {id: 88, title: 'How To Rap Better', link: '/how-to-rap-better'},
    {id: 89, title: 'How To Rap Fast', link: '/how-to-rap-fast'},
    {id: 90, title: 'How To Rap Freestyle', link: '/how-to-rap-freestyle'},
    {id: 91, title: 'How To Rap Lyrics', link: '/how-to-rap-lyrics'},
    {id: 92, title: 'Free Rap Beats', link: '/free-rap-beats'},
    {id: 93, title: 'Rap Beats Free', link: '/rap-beats-free'},
    {id: 94, title: 'Make Rap Beats', link: '/make-rap-beats'},
    {id: 95, title: 'Freestyle Rap Beats', link: '/freestyle-rap-beats'},
    {id: 96, title: 'Best Rap Beats', link: '/best-rap-beats'},
    {id: 97, title: 'Rap Beat Maker', link: '/rap-beat-maker'},
    {id: 98, title: 'Freestyle Rap Beat', link: '/freestyle-rap-beat'},
    {id: 99, title: 'How To Make A Rap Beat', link: '/how-to-make-a-rap-beat'},
    {id: 100, title: 'Song Lyrics', link: '/song-lyrics'},
    {id: 101, title: 'How To Make Edm Music', link: '/how-to-make-edm-music'},
    {id: 102, title: 'How To Make Techno Music', link: '/how-to-make-techno-music'},
    {id: 103, title: 'Songwriting', link: '/songwriting'},
    {id: 104, title: 'Songwriting Tips', link: '/songwriting-tips'},
    {id: 105, title: 'Songwriting Prompts', link: '/songwriting-prompts'},
    {id: 106, title: 'Songwriting Ai', link: '/songwriting-ai'},
    {id: 108, title: 'Song Writing Generator', link: '/song-writing-generator'},
    {id: 109, title: 'Song Writing Structure', link: '/song-writing-structure'},
    {id: 112, title: 'Song Writing Website', link: '/song-writing-website'},
    {id: 113, title: 'Song Writing Ideas', link: '/song-writing-ideas'},
    {id: 114, title: 'Song Writing Template', link: '/song-writing-template'},
    {id: 116, title: 'Song Writing App', link: '/song-writing-app'},
    {id: 117, title: 'Ai Songwriter', link: '/ai-songwriter'},
    {id: 118, title: 'Singer Songwriter', link: '/singer-songwriter'},
    {id: 119, title: 'American Songwriter', link: '/american-songwriter'},
    {id: 120, title: 'Songwriter Ai', link: '/songwriter-ai'},
    {id: 121, title: 'How To Become A Songwriter', link: '/how-to-become-a-songwriter'},
    {id: 122, title: 'How To Be A Songwriter', link: '/how-to-be-a-songwriter'},
    {id: 123, title: 'What Is A Songwriter', link: '/what-is-a-songwriter'},
    {id: 124, title: 'How To Start Songwriting', link: '/how-to-start-songwriting'},
    {id: 125, title: 'What Is Songwriting', link: '/what-is-songwriting'},
    {id: 126, title: 'How To Get Into Songwriting', link: '/how-to-get-into-songwriting'},
    {id: 127, title: 'How To Learn Songwriting', link: '/how-to-learn-songwriting'},
    {id: 128, title: 'How To Start Writing A Song', link: '/how-to-start-writing-a-song'},
    {id: 129, title: 'How To Start Writing Song Lyrics', link: '/how-to-start-writing-song-lyrics'},
    {id: 130, title: 'How To Begin Writing A Song', link: '/how-to-begin-writing-a-song'},
    {id: 131, title: 'Where To Start When Writing A Song', link: '/where-to-start-when-writing-a-song'},
    {id: 132, title: 'How To Write Songs', link: '/how-to-write-songs'},
    {id: 133, title: 'How To Write Song Lyrics', link: '/how-to-write-song-lyrics'},
    {id: 134, title: 'How To Write Song Lyrics Without Music', link: '/how-to-write-song-lyrics-without-music'},
    {id: 135, title: 'How To Write A Rap Song', link: '/how-to-write-a-rap-song'},
    {id: 136, title: 'How To Write A Love Song', link: '/how-to-write-a-love-song'},
    {id: 137, title: 'How To Write A Song For Beginners', link: '/how-to-write-a-song-for-beginners'},
    {id: 138, title: 'How Do You Write A Song', link: '/how-do-you-write-a-song'},
    {id: 139, title: 'Song Writer Generator', link: '/song-writer-generator'},
    {id: 140, title: 'Song Writer Helper', link: '/song-writer-helper'},
    {id: 141, title: 'Song Writer For Hire', link: '/song-writer-for-hire'},
    {id: 142, title: 'Lyric Maker', link: '/lyric-maker'},
    {id: 143, title: 'Rap Lyric Maker', link: '/rap-lyric-maker'},
    {id: 144, title: 'Ai Lyric Maker', link: '/ai-lyric-maker'},
    {id: 145, title: 'Lyric Maker Ai', link: '/lyric-maker-ai'},
    {id: 146, title: 'Genius Lyric Maker', link: '/genius-lyric-maker'},
    {id: 147, title: 'Rap Lyrics Maker', link: '/rap-lyrics-maker'},
    {id: 148, title: 'Make Music Online Free', link: '/make-music-online-free'},
    {id: 149, title: 'Make Music Cloud', link: '/make-music-cloud'},
    {id: 150, title: 'Free Songwriting App', link: '/free-songwriting-app'},
    {id: 151, title: 'Best Songwriting App', link: '/best-songwriting-app'},
    {id: 152, title: 'Songwriting App Free', link: '/songwriting-app-free'},
    {id: 153, title: 'Ai Songwriting App', link: '/ai-songwriting-app'},
    {id: 154, title: 'Chorus Songwriting App', link: '/chorus-songwriting-app'},
    {id: 155, title: 'Songwriting App Online Free', link: '/songwriting-app-online-free'},
    {id: 156, title: 'Songwriting App Iphone', link: '/songwriting-app-iphone'},
    {id: 157, title: 'Songwriting App Android', link: '/songwriting-app-android'},
    {id: 158, title: 'Compose Ai', link: '/compose-ai'},
    {id: 159, title: 'How To Compose Music', link: '/how-to-compose-music'},
    {id: 160, title: 'How To Compose Music On Computer', link: '/how-to-compose-music-on-computer'},
    {id: 161, title: 'Générateur De Paroles Ai', link: '/générateur-de-paroles-ai'},
    {id: 162, title: 'Paroles D Ai', link: '/paroles-d-ai'},
    {id: 163, title: 'Comment Écrire Une Chanson', link: '/comment-écrire-une-chanson'},
    {id: 164, title: 'Comment Écrire Les Paroles D Une Chanson', link: '/comment-écrire-les-paroles-d-une-chanson'},
    {id: 165, title: 'Auteur Compositeur Interprète', link: '/auteur-compositeur-interprète'},
    {id: 166, title: 'Comment Écrire Des Paroles', link: '/comment-écrire-des-paroles'},
    {id: 167, title: 'Générateur D Ia', link: '/générateur-d-ia'},
    {id: 168, title: 'Générateur De Musique Ai', link: '/générateur-de-musique-ai'},
    {id: 169, title: 'Générateur De Paroles De Rap', link: '/générateur-de-paroles-de-rap'},
    {id: 170, title: 'Comment Écrire De La Musique', link: '/comment-écrire-de-la-musique'},
    {id: 171, title: 'Écrire Des Chansons', link: '/écrire-des-chansons'},
    {id: 172, title: 'Laboratoire De Musique Chromé', link: '/laboratoire-de-musique-chromé'},
    {id: 173, title: 'Meilleur Générateur De Musique Ia', link: '/meilleur-générateur-de-musique-ia'},
    {id: 174, title: 'Générateur De Paroles De Chansons', link: '/générateur-de-paroles-de-chansons'},
    {id: 175, title: 'Générateur De Paroles Gratuit', link: '/générateur-de-paroles-gratuit'},
    {id: 176, title: 'Comment Faire De La Musique', link: '/comment-faire-de-la-musique'},
    {id: 177, title: 'Musique Et Paroles', link: '/musique-et-paroles'},
    {id: 178, title: 'Générateur De Paroles De Chansons Ai', link: '/générateur-de-paroles-de-chansons-ai'},
    {id: 179, title: 'Générateur De Paroles Freestyle', link: '/générateur-de-paroles-freestyle'},
    {id: 180, title: 'Générateur De Paroles Ai Gratuit', link: '/générateur-de-paroles-ai-gratuit'},
    {id: 181, title: 'Musique Gratuite', link: '/musique-gratuite'},
    {id: 182, title: 'Musique Relaxante', link: '/musique-relaxante'},
    {id: 183, title: 'Téléchargement De Musique Mp3', link: '/téléchargement-de-musique-mp3'},
    {id: 184, title: 'Téléchargement De Musique Gratuit', link: '/téléchargement-de-musique-gratuit'},
    {id: 185, title: 'Musique Libre De Droits', link: '/musique-libre-de-droits'},
    {id: 186, title: 'Créateur De Musique', link: '/créateur-de-musique'},
    {id: 187, title: 'Générateur De Musique Ai Gratuit', link: '/générateur-de-musique-ai-gratuit'},
    {id: 188, title: 'Générateur De Musique Ai À Partir De Texte', link: '/générateur-de-musique-ai-à-partir-de-texte'},
    {id: 189, title: 'Meilleure Musique Ia', link: '/meilleure-musique-ia'},
    {id: 190, title: 'Générateur De Musique Aléatoire', link: '/générateur-de-musique-aléatoire'},
    {id: 191, title: 'Générateur De Paroles Pop', link: '/générateur-de-paroles-pop'},
    {id: 192, title: 'Générateur De Chansons Ai', link: '/générateur-de-chansons-ai'},
    {id: 193, title: 'Générateur De Chansons Aléatoires', link: '/générateur-de-chansons-aléatoires'},
    {id: 194, title: 'Générateur De Chansons Rap', link: '/générateur-de-chansons-rap'},
    {id: 195, title: 'Générateur De Chansons Aléatoires Par Genre', link: '/générateur-de-chansons-aléatoires-par-genre'},
    {id: 196, title: 'Générateur De Chansons Ai Gratuit', link: '/générateur-de-chansons-ai-gratuit'},
    {id: 197, title: 'Générateur De Chanson De Reprise Ai', link: '/générateur-de-chanson-de-reprise-ai'},
    {id: 198, title: 'Générateur De Texte En Chanson', link: '/générateur-de-texte-en-chanson'},
    {id: 199, title: 'Créateur De Chansons', link: '/créateur-de-chansons'},
    {id: 200, title: 'Créateur De Chansons De Laboratoire De Musique Chromé', link: '/créateur-de-chansons-de-laboratoire-de-musique-chromé'},
    {id: 201, title: 'Créateur De Chansons Ai', link: '/créateur-de-chansons-ai'},
    {id: 202, title: 'Créateur De Chansons En Ligne', link: '/créateur-de-chansons-en-ligne'},
    {id: 203, title: 'Paroles Du Créateur De Chansons', link: '/paroles-du-créateur-de-chansons'},
    {id: 204, title: 'Musique Techno', link: '/musique-techno'},
    {id: 205, title: 'Générateur De Musique Électronique', link: '/générateur-de-musique-électronique'},
    {id: 206, title: 'Générateur De Rap', link: '/générateur-de-rap'},
    {id: 207, title: 'Générateur De Rap Ai', link: '/générateur-de-rap-ai'},
    {id: 208, title: 'Générateur De Rap Freestyle', link: '/générateur-de-rap-freestyle'},
    {id: 209, title: 'Générateur De Texte En Rap', link: '/générateur-de-texte-en-rap'},
    {id: 210, title: 'Ai-Textgenerator', link: '/ai-textgenerator'},
    {id: 211, title: 'Ai-Texte', link: '/ai-texte'},
    {id: 212, title: 'Wie Man Ein Lied Schreibt', link: '/wie-man-ein-lied-schreibt'},
    {id: 213, title: 'Wie Man Einen Liedtext Schreibt', link: '/wie-man-einen-liedtext-schreibt'},
    {id: 214, title: 'Ki-Songwriter', link: '/ki-songwriter'},
    {id: 215, title: 'Wie Man Liedtexte Schreibt', link: '/wie-man-liedtexte-schreibt'},
    {id: 216, title: 'Lyrikgenerator', link: '/lyrikgenerator'},
    {id: 217, title: 'Ki-Generator', link: '/ki-generator'},
    {id: 218, title: 'Ki-Musikgenerator', link: '/ki-musikgenerator'},
    {id: 219, title: 'Wie Man Musik Schreibt', link: '/wie-man-musik-schreibt'},
    {id: 220, title: 'Musik Schreiben', link: '/musik-schreiben'},
    {id: 221, title: 'Songtexte Schreiben', link: '/songtexte-schreiben'},
    {id: 222, title: 'Lieder Schreiben', link: '/lieder-schreiben'},
    {id: 223, title: 'Chrom-Musiklabor', link: '/chrom-musiklabor'},
    {id: 224, title: 'Bester Ki-Musikgenerator', link: '/bester-ki-musikgenerator'},
    {id: 225, title: 'Songtext-Generator', link: '/songtext-generator'},
    {id: 226, title: 'Textgenerator Kostenlos', link: '/textgenerator-kostenlos'},
    {id: 227, title: 'Wie Man Musik Macht', link: '/wie-man-musik-macht'},
    {id: 228, title: 'Musik Und Texte', link: '/musik-und-texte'},
    {id: 229, title: 'Liedtextgenerator', link: '/liedtextgenerator'},
    {id: 230, title: 'Rap-Textgenerator', link: '/rap-textgenerator'},
    {id: 231, title: 'Ai Songtext-Generator', link: '/ai-songtext-generator'},
    {id: 232, title: 'Freestyle-Textgenerator', link: '/freestyle-textgenerator'},
    {id: 233, title: 'Ai-Textgenerator Kostenlos', link: '/ai-textgenerator-kostenlos'},
    {id: 234, title: 'Kostenlose Musik', link: '/kostenlose-musik'},
    {id: 235, title: 'Entspannende Musik', link: '/entspannende-musik'},
    {id: 236, title: 'Mp3-Musik Herunterladen', link: '/mp3-musik-herunterladen'},
    {id: 237, title: 'Kostenloser Musik-Download', link: '/kostenloser-musik-download'},
    {id: 238, title: 'Gemafreie Musik', link: '/gemafreie-musik'},
    {id: 239, title: 'Urheberrechtsfreie Musik', link: '/urheberrechtsfreie-musik'},
    {id: 240, title: 'Musikmacher', link: '/musikmacher'},
    {id: 241, title: 'Ai-Musikgenerator Kostenlos', link: '/ai-musikgenerator-kostenlos'},
    {id: 242, title: 'Ai-Musikgenerator Aus Text', link: '/ai-musikgenerator-aus-text'},
    {id: 243, title: 'Beste Ki-Musik', link: '/beste-ki-musik'},
    {id: 244, title: 'Zufallsgenerator Für Musik', link: '/zufallsgenerator-für-musik'},
    {id: 245, title: 'Pop-Textgenerator', link: '/pop-textgenerator'},
    {id: 246, title: 'Ai-Songgenerator', link: '/ai-songgenerator-de'},
    {id: 247, title: 'Zufallsgenerator Für Lieder', link: '/zufallsgenerator-für-lieder'},
    {id: 248, title: 'Zufallsgenerator Für Lieder Nach Genre', link: '/zufallsgenerator-für-lieder-nach-genre'},
    {id: 249, title: 'Ai-Song-Generator Kostenlos', link: '/ai-song-generator-kostenlos'},
    {id: 250, title: 'Ai-Coversong-Generator', link: '/ai-coversong-generator'},
    {id: 251, title: 'Text-Zu-Song-Generator', link: '/text-zu-song-generator'},
    {id: 252, title: 'Liedermacher', link: '/liedermacher'},
    {id: 253, title: 'Ki-Songmacher', link: '/ki-songmacher'},
    {id: 254, title: 'Online-Songmacher', link: '/online-songmacher'},
    {id: 255, title: 'Musiklabor-Songmacher', link: '/musiklabor-songmacher'},
    {id: 256, title: 'Liedtexte Des Songwriters', link: '/liedtexte-des-songwriters'},
    {id: 257, title: 'Technomusik', link: '/technomusik'},
    {id: 258, title: 'Generator Für Elektronische Musik', link: '/generator-für-elektronische-musik'},
    {id: 259, title: 'Text-Zu-Rap-Generator', link: '/text-zu-rap-generator'},
    {id: 260, title: 'Rap-Macher', link: '/rap-macher'},
    {id: 261, title: 'Rap-Hersteller Online', link: '/rap-hersteller-online'},
    {id: 262, title: 'Ki-Rap-Hersteller', link: '/ki-rap-hersteller'},
    {id: 263, title: 'Rap-Schöpfer', link: '/rap-schöpfer'},
    {id: 264, title: 'Rap-Autor', link: '/rap-autor'},
    {id: 265, title: 'Beatmaker', link: '/beatmaker-de'},
    {id: 266, title: 'Kostenloser Beat Maker', link: '/kostenloser-beat-maker'},
    {id: 267, title: 'Beat Maker Kostenlos', link: '/beat-maker-kostenlos'},
    {id: 268, title: 'Kostenloser Online-Beat-Maker', link: '/kostenloser-online-beat-maker'},
    {id: 269, title: 'Beat Maker Online Kostenlos', link: '/beat-maker-online-kostenlos'},
    {id: 270, title: 'Zufallsgenerator Für Beats', link: '/zufallsgenerator-für-beats'},
    {id: 271, title: 'Beat-Schöpfer', link: '/beat-schöpfer'},
    {id: 272, title: 'Beatmachen', link: '/beatmachen'},
    {id: 273, title: 'Kostenlose Beat-Making-Software', link: '/kostenlose-beat-making-software'},
    {id: 274, title: 'Beste Beat-Making-Software', link: '/beste-beat-making-software'},
    {id: 275, title: 'Beste Kostenlose Beat-Making-Software', link: '/beste-kostenlose-beat-making-software'},
    {id: 276, title: 'Rap-Texte', link: '/rap-texte'},
    {id: 277, title: 'Rap-Musik', link: '/rap-musik'},
    {id: 278, title: 'Wie Man Rappt', link: '/wie-man-rappt'},
    {id: 279, title: 'Wie Man Besser Rappt', link: '/wie-man-besser-rappt'},
    {id: 280, title: 'Wie Man Schnell Rappt', link: '/wie-man-schnell-rappt'},
    {id: 281, title: 'Wie Man Freestyle Rappt', link: '/wie-man-freestyle-rappt'},
    {id: 282, title: 'Wie Man Texte Rappt', link: '/wie-man-texte-rappt'},
    {id: 283, title: 'Kostenlose Rap-Beats', link: '/kostenlose-rap-beats'},
    {id: 284, title: 'Rap Schlägt Kostenlos', link: '/rap-schlägt-kostenlos'},
    {id: 285, title: 'Rap-Beats Machen', link: '/rap-beats-machen'},
    {id: 286, title: 'Beste Rap-Beats', link: '/beste-rap-beats'},
    {id: 287, title: 'Wie Man Einen Rap-Beat Macht', link: '/wie-man-einen-rap-beat-macht'},
    {id: 288, title: 'Wie Man Edm-Musik Macht', link: '/wie-man-edm-musik-macht'},
    {id: 289, title: 'Wie Man Technomusik Macht', link: '/wie-man-technomusik-macht'},
    {id: 290, title: 'Songwriting-Tipps', link: '/songwriting-tipps'},
    {id: 291, title: 'Anregungen Zum Songwriting', link: '/anregungen-zum-songwriting'},
    {id: 292, title: 'Songwriting-Ki', link: '/songwriting-ki'},
    {id: 293, title: 'Songwriting-Generator', link: '/songwriting-generator-de'},
    {id: 294, title: 'Songwriting-Struktur', link: '/songwriting-struktur'},
    {id: 295, title: 'Tipps Zum Songwriting', link: '/tipps-zum-songwriting'},
    {id: 296, title: 'Songwriting-Website', link: '/songwriting-website-de'},
    {id: 297, title: 'Songwriting-Ideen', link: '/songwriting-ideen'},
    {id: 298, title: 'Songwriting-Vorlage', link: '/songwriting-vorlage'},
    {id: 299, title: 'Amerikanischer Songwriter', link: '/amerikanischer-songwriter'},
    {id: 300, title: 'Wie Man Songwriter Wird', link: '/wie-man-songwriter-wird'},
    {id: 301, title: 'Wie Man Ein Songwriter Wird', link: '/wie-man-ein-songwriter-wird'},
    {id: 302, title: 'Was Ist Ein Songwriter?', link: '/was-ist-ein-songwriter?'},
    {id: 303, title: 'Wie Man Mit Dem Songwriting Beginnt', link: '/wie-man-mit-dem-songwriting-beginnt'},
    {id: 304, title: 'Was Ist Songwriting?', link: '/was-ist-songwriting?'},
    {id: 305, title: 'Wie Man Zum Songwriting Kommt', link: '/wie-man-zum-songwriting-kommt'},
    {id: 306, title: 'Wie Man Songwriting Lernt', link: '/wie-man-songwriting-lernt'},
    {id: 307, title: 'Wie Man Anfängt, Ein Lied Zu Schreiben', link: '/wie-man-anfängt,-ein-lied-zu-schreiben'},
    {id: 308, title: 'Wie Man Anfängt, Liedtexte Zu Schreiben', link: '/wie-man-anfängt,-liedtexte-zu-schreiben'},
    {id: 309, title: 'Wo Fange Ich An, Wenn Ich Einen Song Schreibe?', link: '/wo-fange-ich-an,-wenn-ich-einen-song-schreibe?'},
    {id: 310, title: 'Wie Man Lieder Schreibt', link: '/wie-man-lieder-schreibt'},
    {id: 311, title: 'Wie Schreibe Ich Songtexte Ohne Musik?', link: '/wie-schreibe-ich-songtexte-ohne-musik?'},
    {id: 312, title: 'Wie Man Einen Rap-Song Schreibt', link: '/wie-man-einen-rap-song-schreibt'},
    {id: 313, title: 'Wie Man Ein Liebeslied Schreibt', link: '/wie-man-ein-liebeslied-schreibt'},
    {id: 314, title: 'Wie Man Ein Lied Für Anfänger Schreibt', link: '/wie-man-ein-lied-für-anfänger-schreibt'},
    {id: 315, title: 'Wie Schreibt Man Ein Lied?', link: '/wie-schreibt-man-ein-lied?'},
    {id: 316, title: 'Songwriter-Generator', link: '/songwriter-generator'},
    {id: 317, title: 'Songwriter-Helfer', link: '/songwriter-helfer'},
    {id: 318, title: 'Songwriter Zu Mieten', link: '/songwriter-zu-mieten'},
    {id: 319, title: 'Lyriker', link: '/lyriker'},
    {id: 320, title: 'Rap-Lyriker', link: '/rap-lyriker'},
    {id: 321, title: 'Ai-Lyriker', link: '/ai-lyriker'},
    {id: 322, title: 'Textersteller Ai', link: '/textersteller-ai'},
    {id: 323, title: 'Genialer Lyriker', link: '/genialer-lyriker'},
    {id: 324, title: 'Rap-Textersteller', link: '/rap-textersteller'},
    {id: 325, title: 'Ai歌詞ジェネレーター', link: '/ai歌詞ジェネレーター-ja'},
    {id: 326, title: 'あい歌詞', link: '/あい歌詞-ja'},
    {id: 327, title: '曲の書き方', link: '/曲の書き方-ja'},
    {id: 328, title: '歌の歌詞の書き方', link: '/歌の歌詞の書き方-ja'},
    {id: 329, title: 'アイソングライター', link: '/アイソングライター-ja'},
    {id: 330, title: 'Aiジェネレーター', link: '/aiジェネレーター-ja'},
    {id: 331, title: '音楽の書き方', link: '/音楽の書き方-ja'},
    {id: 332, title: '音楽を書く', link: '/音楽を書く-ja'},
    {id: 333, title: '歌詞を書く', link: '/歌詞を書く-ja'},
    {id: 334, title: '曲を書く', link: '/曲を書く-ja'},
    {id: 335, title: '歌', link: '/歌-ja'},
    {id: 336, title: 'クロムミュージックラボ', link: '/クロムミュージックラボ-ja'},
    {id: 337, title: '最高のAi音楽ジェネレーター', link: '/最高のai音楽ジェネレーター-ja'},
    {id: 338, title: '歌詞ジェネレーター無料', link: '/歌詞ジェネレーター無料-ja'},
    {id: 339, title: '音楽の作り方', link: '/音楽の作り方-ja'},
];

const Landings = () => {
    return (
        <div className="container">
            <h1 className="">Landings</h1>
            <ul className="list-group">
                {articlesData.map((article) => (
                    <li className="list-group-item" key={article.id}>
                        <h2><a href={article.link}>{article.title}</a></h2>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Landings;