import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 歌Ja() {
const title = "歌";
const keywords = "歌詞, 音楽ジェネレーター, 音楽, 曲作り, 曲作りアシスタント, 曲作りアプリ, 曲作りプラットフォーム, 曲作りソフトウェア, 曲作りツール, 曲作りツール, 曲作りウェブサイト, 歌の作り方";
const description = "AIを使って歌を作る方法を学びましょう";
const longDescription = "Lyrical Labsは、AIを使用してより速く歌詞と音楽を書くための曲作りプラットフォームです。あなたの個人的な趣味や曲作りのニーズに合わせた個人的な曲作りアシスタントです。";
const h1 = "AIの力を使って歌の作り方を学びましょう。";
const h2 = "AI駆動の歌詞であなたの曲作りを革命的に変え、無限のインスピレーションを得ましょう。";
const sectionHeader = "究極の曲作りの伴侶";
const featureTitle1 = "限界なき創造力";
const featureTitle2 = "あなたの個人的な曲作りアシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どの言語でも書ける";
const featureParagraph1 = "オリジナルのアイデアやメロディが絶え間なく提供されるため、無尽蔵の曲作りのインスピレーションが待っています。AIはどんなトピックについても書くことができ、任意のジャンルの音楽を生成できます";
const featureParagraph2 = "AI曲作りパートナーと協力して完璧な歌を作りましょう。歌詞と音楽が一度に手に入れば、AIにフィードバックを与えて音楽的ビジョンを実現できます。";
const featureParagraph3 = "プラットフォームには、インスピレーションを提供し、歌詞作成を助けるさまざまな関連ワードを見つけるための内蔵スマート辞書が装備されています。";
const featureParagraph4 = "100以上の言語から選ぶことができるので、どの言語でも歌詞を書く方法を学べます。";
const faq1 = "著作権は私が保持できますか？";
const faq2 = "このプラットフォームの目的は私のために曲を完全に作ることですか？";
const faq3 = "このプラットフォームはどのようにして作家のブロックを克服するのを助けますか？";
const faq4 = "このプラットフォームはどのようにして私の曲作りプロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100%ロイヤリティーフリーなので、このプラットフォームで作成した音楽や歌詞の全ての権利を保持できます。";
const faqa2 = "いいえ、私たちのプラットフォームの目的は無限のインスピレーションを提供し、作家のブロックを克服する手助けをすることです。AIは完全な歌詞セットと音楽的メロディを生成する能力を持っていますが、それらをスタートポイントとして使用し、あなた自身の個人的なタッチを加えることを推奨します。AIはあなたを支援し、インスパイアするためにここにあります、あなたの創造性を置き換えるためではありません。";
const faqa3 = "作家のブロックは、どの曲作り人にとってもストレスの多い絶望的な経験です。しかし、私たちのプラットフォームを使用すれば、アイデアが尽きることはありません。希望のテーマとスタイルを入力するだけで、AIが創造的で新しいアイデアの絶え間ない流れを提供します。これはあなたの曲作りプロセスを始動させ、軌道に戻す手助けになります。";
const faqa4a = "私たちのプラットフォームは、選択したスタイリスティックな好みに基づいてアイデアと提案を無限に提供するよう設計されています。これにより、実験して新しいことを試みる自由を得ることができます。";
const faqa4b = "さらに、プラットフォームでは独自の「関連ワード」辞書を横に置きながら、自分の意志で歌詞を書いたり編集したりすることができます。まるであなた自身の個人的な創造アシスタントのようで、あなたの個人的な趣味や曲作りのニーズに適合します。";
const quote1 = "これは画期的なもので、私が初めて歌を書くのを本当に助けてくれました";
const quote2 = "素晴らしい製品で、歌の作り方を学びたい人にはお勧めします";
const quote3 = "優れた顧客サービスで、歌の作り方を学ぶ新しい方法で本当にクールです";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
