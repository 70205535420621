import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function SongwritingGerman() {
const title = "Songwriting";
const keywords = "Songwriting, Songtext-Generator, Musikgenerator, Liederschreiben, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Songwriting-Website";
const description = "Lernen Sie, mit KI Songwriting zu betreiben";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um Ihnen schneller beim Schreiben von Songtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, zugeschnitten auf Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie Songwriting mit der Kraft der KI.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unseren KI-gesteuerten Texten.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Unendliche Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu schaffen. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und Ihnen helfen, Ihre Texte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Sätze von Texten und musikalischen Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu nutzen und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen mehr zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess in Gang bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen einen endlosen Vorrat an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie während Ihres gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, abgestimmt auf Ihre persönlichen Geschmacksrichtungen und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
