import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function KiSongwriter() {
const title = "Ki-Songwriter";
const keywords = "KI-Songwriter, KI-Lyrikerzeuger, KI-Musikerzeuger, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Werkzeug, Songwriting-Tools, Lieder schreiben";
const description = "Lernen Sie, wie man mit KI Songs schreibt";
const longDescription = "KI-Songwriter ist eine Songwriting-Plattform, die KI nutzt, um Ihnen beim schnelleren Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, der auf Ihre persönlichen Geschmäcke und Bedürfnisse zugeschnitten ist!";
const h1 = "Lernen Sie, wie man Songs mithilfe der Kraft der KI schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserem KI-betriebenen Lyrik- und Melodiengenerator.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Eingebautes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Unendliche Songwriting-Inspiration erwartet Sie mit einer niemals endenden Versorgung mit originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um den perfekten Song zu erschaffen. Sobald Sie Texte und Musik haben, können Sie Feedback geben, indem Sie mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem eingebauten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform dabei, meinen Songwriting-Prozess zu verbessern?";
const faqa1 = "Ja! KI-Songwriter ist 100% royaltyfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Text- und Musikmelodien zu erzeugen, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen mehr zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht es Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Songwriting-Prozesses unterstützt. Es ist wie Ihr eigener persönlicher kreativer Assistent, der auf Ihre eigenen persönlichen Geschmäcke und Bedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Songs schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Songschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
