import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusikSchreiben() {
const title = "Musik Schreiben";
const keywords = "Liedtexte generieren, Musikmelodien schaffen, Musik schreiben, Musikgenerator, Textgenerator, Liederschreiben, Liederschreibungsassistent, Liederschreibungssoftware, Liederschreibungswerkzeug, Liederschreibungsplattform, Musik schreiben lernen";
const description = "Lerne Musik schreiben mit KI";
const longDescription = "Lyrical Labs ist eine Plattform zum Schreiben von Liedern, die KI nutzt, um Ihnen beim schnelleren Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Liedschreibungsassistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Bedürfnisse beim Liederschreiben!";
const h1 = "Lerne Musik schreiben mit der Kraft der KI.";
const h2 = "Revolutioniere dein Liederschreiben und erhalte unendliche Inspiration mit unseren KI-gesteuerten Texten.";
const sectionHeader = "Der ultimative Begleiter zum Liederschreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Liedschreibungsassistent";
const featureTitle3 = "Integriertes smartes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration zum Liederschreiben erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art erstellen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Liederschreibungspartner zusammen, um das perfekte Lied zu gestalten. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Ihr musikalisches Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten smarten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Liederschreibungsprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, daher behalten Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI komplette Liedtexte und musikalische Melodien generieren kann, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihren eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und demoralisierende Erfahrung für jeden Liedschreiber sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI liefert Ihnen einen endlosen Strom von kreativen und originellen Ideen, mit denen Sie arbeiten können. Dies kann Ihren Liederschreibungsprozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Menge an Ideen und Vorschlägen basierend auf Ihren stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandten Wörter'-Wörterbuch an Ihrer Seite, das Sie während Ihres gesamten Liederschreibungsprozesses unterstützt. Es ist wie Ihr persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmacks- und Liederschreibungsbedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, Musik zu schreiben";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, Musik schreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
