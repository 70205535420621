import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WieManEinSongwriterWird() {
const title = "Wie Man Ein Songwriter Wird";
const keywords = "Liedtexte, Musikgenerator, Musik, Songwriting, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Songwriter wird";
const description = "Erfahre, wie man mit Hilfe von KI ein Songwriter wird";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI verwendet, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, zugeschnitten auf deinen eigenen Geschmack und deine Songwriting-Bedürfnisse!";
const h1 = "Lerne, wie man ein Songwriter wird, mit der Kraft der KI.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration durch unsere KI-gesteuerten Liedtexte.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes Smart-Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Unendliche Songwriting-Inspiration erwartet dich mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu erschaffen. Sobald du Liedtexte und Musik hast, kannst du Feedback durch Gespräche mit der KI geben, um deine musikalische Vision zu verwirklichen.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten Smart-Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir zu helfen, deine Liedtexte zu vervollständigen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, du behältst also alle Rechte an der Musik und den Liedtexten, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu bieten und den Writer’s Block zu überwinden. Obwohl die KI komplette Sets von Liedtexten und musikalischen Melodien generieren kann, ermutigen wir dich, diese als Ausgangspunkt zu nutzen und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Writer's Block kann eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen unendlichen Strom kreativer und origineller Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Songwriting-Prozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine unendliche Menge an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, was dir die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht es dir die Plattform, die Liedtexte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich während deines gesamten Songwriting-Prozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, zugeschnitten auf deine eigenen persönlichen Bedürfnisse und Geschmäcker!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Songwriter wird";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art zu lernen, wie man ein Songwriter wird";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
