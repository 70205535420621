import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function EntspannendeMusik() {
const title = "Entspannende Musik";
const keywords = "entspannende Musik, Musikgenerator, Lyrikgenerator, Musikkomposition, Musiksoftware, Musikplattform, Songwriting-Hilfe, Musik schreiben, Lieder schreiben, Musikapp";
const description = "Erstellen Sie entspannende Musik mit Hilfe von KI";
const longDescription = "Lyrical Labs ist eine Musikplattform, die KI verwendet, um Ihnen zu helfen, entspannende Musik und Lyrics schneller zu schreiben. Es ist Ihr persönlicher Musikassistent, angepasst an Ihren eigenen persönlichen Geschmack und Ihre Musikbedürfnisse!";
const h1 = "Lerne, entspannende Musik mit der Kraft der KI zu schreiben.";
const h2 = "Revolutionieren Sie Ihre Musikkomposition und erhalten Sie unendliche Inspiration mit unserem KI-betriebenen Lyrics-Generator.";
const sectionHeader = "Der ultimative Begleiter für Musikschaffende";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Musikassistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Schreiben von entspannender Musik erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Musikpartner zusammen, um das perfekte Lied zu komponieren. Sobald Sie Lyrics und Musik haben, können Sie Feedback geben und mit der KI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten integrierten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Worten zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Lyrics zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Musikschreibprozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, so dass Sie alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen, behalten.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu nutzen und Ihre eigene persönliche Note hinzuzufügen. Die KI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Musikschaffenden eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, keine Ideen zu haben. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Musikschreibprozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen einen unendlichen Vorrat an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues zu versuchen.";
const faqa4b = "Darüber hinaus ermöglicht es Ihnen die Plattform, die Texte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hoch einzigartigen 'Verwandte Worte'-Wörterbuch an Ihrer Seite, um Sie während Ihres Musikschreibprozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihren eigenen persönlichen Geschmack und Ihre Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
