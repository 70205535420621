import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function KostenloseBeatMakingSoftware() {
const title = "Kostenlose Beat-Making-Software";
const keywords = "Beats erstellen, Lyrics generieren, Musikgenerator, songwriting, kostenlose Musiksoftware, Beat-Making-App, Beat-Making-Plattform, Beat-Making-Tool, kostenlose Beat-Making-Software";
const description = "Erfahren Sie, wie Sie mit KI kostenlose Beats und Songtexte erstellen können";
const longDescription = "Lyrical Labs ist eine Plattform für das Songwriting, die KI nutzt, um Ihnen zu helfen, schneller Beats und Songtexte zu erstellen. Dies ist Ihr persönlicher Assistent für das Songwriting, angepasst an Ihre persönlichen Geschmacksrichtungen und Bedürfnisse beim Songwriting!";
const h1 = "Erfahren Sie, wie Sie mit der Kraft der KI kostenlose Beats und Songtexte erstellen können.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserem KI-basierten Lyrics-Generator.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Songwriting erwartet Sie mit einem nie endenden Angebot an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeden Genres generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um den perfekten Beat und Songtext zu erstellen. Sobald Sie Lyrics und Musik haben, können Sie durch Gespräche mit der KI Feedback geben, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen dabei hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Songtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu beschleunigen?";
const faqa1 = "Ja! Lyrical Labs ist zu 100% urheberrechtsfrei, sodass Sie alle Rechte an der Musik und den Songtexten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und einen Schreibblock zu überwinden. Während die KI in der Lage ist, komplette Songtexte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom kreativer und origineller Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine unendliche Menge an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Darüber hinaus ermöglicht Ihnen die Plattform, die Songtexte nach eigenem Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Worte'-Wörterbuch an Ihrer Seite, das Ihnen während Ihres Songwriting-Prozesses hilft. Es ist wie Ihr persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmäcks- und Songwriting-Bedürfnisse!";
const quote1 = "Dies ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man einen Song schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Songschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
