import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import WaveSurfer from 'wavesurfer.js';
import useLocalStorage from "../../hooks/useLocalStorage";
import AudioWaveform from '../../components/AudioWaveform';
import styles from "../Homescreen/Homescreen.module.css";
import { Button, Tooltip, Container, Grid } from "@mui/material";
import Footer from '../../components/Footer';
import Modal from "../../components/Modal/Modal";
import ShareModal from "../../components/ShareModal/ShareModal";
import { useNavigate } from "react-router-dom";



export default function MusicPlayer({ user, setUser, ipAddress, setIsAlert, setAlertMessage, setAlertSeverity }) {
  const [formValues, setFormValues] = useState({
    timeSignature: '4/4',
    bpm: '80',
    prompt: ''
  });
  const [audioLinks, setAudioLinks] = useState([]);
  const [audioLink1, setAudioLink1] = useState('');
  const [audioLink2, setAudioLink2] = useState('');
  const [audioLink3, setAudioLink3] = useState('');
  const [audioLink4, setAudioLink4] = useState('');
  const [showWaveforms, setShowWaveforms] = useState({});
  const [newlySignedUrls, setNewlySignedUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [musicHistory, setMusicHistory] = useLocalStorage('musicHistory', '');
  const [generating, setGenerating] = useLocalStorage('generating', '');
  const waveSurferRefs = useRef([]);

  const navigate = useNavigate();

  const url = 'https://frozen-springs-61240.herokuapp.com';
  //const url = 'http://localhost:5001';

  
  // useEffect(() => {
  //   // Cleanup waveforms on component unmount
  //   return () => {
  //     waveSurferRefs.current.forEach(wavesurfer => {
  //       if (wavesurfer) {
  //         wavesurfer.destroy();
  //       }
  //     });
  //   };
  // }, []);

  useEffect(() => {
    // setIsAlert(true);
    // setAlertMessage("We have fixed the issues with the music player, please report any issues in the chatbox.");
    // setAlertSeverity("success");
    async function getMusicIfGeneration() {
      if (generating) {
        setLoading(true);
        setFormValues({ prompt: generating.prompt, bpm: generating.bpm, timeSignature: generating.timeSignature })
        await makeRequest();
        setLoading(false);
      }
    }
    getMusicIfGeneration();
  }, []);

  const handleModalOpen = () => {
    setModalIsOpen(true);
  }

  const handleModalClose = () => {
    setModalIsOpen(false);
  }

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('modal-container')) {
      handleModalClose();
    }
  }

  async function handleGetMusic(history, index) {

    try {
      const originalUrls = history.signedUrls;

      setNewlySignedUrls([
        ...newlySignedUrls,
        { index: index, urls: originalUrls },
      ]);

      // Toggle showWaveforms state for the specific index
      setShowWaveforms((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

    } catch (error) {
      console.error('Error fetching music:', error);
    }
  }

  async function makeRequest() {
    let response2;
    setErrorMessage('');
    try {
      let data;
      if (user) {
        data = { ...formValues, user };
      } else {
        data = { ...formValues, ipAddress };
      }
      console.log(`Generating is: ${generating}`)
      let formValuesForHistory = formValues.prompt? { prompt: formValues.prompt, bpm: formValues.bpm, timeSignature: formValues.timeSignature }: { prompt: generating.prompt, bpm: generating.bpm, timeSignature: generating.timeSignature };
      console.log(formValues);
      setAudioLinks([]);
      setAudioLink1('');
      setAudioLink2('');
      setAudioLink3('');
      setAudioLink4('');
      let link1set;
      let link2set;
      let link3set;
      let link4set;
      let response;
      let response2;
      let response3;
      let responseData;
      if (!generating) {
        try {
          const responsePromise =  axios.post(`${url}/generateMusic`, data);

          const response2Promise = axios.post(`${url}/generateMusic`, data);

          // const response3Promise = axios.post(`${url}/generateMusic`, data);

          response = await Promise.resolve(responsePromise);
          console.log(response);
          // setAudioLink1(response.data.output);
          response2 = await Promise.resolve(response2Promise);
          // setAudioLink2(response2.data.output);
          // response3 = await Promise.resolve(response3Promise);
          // setAudioLink3(response3.data.output);
          responseData = {signedUrls: [response.data.output, response2.data.output]};




          
        } catch (error) {
          console.error('Error generating music:', error);
          console.log('Error generating music:', error.response.data.error);
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage(error.response.data.error);
          setErrorMessage("Error generating music, please try again");
          //if payment is required open modal
          if (error?.response?.status === 402){
            if(!user) {
              setAlertSeverity("error");
              setIsAlert(true);
              setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
              navigate('/register');
            } else {
              handleModalOpen();
            }
          }
          setLoading(false);
          return;
        }

        setGenerating({ data: responseData, prompt: formValues.prompt, bpm: formValues.bpm, timeSignature: formValues.timeSignature });
        console.log(`Generating: ${generating}`);
      }
      while (true) {
        try {

          console.log(`generating: ${generating}`);
          let getMusicData;
          if (user) {
            getMusicData = generating ? { ...generating.data, prompt: generating.prompt, user } : { ...responseData, prompt: formValues.prompt, user };
          } else {
            getMusicData = generating ? { ...generating.data, prompt: generating.prompt, ipAddress } : { ...responseData, prompt: formValues.prompt, ipAddress };
          }
          response2 = await axios.post(`${url}/getMusic`, getMusicData);
          console.log(response2.status);

          //check if that is returning 503 as expected
          if (response2.status === 202 && (!response2.data || !response2.data.signedUrls)) {
            console.log('Music not ready yet');
            //20 second sleep
            await new Promise(r => setTimeout(r, 10000));

            continue;
          } else if (response2.status === 202 && (response2.data && response2.data.signedUrls)) {
            console.log('Music not ready yet');
            for (let i = 0; i < response2.data.signedUrls.length; i++) {
              if (response2.data.signedUrls[i] !== audioLinks[i]) {
                setAudioLinks([...audioLinks, response2.data.signedUrls[i]]);
              }
              let link1 = audioLink1;
              let link2 = audioLink2;
              let link3 = audioLink3;
              let link4 = audioLink4;

              if (i === 0 && response2.data.signedUrls[i] !== link1 && !link1set) {
                console.log(`Audio link 1: ${audioLink1}`);
                console.log(`Setting audio link 1 to ${response2.data.signedUrls[i]}`);
                console.log(`link1: ${link1}`);
                setAudioLink1(response2.data.signedUrls[i]);
                link1set = true;
              }
              if (i === 1 && response2.data.signedUrls[i] !== link2 && !link2set) {
                setAudioLink2(response2.data.signedUrls[i]);
                link2set = true;
              }
              if (i === 2 && response2.data.signedUrls[i] !== link3 && !link3set) {
                setAudioLink3(response2.data.signedUrls[i]);
                link3set = true;
              }
              if (i === 3 && response2.data.signedUrls[i] !== link4 && !link4set) {
                setAudioLink4(response2.data.signedUrls[i]);
                link4set = true;

              }
            }

            await new Promise(r => setTimeout(r, 10000));
            continue;
          } else if (response2.status === 200) {
            console.log('Music ready!');
            console.log(formValues);
            setGenerating('')
            break;
          } else {
            console.error('Error getting music:', response2);
            setGenerating('')
            setLoading(false);
            return;
          }
        } catch (error) {
          setGenerating('')
          console.error('Error getting music:', error);
          setErrorMessage('Error fetching music, please try again');
          setLoading(false);
          return;
        }
      }
      // const responseArray = [];
      // //TODO: add 4 results
      // responseArray.push(response2.data.signedUrl);
      console.log("right before audio links");
      console.log(`Response: ${response2.data.signedUrls}`)
      response2.data.signedUrls.forEach((url, index) => {
        if (url !== audioLinks[index]) {
          setAudioLinks([...audioLinks, url]); // Assuming the response has an array of links
        }
        if (index === 0 && url !== audioLink1 && !link1set) {
          setAudioLink1(url);
          link1set = true;
        }
        if (index === 1 && url !== audioLink2 && !link2set) {
          setAudioLink2(url);
          link2set = true;
        }
        if (index === 2 && url !== audioLink3 && !link3set) {
          setAudioLink3(url);
          link3set = true;
        }
        if (index === 3 && url !== audioLink4 && !link4set) {
          setAudioLink4(url);
          link4set = true;
        }
      });
      console.log(JSON.stringify(formValues));
      setMusicHistory([{ prompt: formValuesForHistory.prompt, bpm: formValuesForHistory.bpm, timeSignature: formValuesForHistory.timeSignature, signedUrls: response2.data.signedUrls }, ...musicHistory])
    } catch (error) {
      console.error('Error fetching music:', error);
      setErrorMessage('Error fetching music, please try again.');
    }

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await makeRequest();
    } catch (error) {
      setErrorMessage('Error fetching music, please try again.');
      setLoading(false)
      console.error('Error fetching music:', error);
    }
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const initializeWaveform = (index, link) => {
    // if (waveSurferRefs.current[index]) {
    //   waveSurferRefs.current[index].destroy();
    // }

    waveSurferRefs.current[index] = WaveSurfer.create({
      container: `#waveform-${index}`,
      waveColor: 'violet',
      progressColor: 'purple',
      backend: 'WebAudio',
      height: 80,
      barWidth: 2
    });
    waveSurferRefs.current[index].load(link);
    const playButton = document.querySelector(`#play-button-${index}`);
    playButton.addEventListener('click', () => {
      waveSurferRefs.current[index].playPause();
    });
    waveSurferRefs.current[index].on('ready', () => {
      console.log(`WaveSurfer ${index} is ready`);
    });

    waveSurferRefs.current[index].on('play', () => {
      console.log('Playback started');
    });

    waveSurferRefs.current[index].on('pause', () => {
      console.log('Playback paused');
    });

    // const downloadButton = document.createElement('button');
    // downloadButton.innerText = 'Download';
    // downloadButton.addEventListener('click', () => {
    //   const a = document.createElement('a');
    //   a.href = link;
    //   a.download = `track-${index}.wav`; // You can customize the file name
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // });

    // document.querySelector(`#waveform-container-${index}`).appendChild(downloadButton);

  };

  return (
    <div type="body" name="MusicPlayer" className='w-screen'>
      {modalIsOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <Modal user={user} onClose={handleModalClose} />
        </div>
      )}
      <main className={styles.main}>
        <Container className="content-between min-h-screen" disableGutters={true} maxWidth="full" max="full">
          <div className="row-auto flex-wrap lg:grid lg:grid-cols-8 lg:gap-0 md:ml-24" id="contents">
            <div className="m-5 mx-8 px-5 py-5 rounded-md lg:col-start-1 lg:col-span-2 lg:mr-0 lg:rounded-r-none " id="col1">
              <div type="headers" className=' text-lg'>Compose some music (Beta)</div>
              <h1 className="text-white/50 text-sm">Use the fields below to generate new samples.</h1>
              <form onSubmit={handleSubmit}>
              <label className="pt-0 pb-0 pl-1 m-0 text-slate-300 text-xs" for="prompt">Prompt</label>
              <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"Write what you want the music to sound like. You can choose multiple styles and be as verbose as you like.\n\n Examples: \n lofi hiphop summer smooth \n piano only baroque \n motown groove \n chill electronic pop"}</span>}
                placement="right">
                <input
                  name="prompt"
                  type="text"
                  value={formValues.prompt}
                  onChange={handleInputChange}
                  placeholder="Prompt"
                  required
                />
              </Tooltip>
              <label className="pt-0 pb-0 pl-1 m-0 text-slate-300 text-xs" for="bpm">BPM</label>
                <input
                  name="bpm"
                  type="number"
                  value={formValues.bpm}
                  onChange={handleInputChange}
                  placeholder="BPM"
                  required
                />

                <label className="pt-0 pb-0 pl-1 m-0 text-slate-300 text-xs" for="timeSignature">Time Signature</label>
                <select required id="timeSignature" name="timeSignature" value={formValues.timeSignature} className="col-start-6 col-span-3 rounded-l-none" onChange={handleInputChange}>
                  <option value="4/4">4/4</option>
                  <option value="3/4">3/4</option>
                  <option value="2/4">2/4</option>
                  <option value="2/2">2/2</option>
                  <option value="6/8">6/8</option>
                  <option value="9/8">9/8</option>
                  <option value="12/8">12/8</option>
                </select>




                {loading ? <button type="submit" disabled>Generate (10 credits)</button> : <button type="submit">Generate (10 credits)</button>}
                {errorMessage ? <div style={{color: 'red'}}>{errorMessage}</div> : null}
                <div style={{color: "lightgray"}}>This may take a long time.</div>
                <div style={{color: "lightgray"}}>Please report any issues using the chatbox.</div>

              </form>
            </div>

            <div className="m-5 mx-8 px-5 py-5 rounded-md col-start-3 col-span-6 lg:ml-0 lg:mr-3.5 lg:rounded-l-none" id="col4">
              <div>
                {loading && <div type="headers" className="text-white-600/10">Loading...</div>}
                {audioLink1 && <div type="headers" className="text-white-600/10">Results</div>}

                {audioLink1 && (
                  <AudioWaveform url={audioLink1} />
                )}
                {audioLink2 && (
                  <AudioWaveform url={audioLink2} />
                )}
                {audioLink3 && (
                  <AudioWaveform url={audioLink3} />
                )}
                {audioLink4 && (
                  <AudioWaveform url={audioLink4} />
                )}
                {/* {audioLinks && audioLinks.map((link, index) => (
                            <div key={index}> */}
                {/* <div id={`waveform-${index}`}></div>
                            <div id={`waveform-container-${index}`}></div>
                            <button id={`play-button-${index}`}>Play/Pause</button>
                            <button href={link} id={`download-button-${index}`}>Download</button> */}
                {/* The audio element is still necessary for WaveSurfer to load the track */}
                {/* <audio onLoadedMetadata={() => initializeWaveform(index, link)}>
                              <source src={link} type="audio/wav" />
                            </audio> */}
                {/* <AudioWaveform url={link} />
                          </div>
                          ))} */}
              </div>
              <div className="text-white mb-5 font text-lg font-bold ">Generated Music History</div>
              {musicHistory && musicHistory.map((history, index) => (
                <div className="flex-col bg-white/10 py-2 my-2 px-2 rounded-md" key={index}>
                  <div className="flex justify-between">
                    <div type="headers2">{history.prompt} {history.bpm}</div>
                    <button onClick={() => handleGetMusic(history, index)}>{showWaveforms[index] ? 'Hide Music' : 'Show Music'}</button>
                  </div>
                  {newlySignedUrls.some(obj => obj.index === index) && showWaveforms[index] && history.signedUrls && newlySignedUrls.find(obj => obj.index === index).urls.map((link, index) => (
                    <div key={index}>
                      {/* <div id={`waveform-${index}`}></div>
                                <div id={`waveform-container-${index}`}></div>
                                <button id={`play-button-${index}`}>Play/Pause</button>
                                <button href={link} id={`download-button-${index}`}>Download</button>
                                {/* The audio element is still necessary for WaveSurfer to load the track */}
                      {/* <audio onLoadedMetadata={() => initializeWaveform(index, link)}>
                                  <source src={link} type="audio/wav" />
                                </audio>  */}
                      <AudioWaveform url={link} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </main>
      <Footer/>
    </div>


  );
};