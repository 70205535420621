import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function MusiklaborSongmacher() {
const title = "Musiklabor-Songmacher";
const keywords = "Liedtexte, Musikgenerator, Musik, Liedschreiben, Liedschreib-Assistent, Liedschreib-App, Liedschreib-Plattform, Liedschreib-Software, Liedschreib-Werkzeug, Liedschreib-Tools, Liedschreib-Website, wie man ein Lied schreibt";
const description = "Lerne, wie man ein Lied mit KI schreibt";
const longDescription = "Musiklabor-Songmacher ist eine Plattform zum Schreiben von Liedern, die KI verwendet, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Liedschreib-Assistent, der auf deinen persönlichen Geschmack und deine Liedschreib-Bedürfnisse zugeschnitten ist!";
const h1 = "Lerne, wie man ein Lied mit der Kraft der KI schreibt.";
const h2 = "Revolutioniere dein Liedschreiben und erhalte unendliche Inspiration mit unserer KI-betriebenen Liedtexte.";
const sectionHeader = "Der ultimative Begleiter für das Liedschreiben";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Liedschreib-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration zum Liedschreiben erwartet dich mit einem niemals endenden Vorrat an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-basierten Liedschreibpartner zusammen, um das perfekte Lied zu erstellen. Sobald du Liedtexte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Fertigstellen deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, wie man Liedtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Liedschreibprozess?";
const faqa1 = "Ja! Musiklabor-Songmacher ist 100% urheberrechtsfrei, sodass du alle Rechte an der Musik und den Liedtexten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Während die KI in der Lage ist, vollständige Sets von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu nutzen und deinen eigenen persönlichen Touch hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und demoralisierende Erfahrung für jeden Liedschreiber sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen unendlichen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dadurch kannst du deinen Liedschreibprozess neu starten und wieder auf Kurs kommen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir einen unendlichen Vorrat an Ideen und Vorschlägen basierend auf deinen stilistischen Präferenzen zu bieten und gibt dir die Freiheit, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es dir die Plattform, die Liedtexte nach deinem eigenen Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich während deines Liedschreibprozesses zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, zugeschnitten auf deinen eigenen persönlichen Geschmack und Liedschreib-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
