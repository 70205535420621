import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function GenialerLyriker() {
const title = "Genialer Lyriker";
const keywords = "Lyrikgenerator, Musikgenerator, Songtexte schreiben, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, genialer Lyriker";
const description = "Lernen Sie, wie man mit KI Songtexte und Melodien schreibt";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Bedürfnisse beim Songwriting!";
const h1 = "Erfahren Sie, wie man mit der Kraft der KI Songtexte schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Lyrics.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "In jeder Sprache schreiben";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einer nie endenden Quelle von originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Kollaborieren Sie mit unserem KI-Songwriting-Partner, um das perfekte Lied zu schaffen. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Ihr musikalisches Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, die Inspiration bieten und Ihnen helfen, Ihre Texte zu vervollständigen.";
const featureParagraph4 = "Mit über 100 Sprachen zur Auswahl können Sie lernen, Songtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich die Urheberrechte?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, Sie behalten alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine unendliche Quelle der Inspiration zu bieten und den Writer's Block zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Writer's Block kann eine frustrierende und demotivierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI bietet Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verarbeitung. Dies kann Ihren Songwriting-Prozess in Schwung bringen und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Texte nach Ihrem eigenen Willen zu schreiben und zu bearbeiten, unterstützt durch ein hochgradig einzigartiges 'Verwandte Wörter'-Wörterbuch, das Ihnen während des gesamten Songwriting-Prozesses zur Seite steht. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Bedürfnisse beim Songwriting!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man Lieder schreibt";
const quote3 = "Ausgezeichnete Kundenbetreuung, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
