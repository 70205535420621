import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function WasIstEinSongwriter() {
const title = "Was Ist Ein Songwriter?";
const keywords = "Songwriter, Musikgenerator, Liedtexte, Lieder schreiben, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, Wie werde ich ein Songwriter";
const description = "Erfahre, was ein Songwriter ist und wie du mit KI Lieder schreibst";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Songwriting-Assistent, angepasst an deinen eigenen Geschmack und deine Songwriting-Bedürfnisse!";
const h1 = "Was ist ein Songwriter? Entdecke es mit der Kraft der KI.";
const h2 = "Revolutioniere dein Songwriting und finde endlose Inspiration mit unserem KI-gestützten Liedtextgenerator.";
const sectionHeader = "Dein ultimativer Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet dich mit einem unendlichen Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeite mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald du Liedtexte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und dir beim Fertigstellen deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass du alle Rechte an der Musik und den Liedtexten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir einen endlosen Vorrat an Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Sätze von Liedtexten und musikalischen Melodien zu generieren, ermutigen wir dich, diese als Ausgangspunkt zu verwenden und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Eine Schreibblockade kann eine frustrierende und demoralisierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI versorgt dich mit einem endlosen Strom von kreativen und originellen Ideen. Dies kann deinen Songwriting-Prozess ankurbeln und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform soll dir einen endlosen Vorrat an Ideen und Vorschlägen bieten, basierend auf deinen stilistischen Vorlieben, und dir die Freiheit geben, zu experimentieren und Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht dir die Plattform, die Liedtexte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, um dich durchgehend in deinem Songwriting-Prozess zu unterstützen. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmäcker und Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, zu lernen, wie man ein Lied schreibt";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
