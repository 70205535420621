import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function RapMacher() {
const title = "Rap-Macher";
const keywords = "rap, musikgenerator, rap musik, rap texte, rap-tool, rap plattform, rap software, songwriting für rap, wie man rap macht";
const description = "Lerne, wie man Rap mit KI erstellt";
const longDescription = "Lyrical Labs ist eine Plattform für das Songwriting, die KI nutzt, um dir zu helfen, schneller Rap-Texte und Musik zu schreiben. Es ist dein persönlicher Rap-Assistent, angepasst an deinen eigenen Geschmack und deine Bedürfnisse beim Rap-Machen!";
const h1 = "Lerne, wie man Rap mit der Kraft der KI macht.";
const h2 = "Revolutioniere dein Rap-Songwriting und erhalte unendliche Inspiration mit unserem KI-betriebenen Lyrics-Generator.";
const sectionHeader = "Der ultimative Begleiter für Rap-Songwriting";
const featureTitle1 = "Grenzenlose Kreativität";
const featureTitle2 = "Dein persönlicher Rap-Assistent";
const featureTitle3 = "Intelligenter eingebauter Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Unendliche Inspiration für das Songwriting erwartet dich mit einem nie endenden Vorrat an originellen Ideen und Melodien. Der KI kann über jedes Thema schreiben und Musik jedes Genres generieren";
const featureParagraph2 = "Arbeite mit unserem KI-Rap-Partner zusammen, um den perfekten Rap-Song zu kreieren. Sobald du Texte und Musik hast, kannst du Feedback geben, indem du mit der KI sprichst, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten eingebauten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu liefern und dir beim Vervollständigen deiner Lyrics zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl, kannst du lernen, wie man Rap-Texte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Songs komplett für mich zu schreiben?";
const faq3 = "Wie hilft mir die Plattform dabei, den Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Rap-Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% royalty-frei, so dass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Quelle der Inspiration zu geben und den Schreibblock zu überwinden. Während die KI in der Lage ist, komplette Sets von Lyrics und musikalischen Melodien zu erzeugen, ermutigen wir dich, sie als Ausgangspunkt zu nutzen und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und demotivierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform musst du dir nie Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil an, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Rap-Songwriting-Prozess in Gang bringen und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine unendliche Menge an Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Vorlieben zu bieten, die dir die Freiheit geben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht es dir die Plattform, die Lyrics nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, das dich während deines gesamten Rap-Songwriting-Prozesses unterstützt. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmäcker und Bedürfnisse im Rap-Songwriting!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Rap zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man Rap macht";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, Rap zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
