import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiSongwriterGerman() {
const title = "Ai-Songwriter";
const keywords = "AI-Songwriter, Musikgenerator, Lyrikgenerator, Liederschreiben, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, wie man ein Lied schreibt";
const description = "Lernen Sie mit einem AI-Songwriter Lieder zu schreiben";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die künstliche Intelligenz nutzt, um Ihnen schneller beim Schreiben von Texten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmäcke und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, ein Lied mit der Kraft eines AI-Songwriters zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere AI-gestützten Lyrics.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Der AI kann über jedes Thema schreiben und Musik jeder Genre generieren";
const featureParagraph2 = "Arbeiten Sie mit unserem AI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald Sie Texte und Musik haben, können Sie Feedback geben, indem Sie mit dem AI sprechen, um Ihre musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen, aus denen Sie wählen können, können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft mir die Plattform, einen Schreibblock zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100 % lizenzfrei, Sie behalten also alle Rechte an der Musik und den Texten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle an Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl der AI in der Lage ist, komplette Textsätze und musikalische Melodien zu generieren, empfehlen wir Ihnen, diese als Ausgangspunkt zu nutzen und Ihren eigenen persönlichen Touch hinzuzufügen. Der AI ist hier, um Sie zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und der AI wird Ihnen einen endlosen Strom kreativer und origineller Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Vorlieben zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, unterstützt durch ein hochgradig einzigartiges 'Verwandte Wörter'-Wörterbuch, das Ihnen während des gesamten Songwriting-Prozesses zur Seite steht. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre eigenen persönlichen Geschmäcke und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Hervorragender Kundenservice, wirklich coole Art, das Liederschreiben zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
