import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function Lyrikgenerator() {
const title = "Lyrikgenerator";
const keywords = "Lyrikgenerator, Musikgenerator, Liedtexte erstellen, Songwriting-Software, Songwriting-Tool, Songwriting-Hilfe, Liedtexte generieren, Musik komponieren";
const description = "Lerne, Liedtexte und Melodien mit KI zu erstellen";
const longDescription = "Lyrical Labs ist eine Plattform für das Songwriting, die KI verwendet, um dir schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist dein persönlicher Assistent für Songwriting, angepasst an deinen persönlichen Geschmack und deine Bedürfnisse beim Songwriting!";
const h1 = "Lerne, Lieder mit Hilfe von KI zu schreiben.";
const h2 = "Revolutioniere dein Songwriting und erhalte unendliche Inspiration durch unsere KI-gesteuerten Texte.";
const sectionHeader = "Der ultimative Begleiter fürs Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Dein persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreibe in jeder Sprache";
const featureParagraph1 = "Endlose Inspiration für das Songwriting erwartet dich mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Art generieren";
const featureParagraph2 = "Kollaboriere mit unserem KI-Songwriting-Partner, um das perfekte Lied zu schaffen. Sobald du Texte und Musik hast, kannst du durch das Gespräch mit der KI Feedback geben, um deine musikalische Vision zum Leben zu erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das dir hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu geben und dir beim Beenden deiner Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl kannst du lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, den Writer's Block zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtlich unbedenklich, so dass du alle Rechte an der Musik und den Texten behältst, die du auf dieser Plattform erstellst.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, dir eine unendliche Inspirationsquelle zu bieten und den Writer's Block zu überwinden. Obwohl die KI in der Lage ist, komplette Textsets und musikalische Melodien zu generieren, ermutigen wir dich, sie als Ausgangspunkt zu verwenden und deine eigene persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um deine eigene Kreativität zu ersetzen.";
const faqa3 = "Der Writer's Block kann für jeden Songwriter eine frustrierende und demoralisierende Erfahrung sein. Aber mit unserer Plattform musst du dir nie wieder Sorgen machen, dass dir die Ideen ausgehen. Gib einfach dein gewünschtes Thema und deinen Stil ein, und die KI wird dir einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen du arbeiten kannst. Dies kann deinen Songwriting-Prozess in Gang bringen und dich wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, dir eine unendliche Anzahl von Ideen und Vorschlägen basierend auf deinen gewählten stilistischen Präferenzen zu bieten, und gibt dir die Freiheit, zu experimentieren und Neues auszuprobieren.";
const faqa4b = "Zusätzlich erlaubt es dir die Plattform, die Texte nach deinem eigenen Willen zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an deiner Seite, das dich durchgehend im Songwriting-Prozess unterstützt. Es ist wie dein eigener persönlicher kreativer Assistent, angepasst an deine eigenen persönlichen Geschmäcke und Bedürfnisse beim Songwriting!";
const quote1 = "Das ist ein Gamechanger, hat mir echt geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Liedern zu erlernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
