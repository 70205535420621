import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function TextZuSongGenerator() {
const title = "Text-Zu-Song-Generator";
const keywords = "Lyrik-Generator, Musikgenerator, Text-zu-Song-Generator, Songwriting-Assistent, Songwriting-App, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website";
const description = "Lernen Sie, mit KI Text in Songs zu verwandeln";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die KI nutzt, um Ihnen zu helfen, schneller Texte und Musik zu schreiben. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const h1 = "Lernen Sie, mit der Kraft der KI Text in Songs umzuwandeln.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gesteuerten Liedtexte.";
const sectionHeader = "Der ultimative Songwriting-Begleiter";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Die KI kann über jedes Thema schreiben und Musik jeder Genre erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu gestalten. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben und Ihre musikalische Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Fertigstellen Ihrer Liedtexte zu helfen.";
const featureParagraph4 = "Mit mehr als 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder für mich komplett zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie fördert die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform besteht darin, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl die KI in der Lage ist, komplette Texte und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können für jeden Songwriter eine frustrierende und entmutigende Erfahrung sein. Aber mit unserer Plattform müssen Sie sich nie Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil an, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen zur Verfügung stellen, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgerichtet, Ihnen eine endlose Versorgung mit Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Zusätzlich ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, unterstützt durch ein hochgradig einzigartiges 'Related Words'-Wörterbuch, das Ihnen während Ihres gesamten Songwriting-Prozesses zur Seite steht. Es ist wie Ihr eigener kreativer Assistent, angepasst an Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, mein erstes Lied zu schreiben.";
const quote2 = "Brillantes Produkt, würde es jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
