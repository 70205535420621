import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function 曲の書き方Ja() {
const title = "曲の書き方";
const keywords = "歌詞, 音楽ジェネレータ, 音楽, 曲作り, 曲作りアシスタント, 曲作りアプリ, 曲作りプラットフォーム, 曲作りソフトウェア, 曲作りツール, 曲作りツールズ, 曲作りウェブサイト, 曲の書き方";
const description = "AIを使って曲の書き方を学びましょう";
const longDescription = "Lyrical Labsは、AIを活用して歌詞と音楽をより速く書くための曲作りプラットフォームです。あなたの個人的な曲作りニーズに合わせてカスタマイズすることができる、あなたの個人的な曲作りアシスタントです！";
const h1 = "AIの力を活用して曲の書き方を学びましょう。";
const h2 = "私たちのAI駆動の歌詞で、あなたの作曲を革命的に変え、無限のインスピレーションを得ましょう。";
const sectionHeader = "究極の曲作りパートナー";
const featureTitle1 = "無限の創造性";
const featureTitle2 = "あなた専用の曲作りアシスタント";
const featureTitle3 = "内蔵スマート辞書";
const featureTitle4 = "どんな言語でも書ける";
const featureParagraph1 = "終わりのないオリジナルのアイデアとメロディで、絶え間ない曲作りのインスピレーションが待っています。AIはどんなトピックについても書くことができ、どんなジャンルの音楽も生成することができます。";
const featureParagraph2 = "AI曲作りパートナーと協力して完璧な曲を作りましょう。歌詞と音楽ができたら、AIにフィードバックを提供して、あなたの音楽的ビジョンを実現させましょう。";
const featureParagraph3 = "関連する単語をすべて見つけるための内蔵スマート辞書が装備されており、インスピレーションを提供し、歌詞を完成させるのに役立ちます。";
const featureParagraph4 = "100以上の言語から選択できるため、どんな言語でも歌詞の書き方を学ぶことができます。";
const faq1 = "著作権は私が持つのですか？";
const faq2 = "このプラットフォームの目的は、私の曲を完全に書くことですか？";
const faq3 = "このプラットフォームはどのようにして作家のブロックを克服するのですか？";
const faq4 = "このプラットフォームはどのようにして作曲プロセスを促進するのですか？";
const faqa1 = "はい！Lyrical Labsは100%ロイヤリティフリーなので、このプラットフォームで作成した音楽や歌詞のすべての権利を保持します。";
const faqa2 = "いいえ、当プラットフォームの目的は無限のインスピレーションを提供し、作家のブロックを乗り越える手助けをすることです。AIは完全な歌詞と音楽メロディを生成する能力を持っていますが、それらをスタートポイントとして使用し、あなた自身の個人的なタッチを加えることをお勧めします。AIは、あなたの創造性を置き換えるものではなく、支援しインスピレーションを提供するものです。";
const faqa3 = "作家のブロックはどんな作曲家にとってもフラストレーションとなり、士気をそぐ体験です。しかし、当プラットフォームを使用すれば、アイディアが尽きることはもうありません。望むテーマとスタイルを入力するだけで、AIは創造的でオリジナルのアイディアの絶え間ない流れを提供します。これにより、曲作りのプロセスをキックスタートさせ、再び軌道に乗るのに役立ちます。";
const faqa4a = "当プラットフォームは、選択したスタイルの好みに基づいてアイディアと提案の無限の供給を提供するように設計されており、新しいことを実験し試す自由を与えます。";
const faqa4b = "さらに、このプラットフォームでは、'関連する単語'の辞書を手元に置きながら、自由に歌詞を書き編集することができます。これは、あなたの個人的な曲作りニーズに合わせた、あなた専用のクリエイティブアシスタントのようなものです！";
const quote1 = "これは画期的です。本当に私が最初の曲を書くのを助けてくれました";
const quote2 = "素晴らしい製品です。曲の書き方を学びたいすべての人におすすめします";
const quote3 = "優れたカスタマーサービス、曲の書き方を学ぶのにとてもクールな方法です";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
