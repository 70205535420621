import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function AiTextgenerator() {
const title = "Ai-Textgenerator";
const keywords = "Textgenerator, AI, Musikgenerator, Liedtextgenerator, Songwriting-Assistent, Songwriting-Plattform, Songwriting-Software, Songwriting-Tool, Songwriting-Tools, Songwriting-Website, AI-Textgenerator";
const description = "Erfahren Sie, wie Sie mit einem AI-Textgenerator Lieder schreiben können.";
const longDescription = "Lyrical Labs ist eine Songwriting-Plattform, die AI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, der auf Ihre persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse zugeschnitten ist!";
const h1 = "Lernen Sie, wie man mit der Kraft des AI-Textgenerators Lieder schreibt.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration mit unserem AI-gesteuerten Liedtextgenerator.";
const sectionHeader = "Der ultimative Begleiter für das Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes intelligentes Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einem nie endenden Vorrat an originellen Ideen und Melodien. Der AI kann über jedes Thema schreiben und Musik jeder Genre erzeugen.";
const featureParagraph2 = "Arbeiten Sie mit unserem AI-Songwriting-Partner zusammen, um das perfekte Lied zu kreieren. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der AI Feedback geben, um Ihre musikalische Vision zu verwirklichen.";
const featureParagraph3 = "Die Plattform ist mit einem integrierten intelligenten Wörterbuch ausgestattet, das Ihnen hilft, alle Arten von verwandten Wörtern zu finden, um Inspiration zu bieten und Ihnen beim Beenden Ihrer Liedtexte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, wie man Liedtexte in jeder Sprache schreibt!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist das Ziel der Plattform, meine Lieder vollständig für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, Schreibblockaden zu überwinden?";
const faq4 = "Wie hilft die Plattform, meinen Songwriting-Prozess zu beschleunigen?";
const faqa1 = "Ja! Lyrical Labs ist 100% lizenzfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Schreibblockaden zu überwinden. Obwohl der AI vollständige Text- und Musikmelodien generieren kann, ermutigen wir Sie, diese als Ausgangspunkt zu verwenden und Ihre eigene persönliche Note hinzuzufügen. Der AI ist hier, um zu unterstützen und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Schreibblockaden können eine frustrierende und demotivierende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und der AI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder auf Kurs bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgerichtet, Ihnen einen endlosen Vorrat an Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, was Ihnen die Freiheit gibt, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Außerdem ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Related Words' Wörterbuch an Ihrer Seite, um Sie während des gesamten Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, der auf Ihre eigenen persönlichen Geschmacksrichtungen und Songwriting-Bedürfnisse zugeschnitten ist!";
const quote1 = "Das ist ein Wendepunkt, hat mir wirklich geholfen, mein erstes Lied zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Ausgezeichneter Kundenservice, wirklich coole Art, das Schreiben von Liedern zu lernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
