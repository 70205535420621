import React from 'react';

import LandingPageTemplate from './LandingPageTemplate';

export default function KiGenerator() {
const title = "Ki-Generator";
const keywords = "KI-Generator, Text-zu-Lied, Musikgenerator, Texteingabe, Liedtexterstellung, Musikmelodienerstellung, Lyrik-App, Musik-App, Songwriting-Assistent, Musikalische Inspiration, Melodieerzeugung";
const description = "Lernen Sie, Lieder mit dem KI-Generator zu schreiben";
const longDescription = "Lyrical Labs ist eine Plattform für Songwriting, die KI verwendet, um Ihnen schneller beim Schreiben von Liedtexten und Musik zu helfen. Es ist Ihr persönlicher Songwriting-Assistent, angepasst an Ihre persönlichen Geschmäcke und Bedürfnisse beim Songwriting!";
const h1 = "Lernen Sie, Lieder mit der Kraft des KI-Generators zu schreiben.";
const h2 = "Revolutionieren Sie Ihr Songwriting und erhalten Sie unendliche Inspiration durch unsere KI-gestützten Texte und Melodien.";
const sectionHeader = "Der ultimative Begleiter für Songwriting";
const featureTitle1 = "Unbegrenzte Kreativität";
const featureTitle2 = "Ihr persönlicher Songwriting-Assistent";
const featureTitle3 = "Integriertes Smart-Wörterbuch";
const featureTitle4 = "Schreiben in jeder Sprache";
const featureParagraph1 = "Endlose Songwriting-Inspiration erwartet Sie mit einer nie endenden Quelle an originellen Ideen und Melodien. Der KI-Generator kann über jedes Thema schreiben und Musik jeder Gattung erzeugen";
const featureParagraph2 = "Arbeiten Sie mit unserem KI-Songwriting-Partner zusammen, um das perfekte Lied zu erschaffen. Sobald Sie Texte und Musik haben, können Sie durch Gespräche mit der KI Feedback geben und Ihre musikalische Vision zum Leben erwecken.";
const featureParagraph3 = "Die Plattform ist mit einem intelligenten Wörterbuch ausgestattet, das Ihnen hilft, allerlei verwandte Wörter zu finden, um Inspiration zu bieten und Ihnen beim Vervollständigen Ihrer Texte zu helfen.";
const featureParagraph4 = "Mit über 100+ Sprachen zur Auswahl können Sie lernen, Liedtexte in jeder Sprache zu schreiben!";
const faq1 = "Behalte ich das Urheberrecht?";
const faq2 = "Ist es das Ziel der Plattform, meine Lieder komplett für mich zu schreiben?";
const faq3 = "Wie hilft die Plattform mir, einen Schreibblock zu überwinden?";
const faq4 = "Wie unterstützt die Plattform meinen Songwriting-Prozess?";
const faqa1 = "Ja! Lyrical Labs ist 100% urheberrechtsfrei, sodass Sie alle Rechte an der Musik und den Texten behalten, die Sie auf dieser Plattform erstellen.";
const faqa2 = "Nein, das Ziel unserer Plattform ist es, Ihnen eine endlose Quelle der Inspiration zu bieten und Ihnen zu helfen, Schreibblockaden zu überwinden. Während der KI-Generator in der Lage ist, komplette Textsätze und musikalische Melodien zu generieren, ermutigen wir Sie, diese als Ausgangspunkt zu nutzen und Ihre persönliche Note hinzuzufügen. Die KI ist hier, um zu assistieren und zu inspirieren, nicht um Ihre eigene Kreativität zu ersetzen.";
const faqa3 = "Ein Schreibblock kann eine frustrierende und entmutigende Erfahrung für jeden Songwriter sein. Aber mit unserer Plattform müssen Sie sich nie wieder Sorgen machen, dass Ihnen die Ideen ausgehen. Geben Sie einfach Ihr gewünschtes Thema und Ihren Stil ein, und die KI wird Ihnen einen endlosen Strom von kreativen und originellen Ideen liefern, mit denen Sie arbeiten können. Dies kann Ihren Songwriting-Prozess ankurbeln und Sie wieder in Schwung bringen.";
const faqa4a = "Unsere Plattform ist darauf ausgelegt, Ihnen eine endlose Quelle von Ideen und Vorschlägen basierend auf Ihren gewählten stilistischen Präferenzen zu bieten, sodass Sie die Freiheit haben, zu experimentieren und etwas Neues auszuprobieren.";
const faqa4b = "Außerdem ermöglicht Ihnen die Plattform, die Texte nach Belieben zu schreiben und zu bearbeiten, mit einem hochgradig einzigartigen 'Verwandte Wörter'-Wörterbuch an Ihrer Seite, um Sie während Ihres Songwriting-Prozesses zu unterstützen. Es ist wie Ihr eigener persönlicher kreativer Assistent, angepasst an Ihre persönlichen Geschmäcke und Bedürfnisse beim Songwriting!";
const quote1 = "Das ist ein Gamechanger, hat mir wirklich geholfen, meinen ersten Song zu schreiben";
const quote2 = "Brillantes Produkt, würde ich jedem empfehlen, der lernen möchte, wie man ein Lied schreibt";
const quote3 = "Exzellenter Kundenservice, wirklich coole Möglichkeit, das Schreiben von Liedern zu erlernen";
return (

<>
<LandingPageTemplate
title={title}
keywords={keywords}
description={description}
longDescription={longDescription}
h1={h1}
h2={h2}
sectionHeader={sectionHeader}
featureTitle1={featureTitle1}
featureTitle2={featureTitle2}
featureTitle3={featureTitle3}
featureTitle4={featureTitle4}
featureParagraph1={featureParagraph1}
featureParagraph2={featureParagraph2}
featureParagraph3={featureParagraph3}
featureParagraph4={featureParagraph4}
faq1={faq1}
faq2={faq2}
faq3={faq3}
faq4={faq4}
faqa1={faqa1}
faqa2={faqa2}
faqa3={faqa3}
faqa4a={faqa4a}
faqa4b={faqa4b}
quote1={quote1}
quote2={quote2}
quote3={quote3}
/>
</>
);
}
